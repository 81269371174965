<template lang='pug'>
div(style="min-height: 300px; transition: height 0.5s ease-in")
  .d-flex.justify-content-between.align-items-center
    .d-flex.mx-3(style='gap:5px')
      div
        small Organisationen:
        b-form-checkbox(v-model='filter.myOrganisation')
          |Meine Organisation
          span.mx-1.d-inline-block( title='Organisationen die mir oder meinem Team zugewiesen sind' v-b-tooltip)
            i.fa.fa-question-circle
        b-form-checkbox(v-model='filter.otherOrganisation')
          |Andere Organisation
      div
        small Vorgänge:
        b-form-checkbox(v-model='filter.vorgangByMy')
          |Letzter Vorgang von mir
          span.mx-1.d-inline-block(title='Der allerletzte (angezeigte) Akquisevorgang mit der Organisation ist von mir' v-b-tooltip)
            i.fa.fa-question-circle
        b-form-checkbox(v-if='currentCommunityManagerTeamIds.length > 0' v-model='filter.anyVorgangByMyTeam' )
          |Letzter Vorgang von meinem Team
          span.mx-1.d-inline-block(v-b-tooltip title='der letzte Akquisevorgang ist von einem Teammitglied meines Akquiseteams')
            i.fa.fa-question-circle
        b-form-checkbox(v-model='filter.anyVorgangByMy')
          |Irgendein Vorgang von mir
          span.mx-1.d-inline-block(v-b-tooltip title='Im Zeitraum rechts habe ich jemals einen Akquisevorgang mit dieser Organisation gehabt')
            i.fa.fa-question-circle
        b-form-checkbox(v-model='filter.vorgaengeByOthers')
          |Vorgänge von anderen


    div
      small
        |Sortierung
        span.mx-1.d-inline-block(v-b-tooltip title='Fällige Erinnerungen stehen immer oben, nicht fällige Erinnerung immer unten, ansonsten sortiert nach Datum des Vorgangs entsprechend der Option unten')
          i.fa.fa-question-circle
      nav.nav-pills.nav.m-2
        // sortOrder
        .nav-item
          a.nav-link(:class="{ active: sortBy === 'oldestFirst' }" @click="sortBy = 'oldestFirst'") Älteste zuerst
        .nav-item
          a.nav-link(:class="{ active: sortBy === 'newestFirst' }" @click="sortBy = 'newestFirst'") Neueste zuerst

    div
      small Letzter Akquisevorgang:
      nav.nav-pills.nav.m-2
        .nav-item
          a.nav-link(:class="{ active: filter.date === 'lastYear' }" @click="filter.date = 'lastYear'") Letztes Jahr
        .nav-item
          a.nav-link(:class="{ active: filter.date === 'last2Years' }" @click="filter.date = 'last2Years'") Letzte 2 Jahre
        .nav-item
          a.nav-link(:class="{ active: filter.date === 'last5Years' }" @click="filter.date = 'last5Years'") Letzte 5 Jahre



  .alert.alert-danger(v-if="error")
    | {{ error }}
  .panel-body.text-center(v-if="isLoading")
    PulseLoader(:loading="isLoading")
  div(v-else)

    .d-grid
      .akquise-card(v-for='(items, index) in filteredItems' :key="index")
        h2.small.text-center.sticky-headline
          |{{ translation[index] || index }}
          br
          small
            |Summe: {{ akquiseSum(items) }}

        .d-flex.flex-column(style='gap: 3px')
          akquise-organisation-card(v-for='(item, index) in items' :key="index" :vorgang="item")
</template>

<script lang='ts'>
import Vue from 'vue'
import PulseLoader from "vue-spinner/src/PulseLoader.vue"
import { InteressentenTableQuery } from "types/graphql/requests"
import AkquiseOrganisationCard from "./AkquiseOrganisationCard.vue"
import { BFormCheckbox } from "bootstrap-vue"
import gql from "utils/graphql"
import sortBy from "lodash.sortby"

type Keys = keyof InteressentenTableQuery["interessenten"]

export default Vue.extend({
  components: {
    PulseLoader,
    AkquiseOrganisationCard,
    BFormCheckbox
  },
  data() {
    return {
      isLoading: false,
      error: null,
      filter: {
        myOrganisation: true,
        otherOrganisation: true,
        vorgangByMy: true,
        anyVorgangByMy: true,
        anyVorgangByMyTeam: false,
        vorgaengeByOthers: false,
        date: 'lastYear',
      },
      sortBy: 'oldestFirst',
      data: {} as InteressentenTableQuery["interessenten"],
      sortedData: {} as InteressentenTableQuery["interessenten"],
      translation: {
        lead: "Kontakt hergestellt",
        contactEstablished: "Qualif. Lead",
        offerSent: "Bereit zur Registrierung",
        contractSigned: "Vertrag unterschrieben",
        rejected: "Abgelehnt",
      }
    }
  },
  watch: {
    "filter.date": {
      handler(o, v) {
        this.fetchData()
      }
    },
  },
  inject: ["$communityManagerId", "$communityManagerTeamIds"],
  computed: {
    currentCommunityManagerId() {
      return this.$communityManagerId.toString() as string
    },
    currentCommunityManagerTeamIds() {
      return this.$communityManagerTeamIds as string[]
    },
    filteredItems() {
      const curDate = new Date().toISOString()
      const filteredItems = {} as InteressentenTableQuery["interessenten"]
      type Key = keyof typeof this.data
      (Object.keys(this.data) as Key[]).map((key: Key) => {
        if (key == "__typename") {
          return
        }
        const items = this.data[key]
        let currentItems = items.filter(item => {
          let orgMatches = false
          if (this.filter.myOrganisation && item.organisation.belongsToCurrentCommunityManagerOrTeam) {
            orgMatches = true
          } else if (this.filter.otherOrganisation && !item.organisation.belongsToCurrentCommunityManagerOrTeam) {
            orgMatches = true
          }
          let vorgangMatches = false
          if (this.filter.vorgangByMy && item.communityManager?.id == this.currentCommunityManagerId) {
            vorgangMatches = true
          } else if (this.filter.anyVorgangByMy && item.organisation.akquiseParticipantCmIds.includes(this.currentCommunityManagerId)) {
            vorgangMatches = true
          } else if (this.filter.anyVorgangByMyTeam && this.currentCommunityManagerTeamIds.includes(item.communityManager?.id ?? "")) {
            vorgangMatches = true
          } else if (this.filter.vorgaengeByOthers) {
            vorgangMatches = item.communityManager?.id != this.currentCommunityManagerId && !item.organisation.akquiseParticipantCmIds.includes(this.currentCommunityManagerId)
          }
          return orgMatches && vorgangMatches
        })
        filteredItems[key] = sortBy(currentItems, (item) => {
          const diff = new Date(item.date).getTime()
          const f = [
            item.reminder && item.reminder < curDate ? 0 : 1,
            item.reminder && item.reminder > curDate ? 1 : 0,
            this.sortBy === 'oldestFirst' ? diff : 9999999999999 - diff,
          ].join("-")
          return f
        })

      })
      return filteredItems
    }
  },
  methods: {
    akquiseSum(items: InteressentenTableQuery["interessenten"][Keys]) {
      const sum = items.reduce((sum, item) => {
        return sum + (item.organisation.potentiellesUmsatzvolumen || 0)
      }, 0)
      return sum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
    },
    fetchData() {
      this.isLoading = true
      this.error = null
      let minDate
      if (this.filter.date == 'lastYear')  {
        minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString()
      } else if (this.filter.date == 'last2Years') {
        minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 2)).toISOString()
      } else {
        minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 5)).toISOString()
      }
      gql
        .InteressentenTable({
          minDate,
        })
        .then((data) => {
          this.data = data.interessenten
          this.isLoading = false
        })
        .catch((error) => {
          console.error(error)
          this.isLoading = false
          this.error = error
        })
    }
  },
  mounted() {
    this.fetchData()
  }
})
</script>

<style scoped>
.d-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin: 1rem;
  overflow-x: scroll;
  overflow-y: auto;
  max-height: 100vh;
}

.akquise-card {
  border: 1px solid #ccc;
  padding: 1rem;
  margin: 0;
  background: #ccc;
}
.nav-link {
  cursor: pointer;
}
.sticky-headline {
  position: sticky;
  top: 0;
  background: #ccc;
  z-index: 1;
}
</style>
