import tinymce from "tinymce"

import _groupBy from "lodash/groupBy"

tinymce.PluginManager.add("templateselection", function Plugin(editor) {
  const templates = editor.settings.templates as (Mailtemplate & { subject?: string })[]

  const templateGroups = _groupBy(templates, "template_group")

  editor.ui.registry.addMenuButton("templates", {
    text: "Vorlagen",
    type: "menubutton",
    icon: "template",
    fetch(callback) {
      callback(
        Object.keys(templateGroups).map((group) => {
          return {
            text: group,
            type: "nestedmenuitem",
            getSubmenuItems() {
              return templateGroups[group].map((template) => {
                return {
                  type: "menuitem",
                  text: template.title,
                  onAction() {
                    const body = template.body+ editor.settings.fragments.find(e => e.title == 'Signatur')?.content
                    editor.setContent(body)

                    if (template.subject) {
                      const input = document.querySelector("input[name*=subject]")
                      if (input instanceof HTMLInputElement) {
                        setTimeout(() => {
                          if (template.subject) {
                            input.value = template.subject
                            const event = new Event('input', {
                              cancelable: true,
                              bubbles: true
                            })
                            input.dispatchEvent(event)
                          }
                        }, 50)
                      }
                    }
                  },
                }
              })
            },
          }
        })
      )
    },
  })
})
