<template lang="pug">
div
  pager(:pages='pages')
    template(v-slot:page(1))
      form
        b-form-group(label='Interner Titel' description='Interner Name der Kampagne')
          input.form-control(type="text" placeholder="Interner Titel der Kampagne" v-model="mailing.title" required)
        .row
          .col
            b-form-group(label='Absender (E-Mail)')
              b-form-select(v-model='form.from_email' :options='allowedFrom')
          .col
            b-form-group(label='Absender (Name)')
              b-form-input(v-model='form.from_display_name')
        b-form-group(label='BCC')
          b-form-input(v-model='form.bcc' type='email')

        b-form-group(label='Betreff' description='KEINE automatischen Ersetzungen hier')
          input.form-control(type="text" placeholder="Betreff (Keine Ersetzungen)" v-model="mailing.subject" required name='subject')

        editor(
          v-model='mailing.body'
          :placeholders='placeholders'
          :templateUrl='templateUrl'
        )
        hr

        attachments-field(v-model='uploads')

    template(v-slot:page(2))
      community-workshop-recipient-step(
        v-if='relatedEvent'
        v-model='form.filter' @count='recipient_count = arguments[0]' :mailing='form'
      )
      recipient-selector-step(
        v-else
        v-model='form.filter' @count='recipient_count = arguments[0]' :mailing='form'
      )

    template(v-slot:page(3))
      delivery-step(v-model='form')

    template(v-slot:last)
      button.btn.btn-primary.btn-lg(@click='deliver' :disabled='delivering')
        |E-Mail an {{ recipient_count }} Empfänger senden

</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { BFormGroup, BFormSelect, BFormInput } from "bootstrap-vue"
import Editor from "mailing_form/editor/Editor.vue"
import Pager from "common/pager/SimplePageWrapper.vue"
import RecipientSelectorStep from "./steps/RecipientSelectorStep.vue"
import CommunityWorkshopRecipientStep from "./steps/CommunityWorkshopRecipientStep.vue"
import DeliveryStep from "./steps/DeliveryStep.vue"
import * as Routes from "generated/routes"
import AttachmentsField from './steps/AttachmentsField.vue'

export default Vue.extend({
  components: {
    Editor,
    BFormGroup,
    Pager,
    RecipientSelectorStep,
    CommunityWorkshopRecipientStep,
    BFormSelect,
    BFormInput,
    DeliveryStep,
    AttachmentsField,
  },
  props: {
    relatedEvent: {
      type: Object as PropType<schema.CommunityWorkshop | schema.CommunityWorkshopEvent>,
    },
    allowedFrom: {
      type: Array as PropType<string[]>,
      required: true,
    },
    mailing: {
      type: Object as PropType<schema.Mailing & { attachments: any }>,
      required: true,
    },
    placeholders: { type: Array as PropType<Placeholder[]>, required: true },
  },
  data() {
    return {
      delivering: false,
      form: this.mailing as MailingForm,
      recipient_count: 0,
      uploads: this.mailing.attachments as ActiveStorage.Blob[],
    }
  },
  computed: {
    templateUrl(): string {
      return Routes.templatesMailingPath(this.mailing.id)
    },

    pages(): any[] {
      const that = this
      const onNext = async () => {
        try {
          await that.$http.patch(`/mailings/${that.form.id}`, {
            mailing: {
              ...that.form,
              attachments: this.uploads.map((upload) => upload.signed_id),
            },
          })
        } catch (err) {
          console.log(err)
          return false
        }
      }
      return [
        {
          title: "Inhalt",
          isValid: this.form.subject && this.form.body && this.form.title,
          onNext,
        },
        {
          title: "Auswahl der Empfänger",
          isValid: this.form.filter && this.recipient_count > 0,
          onNext,
        },
        {
          title: "Vorschau/Versand",
          isValid: false,
        },
      ]
    },
  },
  methods: {
    async deliver() {
      this.delivering = true
      try {
        await this.$http.post(Routes.deliverMailingPath(this.form.id))
        window.location.assign(Routes.mailingPath(this.form.id))
      } catch(err) {
        this.$bvToast.toast(`Fehler beim Versand: ${err}`, {
          title: "Fehler",
          variant: "danger",
        })
      }
    },
  },
})
</script>
