<template lang='pug'>
div
  attachments-field(v-model='files')
  input(type='hidden' v-for='file in files' :name='formName' :value='file.signed_id')
</template>

<script lang='ts'>
import AttachmentsField from 'mailing_form/steps/AttachmentsField.vue'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  components: {
    AttachmentsField,
  },
  props: {
    formName: {
      type: String,
      required: true
    },
    initial: {
      type: Array as PropType<ActiveStorage.Blob[]>,
      default: () => []
    }
  },
  data() {
    return {
      files: this.initial
    }
  }
})
</script>
<style scoped>
</style>
