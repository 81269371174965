import $ from 'jquery';

document.addEventListener("DOMContentLoaded", () => {
  $('.js-qrcode').each(function() {
    const el = $(this)
    el.click((e)=> {
      e.preventDefault()

      const p = el.popover({
        html: true,
        content: () => `<img src='${el.attr('href')}'/>`
      })
      p.popover('toggle')
    })
  })
})
