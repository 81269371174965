<template lang="pug">
.card.mb-4(style='max-width: 500px')
  .card-header
    h5.card-title
      | CSV-Import
  .card-body
    ul
      li Mindestens Spalte "Email/E-Mail/email" muss vorhanden sein
      li Spalten "Firma", "Name" (Kompletter Anzeigename/Nachname) und "Anrede" optional
      li Format: Mit Semikolon getrennt, mit Anführungszeichen umschlossen
  .card-footer
    b-form-file(v-if="!isUploading" type="file" accept=".csv" placeholder="Choose a file or drop it here..." @input="uploadCsv")
    div(v-if="isUploading")
      i.fa.fa-spinner.fa-spin.fa-3x.text-center
</template>

<script lang="ts">
import { BFormFile } from "bootstrap-vue"
import { defineComponent, ref, PropType } from "@vue/composition-api"

export default defineComponent({
  components: {
    BFormFile,
  },
  props: {
    mailing: { type: Object as PropType<MailingForm>, required: true },
  },
  setup(props, ctx) {
    const isUploading = ref(false)
    const uploadCsv = (file: File) => {
      const formData = new FormData()
      formData.append("file", file)
      isUploading.value = true

      fetch(`/mailings/${props.mailing.id}/csv`, {
        method: "POST",
        headers: {
          "X-CSRF-Token":
            document
              .querySelector("meta[name=csrf-token]")
              ?.getAttribute("content") || "",
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          isUploading.value = false
          ctx.emit("refresh", data.recipients)
        })
        .catch((error) => {
          isUploading.value = false
          console.error("Error:", error)
        })
    }
    return {
      isUploading,
      uploadCsv,
    }
  },
})
</script>
<style scoped></style>
