const setBadgeCount = function (listGroup) {
  const el = $(listGroup)
  el.closest(".panel")
    .find(".panel-title .incident-counter")
    .html(el.find(".list-group-item").length)
  const companies = {}

  el.find(".list-group-item").each((i, e) => {
    return (companies[$(e).find(".text-right").text()] = true)
  })

  const set = new Set(Object.keys(companies))
  return el
    .closest(".panel")
    .find(".panel-title .company-counter")
    .html(set.size)
}

const runPopover = function (selector) {
  return selector
    .find("[data-toggle=popover]")
    .popover({
      container: "body",
      html: true,
      placement: "bottom",
      trigger: "click",
    })
    .click((e) => {
      return e.preventDefault()
    })
}
window.runIncidentApp = function () {
  runPopover($("body"))

  return $(".js-incident").each(function () {
    setBadgeCount(this)
    const cm = $(this).data("cm")

    return Sortable.create(this, {
      group: "same",
      handle: ".js-handle",
      forceFallback: true,
      animation: 150,

      onStart: function () {
        return $("body").addClass("dragging")
      },

      onEnd: function () {
        return $("body").removeClass("dragging")
      },

      onAdd: function (event) {
        $.ajax({
          url: "/incidents/" + event.item.dataset.id + "/assign",
          method: "PATCH",

          data: {
            community_manager_id: cm,
          },

          success: function (data) {
            const tpl = $(data.template).html()
            const el = $(event.item)
            el.html(tpl)
            return runPopover(el)
          },
        })

        setBadgeCount(event.to)
        return setBadgeCount(event.from)
      },
    })
  })
}
