<template lang='pug'>
.panel.panel-primary
  .panel-heading
    h3.panel-title Review-Termine

    ul.nav.nav-pills.line-height-1.flex-grow-1(v-if='false')
      li.nav-item(:class="{active: tab === 1}")
        a.nav-link(href='#' @click='tab = 1') Eigene Kunden
      li.nav-item(:class="{active: tab === 2}")
        a.nav-link(href='#' @click='tab = 2') Alle CMs
  highcharts(v-if='chartData' :options="chart")
</template>

<script lang='ts'>
import Vue from 'vue'
import type { Options } from "highcharts"

export default Vue.extend({
  props: {},
  data() {
    return {
      tab: 1,
      chartData: null as Options | null,
    }
  },
  computed: {
    chart(): Options | null  {
      return { ...this.chartData, title: { text: undefined } }
    }
  },
  methods: {
    async fetchData() {
      const response = await this.$http.get('/admin/review_teilnahmen.json')
      this.chartData = response.data
    }
  },
  mounted() {
    this.fetchData()
  },
})
</script>
<style scoped>
</style>
