<template lang="pug">
div(style="min-height: 300px; transition: height 0.5s ease-in")
  .alert.alert-danger(v-if="error")
    | {{ error }}
  .panel-body.text-center(v-if="isLoading")
    PulseLoader(:loading="isLoading")
  div(v-else)
    .d-flex.justify-content-between
      .btn-group
        button.btn.btn-sm.btn-outline-dark(v-for='cm in communityManagers' @click='changeCm(cm)' :class="{ 'active': cm.id == currentCm }")
          | {{ cm.ldap_login }}
      .btn-group
        button.btn.btn-sm.btn-outline-dark.float-right(
          @click.prevent="problemMode = !problemMode",
          :class="{ 'active': problemMode }"
        )
          | Probleme
        button.btn.btn-sm.btn-outline-dark.float-right(
          @click.prevent="cmReviewMode = !cmReviewMode",
          :class="{ 'active': cmReviewMode }"
        )
          | CM-Review
    BTable(
      :items="organisationsWithGitlabIssues",
      :fields="fields",
      :sort-by.sync="sortBy",
      :sort-desc.sync="sortDesc"
      ref="table"
    )
      template(v-slot:cell(name)="row")
        a(:href="'/organisationen/' + row.item.id")
          | {{ row.item.name }}
        .d-flex.align-items-center(style='gap: 5px;')
          div: .btn-group
            a.btn.btn-xs.btn-outline-dark(:href='"https://login.empfehlungsbund.de/sessions/switch?user=" + row.item.username' target="_blank" v-if="row.item.username")
              | EB
            a.btn.btn-xs.btn-outline-dark(:href='"https://bms.empfehlungsbund.de/admin/switch_user?organisation=" + row.item.username' target="_blank" v-if="row.item.username && row.item.bmsType == 'ebms'")
              | BMS
          kbd.text-dark.bg-secondary
            | {{ row.item.username }}
        div.bg-danger.p-1.text-white.mt-1.rounded.small(v-if='problemMode')
          small.text-center.d-block(style='color: #fbb')
            |Probleme:
          ul.pl-3.m-0.line-height-1.d-flex.flex-column.gap-1
            li.m-0(v-for='problem in row.item.patientenakte.problems')
              | {{ problem }}


      template(v-slot:cell(mitarbeiterstufe.from)="{ item }")
        | {{ item.mitarbeiterstufe ? item.mitarbeiterstufe.level : '' }}

      template(v-slot:cell(bmsType)="{ item, value }")
        div(v-if="value == 'EBMS'")
          img(:src='bmsIcon' style='height: 1.2rem; width: 1.2rem')
        div(v-else)
          | {{ value }}

        small.text-muted(v-if='item.bmsType == "other_bms" && item.bmsName')
          | {{ item.bmsName }}
        div
          small(title="Bewerbungsmethode" v-b-tooltip v-if='item.patientenakte && item.patientenakte.applicationMethod && item.patientenakte.applicationMethod != "ebms"')
            | {{ item.patientenakte.applicationMethod }}

      template(v-slot:cell(weight)="{ item }")
        status-cell(:item="item")

      template(v-slot:cell(customerRelation)="{ item }")
        customer-relation-cell(:item="item")

      template(v-slot:cell(cmReview.reviewgespraech.value)="{ item }")
        review-cell(:item="item")

      template(v-slot:cell(cmReview.smartclickOhneCode.value)="{ item }")
        smartclick-cell(:item="item")

      template(v-slot:cell(patientenakte.applicationsLast12Months)="{ item }")
        statistic-cell(:item="item" v-if='item.patientenakte')

      template(v-slot:cell(patientenakte.hiredWithEb.last12Months)="{ item }")
        div(v-if='item.patientenakte')
          | {{ item.patientenakte.hiredWithEb.last12Months }}

          div: small.text-muted(v-b-tooltip title="Ausgefüllte Statistik Monate")
            | {{ item.cmReview.statistikAusgefuellt.value }} / 12
      template(v-slot:cell(patientenakte.jobImport)="{ item }")
        job-import-cell(:item="item")
</template>

<script lang="ts">
import Vue from "vue"
import PulseLoader from "vue-spinner/src/PulseLoader.vue"
import { PartnerTableQuery } from "types/graphql/requests"
import gql from "utils/graphql"
import { BTable, BvTableFieldArray } from "bootstrap-vue"
import StatusCell from "./StatusCell.vue"
import CustomerRelationCell from "./CustomerRelationCell.vue"
import ReviewCell from "./ReviewCell.vue"
import SmartclickCell from "./SmartclickCell.vue"
import JobImportCell from "./JobImportCell.vue"
import StatisticCell from "./StatisticCell.vue"
import bmsIcon from 'images/bms-icon.png'

import { dashboard } from 'types/dashboard.d'

const sortWeight = (organisation: dashboard.PartnerTableRow) => {
  const prefix = (
    organisation.partnerAngebot?.status == "gekuendigt"
      ? 0
      : organisation.partnerAngebot?.neukunde
      ? 1
      : 2
  ).toString()
  return `${prefix}-${organisation.name?.toLowerCase()}`
}

export default Vue.extend({
  components: {
    PulseLoader,
    StatusCell,
    ReviewCell,
    StatisticCell,
    CustomerRelationCell,
    SmartclickCell,
    JobImportCell,
    BTable,
  },
  props: {
    services: { type: Object, required: true },
    communityManagers: { type: Array, required: true },
  },
  data() {
    return {
      sortBy: "name",
      sortDesc: false,
      isLoading: false,
      cmReviewMode: false,
      problemMode: false,
      error: null,
      bmsIcon,
      currentCm: window.CurrentCm?.id,
      data: { organisations: [], gitlabIssues: [] } as PartnerTableQuery,
    }
  },
  computed: {
    organisationsWithGitlabIssues(): dashboard.PartnerTableRowWithIssues[] {
      let orgs = this.data.organisations.map((organisation) => {
        const issues = this.data.gitlabIssues.filter(
          (issue) => issue.username === organisation.username
        )
        const weight = sortWeight(organisation)
        return { ...organisation, issues, weight }
      })
      if (this.problemMode) {
        orgs = orgs.filter(e => e.patientenakte.problems.length > 0)
      }
      return orgs
    },
    fields(): BvTableFieldArray {
      return [
        { key: "name", label: "Name", sortable: true },
        { key: "weight", label: "Status", sortable: true },
        { key: "mitarbeiterstufe.from", label: "Größe", sortable: true },
        { key: "customerRelation", label: "Beziehung", sortable: true },
        {
          key: "patientenakte.karma",
          label: "EB-Aktivität",
          tdClass: "text-right",
          sortable: true,
          formatter(n: number | undefined) {
            return n?.toLocaleString("de-DE")
          },
        },
        {
          key: "cmReview.smartclickOhneCode.value",
          label: "Smartclicks",
          sortable: true,
          tdClass: "text-right",
        },
        {
          key: "patientenakte.jobImport",
          label: "Stellenanbindung",
          sortable: true,
          tdClass: "text-right",
        },
        {
          key: "patientenakte.applicationsLast12Months",
          label: "Bewerbungen (12Mon.)",
          sortable: true,
          tdClass: "text-right",
        },
        {
          key: "patientenakte.hiredWithEb.last12Months",
          label: "Einstellungen EB",
          sortable: true,
          tdClass: "text-right",
        },
        {
          key: "cmReview.reviewgespraech.value",
          label: "Reviewgespräch",
          sortable: true,
          tdClass: "text-center",
        },
        {
          key: "bmsType",
          label: "BMS",
          sortable: true,
          formatter(val: string) {
            const map = {
              none_email: "Kein/E-Mail",
              ebms: "EBMS",
              other_bms: "Ext.BMS",
            }
            if (val in map) {
              return map[val]
            } else {
              return val
            }
          },
        },
        ...this.cmReviewColumns,
      ]
    },
    cmReviewColumns(): BvTableFieldArray {
      if (this.cmReviewMode) {
        return [
          {
            key: "cmReview.backlink.value",
            label: "Backlink",
            sortable: true,
            tdClass: "text-center",
            formatter(val?: number) {
              if (val == 100) {
                return "✅"
              } else if (val == 0) {
                return "❌"
              } else {
                return val
              }
            },
          },
          {
            key: "cmReview.kundenaufwand.value",
            label: "Aufwand",
            sortable: true,
            tdClass: "text-center",
          },
        ]
      } else {
        return []
      }
    },
  },
  methods: {
    changeCm(cm) {
      this.currentCm = cm.id
      this.isLoading = true
      gql
        .PartnerTable({ communityManagerIds: [cm.id], assignedToMeOrAssistant: cm.id == window.CurrentCm?.id })
        .then((data) => {
          this.data = data
          this.isLoading = false
        })
        .catch((error) => {
          console.error(error)
          this.isLoading = false
          this.error = error
        })
    },
  },
  mounted() {
    this.isLoading = true
    gql
      .PartnerTable()
      .then((data) => {
        this.data = data
        this.isLoading = false
      })
      .catch((error) => {
        console.error(error)
        this.isLoading = false
        this.error = error
      })
  },
})
</script>
<style scoped>
.kuendigungsgrund {
  padding-left: 10px;
  position: relative;
  z-index: 1;
}
.kuendigungsgrund::before {
  content: "‟";
  left: 0;
  font-size: 3rem;
  position: absolute;
  z-index: 0;
  color: rgba(0, 0, 0, 0.2);
}
</style>
