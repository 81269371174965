import $ from "jquery"

// https://www.tiny.cloud/docs/integrations/bootstrap/#usingtinymceinabootstrapdialog
$(document).on('focusin', function(e) {
  if ($(e.target).closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
    e.stopImmediatePropagation();
  }
});


$(document).on(
  "click",
  ".js-modal, .js-modal-no-keyboard, #js-modal .modal-body a",
  function (e) {
    var modal
    var self = $(this)

    if (self.data("toggle")) {
      return true
    }

    if (self.attr("target")) {
      return true
    }
    e.preventDefault()

    if ($("#js-modal").length === 0) {
      modal =
        "<div class='modal fade container dfd' id='js-modal' role=\"dialog\" >\n  <div class='no-modal-dialog'>\n    <div class='modal-content'>\n      <div class='modal-header'>\n        <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">&times;</button>\n        <h4 class=\"modal-title\"></h4>\n      </div>\n      <div class='modal-body clearfix'></div>\n      <div class='modal-footer'></div>\n    </div>\n  </div>\n</div>"
      $("body").append(modal)
    }

    modal = $("#js-modal")

    modal.one("show.bs.modal", function () {
      if (self.hasClass("js-modal-no-keyboard")) {
        modal.data("bs.modal")._config.keyboard = false
      } else {
        modal.data("bs.modal") &&
          (modal.data("bs.modal")._config.keyboard = true)
      }
    })

    modal.trigger("modal:loading", self.attr("href"))

    $.ajax({
      url: self.attr("href"),
      error(a, b, c) {
        modal
          .trigger("modal-changed", self.attr("href"))
          .trigger("modal:changed", self.attr("href"))
          .trigger("modal:error", self.attr("href"), a, b, c)
      },
      success(data) {
        data = $("<div>" + data + "</div>")
        window.data = data
        var footer_provided = data.find("#modal-footer")

        if (footer_provided.length > 0) {
          modal.find(".modal-footer").html(footer_provided.remove().html())
        }

        var header_provided = data.find("#modal-title")

        if (header_provided.length > 0) {
          modal.find(".modal-title").html(header_provided.remove().html())
        }

        modal
          .find(".modal-body > .container-fluid, .modal-body")
          .first()
          .html(data.html())
        modal.html(modal.html())
        modal
          .trigger("modal-changed", self.attr("href"))
          .trigger("modal:changed", self.attr("href"))

        if (!modal.is(":visible")) {
          return modal.modal("show")
        }
      },
    })
  }
)

$(document).on("submit", "#js-modal form", function (e) {
  var form = $(this)

  if (form.attr("target") === "_top" || form.attr("target") === "_parent") {
    return true
  }

  $("#js-modal").trigger("modal:loading", form.attr("action"))
  e.preventDefault()

  $.ajax({
    url: form.attr("action"),
    type: form.attr("method") === "GET" ? "GET" : "POST",
    data: form.serialize(),
    complete (d, e) {
      $("#js-modal")
        .find(".modal-body > .container-fluid, .modal-body")
        .html(d.responseText)
      $("#js-modal")
        .trigger("modal-changed", form.attr("action"))
        .trigger("modal:changed", form.attr("action"))
    },
  })
})
