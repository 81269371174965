<template>
  <tr>
    <td>
      <div class="pull-right">
        <div class="btn-group quick-buttons">
          <a
            v-if="canCloseWithoutVorgang"
            v-b-tooltip
            title="Abhaken ohne Vorgang"
            class="btn btn-sm btn-success"
            @click="closeWithoutIncident"
          >
            <i class="fa-check-square-o fa fa-fw"></i>
          </a>
          <a
            v-if="canMute"
            v-b-tooltip="'Muten'"
            class="btn btn-sm btn-default"
            @click="openMuteModal"
          >
            <i class="fa-microphone-slash fa fa-fw"></i>
          </a>
          <a
            v-b-tooltip
            title="Vorgang anlegen und erledigen"
            class="btn btn-sm btn-primary"
            :href="incidentCompletUrl"
            target="_blank"
          >
            <i class="fa-play fa fa-fw"></i>
          </a>
        </div>
      </div>
      <span class="incident-type">
        <i class="fa fa-fw" :class="incidentTypeIcon"></i>
      </span>
      <a @click.prevent="modalOpen = true" href="#"> {{ incident.subject }} </a>
      <br />
      <small class="text-muted line-height-1 d-block" @click="modalOpen = true">
        {{ preview }}
        <br />
        vom {{ incident.created_at | moment("L") }}
      </small>
    </td>
    <BModal v-model="modalOpen">
      <div
        v-if="modalOpen"
        v-html="$options.filters.tohtml(incident.text)"
      ></div>
      <MutingForm @submit="createMuting" v-if="muteModalOpen"></MutingForm>
      <template v-slot:footer>
        <div class="btn-group">
          <a
            v-if="canCloseWithoutVorgang"
            class="btn btn-sm btn-success"
            @click="closeWithoutIncident"
          >
            <i class="fa-check-square-o fa fa-fw"></i>Abhaken ohne Vorgang
          </a>
          <a
            v-if="canMute"
            class="btn btn-sm btn-default"
            @click="openMuteModal"
          >
            <i class="fa-microphone-slash fa fa-fw"></i>Muten
          </a>
          <a
            class="btn btn-sm btn-primary"
            :href="incidentCompletUrl"
            target="_blank"
          >
            <i class="fa-play fa fa-fw"></i>Vorgang anlegen und erledigen
          </a>
        </div>
      </template>
    </BModal>
  </tr>
</template>

<script>
import { BModal } from "bootstrap-vue"
import MutingForm from "dashboard/components/incidents/MutingForm.vue"

import { truncate, stripHtml } from "@pludoni/frontend-pack"

export default {
  components: { BModal, MutingForm },
  props: { incident: { type: Object, required: true } },
  data() {
    return {
      modalOpen: false,
      muteModalOpen: false,
    }
  },
  computed: {
    preview() {
      return truncate(stripHtml(this.incident.text), 200)
    },
    incidentTypeIcon() {
      switch (this.incident.incident_type) {
        case "background_check":
          return "fa-bug"
        case "communication_recommendation":
          return "fa-phone"
        case "direct_message":
          return "fa-envelope-o"
        case "measure":
          return "fa-thumb-tack"
        default:
          return ""
      }
    },
    canCloseWithoutVorgang() {
      return (
        this.incident.incident_type === "background_check" ||
        this.incident.incident_type === "communication_recommendation" ||
        this.incident.incident_type === "direct_message"
      )
    },
    canMute() {
      return (
        this.incident.incident_type === "background_check" ||
        this.incident.incident_type === "communication_recommendation"
      )
    },
    incidentCompletUrl() {
      return `/incidents/${this.incident.id}`
    },
  },
  methods: {
    openMuteModal() {
      this.modalOpen = true
      this.muteModalOpen = true
    },
    async closeWithoutIncident() {
      await this.$http.post(`/incidents/${this.incident.id}/close`)
      this.closeModalAndRemove()
    },
    closeModalAndRemove() {
      this.modalOpen = false
      setTimeout(() => {
        this.$emit("remove", this.incident)
      }, 300)
    },
    createMuting(muting) {
      const params = {
        ...muting,
        organisation_id: this.incident.organisation_id,
        incident_id: this.incident.id,
        identification: this.incident.identification,
      }
      this.$http
        .post(`/incidents/${this.incident.id}/mutings`, {
          incident_muting: params,
        })
        .then((_) => {
          this.closeModalAndRemove()
        })
        .catch((e) => alert(e))
    },
  },
}
</script>

<style scoped lang="scss">
.quick-buttons {
  opacity: 0.5;
  transition: opacity 0.2s ease-in;
  transform: scale(0.7);
  &:hover {
    opacity: 1;
  }
}
.incident-type {
  margin-left: -22px;
  filter: opacity(30%);
}
</style>
