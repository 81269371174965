type TocElement = {
  text: string
  id: string
  level: string
}
import Scrollspy from 'bootstrap/js/src/scrollspy'

document.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector(".js-auto-toc")
  if (!el) {
    return
  }
  const tocElements: TocElement[] = []

  setTimeout(() => {
    Array.from(
      document.querySelectorAll("h2,h3")
    ).forEach((title) => {
      const text = title.textContent
      if (!text) {
        return
      }
      if ((title as HTMLElement).dataset.hideToc != undefined) return
      if (!title.id) title.id = `toc-${Math.random().toString(36).substr(2, 9)}`

      const level = title.tagName.toLowerCase()

      tocElements.push({
        text,
        id: title.id,
        level,
      })
    })
    if (tocElements.length > 0) {
      const html = tocElements
        .map(
          (el) =>
            `<li class='nav-item nav-toc-${el.level}'><a class='nav-link' href="#${el.id}">${el.text}</a></li>`
        )
        .join("")
      el.innerHTML = `<ul class='nav nav-pills nav-toc'>${html}</ul>`
    }
    new Scrollspy(document.body, {
      target: ".js-auto-toc ul",
      offset: 100,
    })
  }, 500)
})
