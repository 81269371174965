<template lang="pug">
div
  input.form-control(v-model='form.name' placeholder='Firmenname' @keyup='debouncedSearch')

  a.btn.btn-sm.btn-link(:href='northdataLink' target='_blank' rel='noopener')
    |North-Data-Suche

  h5 Standorte aus Google Places

  i.fa.fa-cog.fa-spin.fa-3x.text-primary(v-if='isLoading')

  .panel.panel-default(v-for='result in googlePlacesResults')
    .panel-heading
      div
        |{{ result.name }}
      div: small
        |{{ result.ort }}
    .panel-body
      dl
        dt Website:
        dd
          a(:href='result.website' target='_blank')
            | {{ result.website }}
        dt Telefon:
        dd
          | {{ result.international_phone_number }}
        dt Adresse
        dd
          div
            | {{ result.strasse }}
            |
            | {{ result.hausnummer }}
          div
            | {{ result.plz }}
            |
            | {{ result.ort }}
    .panel-footer
      button.btn.btn-default(@click='copy(result)')
        |Übernehmen

  .form-group
    label
      |Straße
      input.form-control(v-model='form.strasse' placeholder='Straße')
  .form-group
    label
      |Hausnummer
      input.form-control(v-model='form.hausnummer' placeholder='Hausnummer')
  .form-group
    label
      |PLZ
      input.form-control(v-model='form.plz' placeholder='PLZ')
  .form-group
    label
      |Ort
      input.form-control(v-model='form.ort' placeholder='Ort')
  .form-group
    label
      |Website
      input.form-control(v-model='form.url' placeholder='Website')
  .form-group
    label
      |Telefon
      input.form-control(v-model='form.telefon' placeholder='Telefon')

  .form-group
    label Organisationstyp

    label(v-for='typ in organisationstypen')
      input(name='organisationstyp' v-model='form.organisationstyp' :value='typ.id' type='radio')
      |
      | {{ typ.name }}

  .form-group
    label Cluster

    div: label(v-for='typ in cluster')
      input(v-model='form.cluster' :value='typ.ID' type='checkbox')
      |
      | {{ typ.Kurzbezeichnung }}

  button.btn.btn-primary(@click.prevent='save' type='submit')
    |Anlegen


</template>

<script>
import debounce from 'lodash.debounce'
import * as Routes from 'generated/routes';

export default {
  components: {
  },
  props: {
    name: { type: String, required: true },
    website: { type: String, required: false },
  },
  data() {
    return {
      googlePlacesResults: [],
      isLoading: true,
      communities: [],
      cluster: [],
      organisationstyp: 1,
      organisationstypen: [],
      form: {
        name: this.name,
        url: this.website || "",
        strasse: null,
        hausnummer: null,
        telefon: null,
        plz: null,
        ort: null,
        cluster: [],
        organisationstyp: 10,
        communities: [919998],
      }
    }
  },
  computed: {
    northdataLink() {
      return `https://www.northdata.de/${encodeURIComponent(this.name)}`
    }
  },
  methods: {
    copy(result) {
      this.form.url = result.website
      this.form.strasse = result.strasse
      this.form.hausnummer = result.hausnummer
      this.form.plz = result.plz
      this.form.ort = result.ort
      this.form.telefon = result.international_phone_number
    },
    search() {
      this.googlePlacesResults = []
      this.isLoading = true
      this.$http.get(Routes.autocompleteGooglePlacesPath({query: this.form.name })).then(r => {
        this.googlePlacesResults = r.data
        this.isLoading = false
      })
    },
    save() {
      this.$http.post(Routes.createOrganisationWithLocationLeadsPath(), { organisation: this.form }).then(r => {
        this.$emit("save", r.data)
      })
    }
  },
  created() {
    this.debouncedSearch = debounce(this.search.bind(this), 800)
    this.debouncedSearch()
    this.$http.get(Routes.autocompleteCommunitiesPath()).then(r => {
      this.organisationstypen = r.data.organisationstypen
      this.communities = r.data.communities
      this.cluster = r.data.cluster
    })
  }
}
</script>

<style scoped>
label { display: block }
</style>
