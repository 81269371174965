<template>
  <tr :class="{ danger: gekuendigt, success: neukunde }">
    <td>
      <a :href="'/organisationen/' + organisation.ID" target="_blank">
        {{ organisation.Bezeichnung }} <br />
        <small class="text-muted"> {{ organisation.Nutzername }} </small>
      </a>
    </td>
    <td style="text-center">
      <span v-if="gekuendigt" class="label label-danger">Gekündigt</span>
      <div v-if="gekuendigt" class="text-muted small" title="Austrittsdatum">
        {{ partnerAngebot.LaufzeitBis | moment("L") }}
      </div>
      <div
        v-if="
          gekuendigt &&
          (partnerAngebot.Kuendigungsgrund)
        "
        class="text-muted small kuendigungsgrund"
        title="Kündigungsgrund"
      >
        {{ partnerAngebot.Kuendigungsgrund }}
      </div>

      <span v-if="neukunde" class="label label-success">Neukunde</span>
      <div v-if="neukunde" class="text-muted small" title="Eintrittsdatum">
        {{ partnerAngebot.LaufzeitVon | moment("L") }}
      </div>

      <span
        v-if="!gekuendigt"
        title="Verlängerung ab/Kündbar bis"
        class="text-muted small"
      >
        <i class="fa fa-undo fa-fw"></i>
        {{ partnerAngebot.NaechsteVerlaengerung | moment("L") }}
      </span>
      <div>
        <span
          v-if="organisation.feedimport_max == -1"
          title="Keine Feedimport Beschränkung"
          class="label label-success"
        >
          <i class="fa fa-cloud-download" aria-hidden="true"></i> :
          <i class="fa fa-play fa-fw"></i>
        </span>
        <span
          v-else-if="organisation.feedimport_max == 0"
          title="Feedimport gestoppt!"
          class="label label-danger"
        >
          <i class="fa fa-cloud-download" aria-hidden="true"></i> :
          <i class="fa fa-ban fa-fw"></i>
        </span>
        <span
          v-else
          title="Feedimport gedrosselt auf Anzahl Stellen"
          class="label label-warning"
        >
          <i class="fa fa-cloud-download" aria-hidden="true"></i> :
          <i class="fa fa-sort-numeric-asc fa-fw"></i>
          {{ organisation.feedimport_max }}
        </span>
      </div>
    </td>
    <td>
      <span
        v-if="organisation.kundenbeziehung == 'positiv'"
        title="Positive Kundenbeziehung"
      >
        <i class="fa fa-fw fa-thumbs-o-up"></i>
      </span>
      <span
        v-if="organisation.kundenbeziehung == 'neutral'"
        title="Neutrale Kundenbeziehung"
      >
        <i class="fa fa-fw fa-balance-scale"></i>
      </span>
      <span
        v-if="organisation.kundenbeziehung == 'negativ'"
        title="Problemkunde"
      >
        <i class="fa fa-fw fa-exclamation-circle text-danger"></i>
      </span>
      <div>
        <MeasureChip
          v-for="measure in organisation.measures"
          :key="measure.id"
          :measure="measure"
        />
      </div>
    </td>
    <td>
      <div
        style="display: flex"
        title="KZI Befragung / KZI Gesamtzufriedenheit"
      >
        <ValueWithFormatting :value="reviewInfo['KZI Befragung']" />
        <ValueWithFormatting :value="reviewInfo['KZI Gesamtzufriedenheit']" />
      </div>
    </td>
    <td>
      <div
        style="display: flex"
        title="Smartclicks Ohne Code / Code 1 / Code 2 -- normiert zur Anzahl MA"
      >
        <ValueWithFormatting :value="reviewInfo['Smartclick Ohne Code']" />
        <span style="padding: 3px 0">%</span>
        /
        <ValueWithFormatting :value="reviewInfo['Smartclick Code 1']" />
        <span style="padding: 3px 0">%</span>
        /
        <ValueWithFormatting :value="reviewInfo['Smartclick Code 2']" />
        <span style="padding: 3px 0">%</span>
      </div>
      <div>
        <span
          class="label label-warning"
          v-if="organisation.show_smartlink_alert"
          title='"Drama"-Hinweis auf Wenig Smartclicks ist aktiv'
        >
          <i class="fa fa-fw fa-angle-double-down"></i>
        </span>
        <span
          class="label label-warning"
          v-if="organisation.ignore_smartlinks"
          title="Smartclick Warnungen + Notbremse ist für Firma vom CM ausgenommen"
        >
          <svg
            height="10"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="hand-holding-medical"
            class="fa"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="currentColor"
              d="M159.88,175.82h64v64a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16v-64h64a16,16,0,0,0,16-16v-64a16,16,0,0,0-16-16h-64v-64a16,16,0,0,0-16-16h-64a16,16,0,0,0-16,16v64h-64a16,16,0,0,0-16,16v64A16,16,0,0,0,159.88,175.82ZM568.07,336.13a39.91,39.91,0,0,0-55.93-8.47L392.47,415.84H271.86a16,16,0,0,1,0-32H350.1c16,0,30.75-10.87,33.37-26.61a32.06,32.06,0,0,0-31.62-37.38h-160a117.7,117.7,0,0,0-74.12,26.25l-46.5,37.74H15.87a16.11,16.11,0,0,0-16,16v96a16.11,16.11,0,0,0,16,16h347a104.8,104.8,0,0,0,61.7-20.27L559.6,392A40,40,0,0,0,568.07,336.13Z"
            ></path>
          </svg>
        </span>
      </div>
    </td>
    <td><ValueWithFormatting :value="reviewInfo['BMS']" /></td>
    <td><ValueWithFormatting :value="reviewInfo['Reviewgespräch']" /></td>
    <td>
      <div
        style="display: flex"
        title="Aktive Nutzung von Services (EB-Dashboard Regeln)"
      >
        <img
          class="service-thumb"
          :src="services.kanaleo"
          v-if="organisation.services.kanaleo"
        />
        <img
          class="service-thumb"
          :src="services.coworkr"
          v-if="organisation.services.coworkr"
        />
        <img
          class="service-thumb"
          :src="services.jobwert"
          v-if="organisation.services.jobwert"
        />
        <img
          class="service-thumb"
          :src="services.fairekarriere"
          v-if="organisation.services.fairekarriere"
        />
      </div>
    </td>
    <td><ValueWithFormatting :value="reviewInfo['Backlink']" /></td>
    <td
      title="Anzahl Monate (max. 12) die ausgefüllt sind oder durch EBMS automatisch befüllt sind"
    >
      <ValueWithFormatting :value="reviewInfo['Statistik ausgefüllt']" />

      <div v-if='organisation.hired_with_eb'>
        <svg style="width:1em;height:1em;fill: currentColor" viewBox="0 0 24 24">
          <path fill="currentColor" d="M20,6C20.58,6 21.05,6.2 21.42,6.59C21.8,7 22,7.45 22,8V19C22,19.55 21.8,20 21.42,20.41C21.05,20.8 20.58,21 20,21H4C3.42,21 2.95,20.8 2.58,20.41C2.2,20 2,19.55 2,19V8C2,7.45 2.2,7 2.58,6.59C2.95,6.2 3.42,6 4,6H8V4C8,3.42 8.2,2.95 8.58,2.58C8.95,2.2 9.42,2 10,2H14C14.58,2 15.05,2.2 15.42,2.58C15.8,2.95 16,3.42 16,4V6H20M4,8V19H20V8H4M14,6V4H10V6H14Z" />
        </svg>
        <span style="text-align: right" title="Einstellung mit Bezug EB: GESAMT | Letzte 12 Monate lauf Bewerberstatistik">
          ∑
          {{ organisation.hired_with_eb.total }}
          /
          {{ organisation.hired_with_eb.last_12_months }}
          </span>
      </div>
    </td>
    <td style="text-align: right; padding-right: 10px">
      <span title="Anzahl der Vorgänge im aktuellen Kalenderjahr">
        {{ reviewInfo["Kundenaufwand"].value }}
      </span>
    </td>
    <td>
      <div class="quick-actions">
        <a
          class="btn btn-sm btn-primary js-modal"
          title="Maßnahme/Incident anlegen"
          :href="'/incidents/new?organisation_id=' + organisation.ID"
        >
          <i class="fa fa-thumb-tack fa-fw"></i>
        </a>
        <a
          class="btn btn-sm btn-primary js-modal"
          title="Vorgang anlegen"
          :href="'/vorgaenge/new?back=%2F&Organisation=' + organisation.ID"
        >
          <i class="fa fa-plus fa-fw"></i>
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import ValueWithFormatting from "../ValueWithFormatting.vue"
import MeasureChip from "./MeasureChip.vue"

export default {
  components: { ValueWithFormatting, MeasureChip },
  props: {
    organisation: { type: Object, required: true },
    services: { type: Object, required: true },
  },
  data() {
    return {
      measures: this.organisation.measures,
    }
  },
  computed: {
    partnerAngebot() {
      return this.organisation.angebot
    },
    gekuendigt() {
      return this.organisation.is_gekuendigt && !this.neukunde
    },
    neukunde() {
      return this.organisation.is_neukunde
    },
    reviewInfo() {
      return this.organisation.review_info
    },
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.quick-actions {
  opacity: 0.5;
  transition: opacity 0.2s ease-in;
  transform: scale(0.7);
  &:hover {
    opacity: 1;
  }
}
.kuendigungsgrund {
  padding-left: 10px;
  position: relative;
  z-index: 1;
  &:before {
    content: "‟";
    left: 0;
    font-size: 3rem;
    position: absolute;
    z-index: 0;
    color: rgba(0, 0, 0, 0.2);
  }
}

.service-thumb {
  height: 12px;
  filter: saturate(0.3);
  margin-right: 2px;
}
</style>
