/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /addons(.:format) */
export function addonsPath(options?: object): string {
  return "/" + "addons" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/addons(.:format) */
export function addonsAddonsPath(options?: object): string {
  return "/" + "addons" + "/" + "addons" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/addons/new(.:format) */
export function newAddonsAddonPath(options?: object): string {
  return "/" + "addons" + "/" + "addons" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/addons/:id/edit(.:format) */
export function editAddonsAddonPath(id: ScalarType, options?: object): string {
  return "/" + "addons" + "/" + "addons" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /addons/addons/:id(.:format) */
export function addonsAddonPath(id: ScalarType, options?: object): string {
  return "/" + "addons" + "/" + "addons" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /addons/bookings/:booking_id/renewal/new(.:format) */
export function newAddonsBookingRenewalPath(booking_id: ScalarType, options?: object): string {
  return "/" + "addons" + "/" + "bookings" + "/" + booking_id + "/" + "renewal" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["booking_id","format"]);
}

/** /addons/bookings/:booking_id/renewal(.:format) */
export function addonsBookingRenewalPath(booking_id: ScalarType, options?: object): string {
  return "/" + "addons" + "/" + "bookings" + "/" + booking_id + "/" + "renewal" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["booking_id","format"]);
}

/** /addons/bookings(.:format) */
export function addonsBookingsPath(options?: object): string {
  return "/" + "addons" + "/" + "bookings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/bookings/new(.:format) */
export function newAddonsBookingPath(options?: object): string {
  return "/" + "addons" + "/" + "bookings" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/bookings/:id/edit(.:format) */
export function editAddonsBookingPath(id: ScalarType, options?: object): string {
  return "/" + "addons" + "/" + "bookings" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /addons/bookings/:id(.:format) */
export function addonsBookingPath(id: ScalarType, options?: object): string {
  return "/" + "addons" + "/" + "bookings" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /addons/letter_of_intents(.:format) */
export function addonsLetterOfIntentsPath(options?: object): string {
  return "/" + "addons" + "/" + "letter_of_intents" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/letter_of_intents/new(.:format) */
export function newAddonsLetterOfIntentPath(options?: object): string {
  return "/" + "addons" + "/" + "letter_of_intents" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/letter_of_intents/:id/edit(.:format) */
export function editAddonsLetterOfIntentPath(id: ScalarType, options?: object): string {
  return "/" + "addons" + "/" + "letter_of_intents" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /addons/letter_of_intents/:id(.:format) */
export function addonsLetterOfIntentPath(id: ScalarType, options?: object): string {
  return "/" + "addons" + "/" + "letter_of_intents" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /addons/booking_cancel/new(.:format) */
export function newAddonsBookingCancelPath(options?: object): string {
  return "/" + "addons" + "/" + "booking_cancel" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/booking_cancel(.:format) */
export function addonsBookingCancelPath(options?: object): string {
  return "/" + "addons" + "/" + "booking_cancel" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/booking_new/new(.:format) */
export function newAddonsBookingNewPath(options?: object): string {
  return "/" + "addons" + "/" + "booking_new" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/booking_new(.:format) */
export function addonsBookingNewPath(options?: object): string {
  return "/" + "addons" + "/" + "booking_new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/activate/new(.:format) */
export function newAddonsActivatePath(options?: object): string {
  return "/" + "addons" + "/" + "activate" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/activate(.:format) */
export function addonsActivatePath(options?: object): string {
  return "/" + "addons" + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/offers/:offer_id/create_offer_pdf/new(.:format) */
export function newAddonsOfferCreateOfferPdfPath(offer_id: ScalarType, options?: object): string {
  return "/" + "addons" + "/" + "offers" + "/" + offer_id + "/" + "create_offer_pdf" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["offer_id","format"]);
}

/** /addons/offers/:offer_id/create_offer_pdf(.:format) */
export function addonsOfferCreateOfferPdfPath(offer_id: ScalarType, options?: object): string {
  return "/" + "addons" + "/" + "offers" + "/" + offer_id + "/" + "create_offer_pdf" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["offer_id","format"]);
}

/** /addons/offers(.:format) */
export function addonsOffersPath(options?: object): string {
  return "/" + "addons" + "/" + "offers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/offers/new(.:format) */
export function newAddonsOfferPath(options?: object): string {
  return "/" + "addons" + "/" + "offers" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /addons/offers/:id/edit(.:format) */
export function editAddonsOfferPath(id: ScalarType, options?: object): string {
  return "/" + "addons" + "/" + "offers" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /addons/offers/:id(.:format) */
export function addonsOfferPath(id: ScalarType, options?: object): string {
  return "/" + "addons" + "/" + "offers" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /self_service/login/:token(.:format) */
export function selfServiceLoginPath(token: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "login" + "/" + token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["token","format"]);
}

/** /self_service(.:format) */
export function selfServicePath(options?: object): string {
  return "/" + "self_service" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /self_service/logout(.:format) */
export function selfServiceLogoutPath(options?: object): string {
  return "/" + "self_service" + "/" + "logout" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /self_service/vertraege(.:format) */
export function selfServiceVertraegePath(options?: object): string {
  return "/" + "self_service" + "/" + "vertraege" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /self_service/addons/:addon_id/booking/billing(.:format) */
export function billingSelfServiceAddonBookingPath(addon_id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "addons" + "/" + addon_id + "/" + "booking" + "/" + "billing" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["addon_id","format"]);
}

/** /self_service/addons/:addon_id/booking/new(.:format) */
export function newSelfServiceAddonBookingPath(addon_id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "addons" + "/" + addon_id + "/" + "booking" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["addon_id","format"]);
}

/** /self_service/addons/:addon_id/booking(.:format) */
export function selfServiceAddonBookingPath(addon_id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "addons" + "/" + addon_id + "/" + "booking" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["addon_id","format"]);
}

/** /self_service/addons/:addon_id/activate(.:format) */
export function selfServiceAddonActivatePath(addon_id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "addons" + "/" + addon_id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["addon_id","format"]);
}

/** /self_service/addons/:addon_id/letter_of_intent/new(.:format) */
export function newSelfServiceAddonLetterOfIntentPath(addon_id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "addons" + "/" + addon_id + "/" + "letter_of_intent" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["addon_id","format"]);
}

/** /self_service/addons/:addon_id/letter_of_intent(.:format) */
export function selfServiceAddonLetterOfIntentPath(addon_id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "addons" + "/" + addon_id + "/" + "letter_of_intent" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["addon_id","format"]);
}

/** /self_service/addons(.:format) */
export function selfServiceAddonsPath(options?: object): string {
  return "/" + "self_service" + "/" + "addons" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /self_service/addons/:id(.:format) */
export function selfServiceAddonPath(id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "addons" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /self_service/rechnungen(.:format) */
export function selfServiceRechnungenPath(options?: object): string {
  return "/" + "self_service" + "/" + "rechnungen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /self_service/rechnungen/:id(.:format) */
export function selfServiceRechnungPath(id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "rechnungen" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /self_service/angebote/:id/edit(.:format) */
export function editSelfServiceAngebotPath(id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "angebote" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /self_service/angebote/:id(.:format) */
export function selfServiceAngebotPath(id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "angebote" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /self_service/angebote/:id/:document(.:format) */
export function selfServiceDownloadAngebotPath(id: ScalarType, document: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "angebote" + "/" + id + "/" + document + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","document","format"]);
}

/** /self_service/offers/:offer_id(.:format) */
export function selfServiceOfferPath(offer_id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "offers" + "/" + offer_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["offer_id","format"]);
}

/** /self_service/offers/:offer_id/billing(.:format) */
export function selfServiceBillingOfferPath(offer_id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "offers" + "/" + offer_id + "/" + "billing" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["offer_id","format"]);
}

/** /self_service/offers/:offer_id/billing(.:format) */
export function selfServiceCreateBillingOfferPath(offer_id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "offers" + "/" + offer_id + "/" + "billing" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["offer_id","format"]);
}

/** /self_service/advs(.:format) */
export function selfServiceAdvsPath(options?: object): string {
  return "/" + "self_service" + "/" + "advs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /self_service/advs/new(.:format) */
export function newSelfServiceAdvPath(options?: object): string {
  return "/" + "self_service" + "/" + "advs" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /self_service/advs/:id(.:format) */
export function selfServiceAdvPath(id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "advs" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /self_service/adv_consents(.:format) */
export function selfServiceAdvConsentsPath(options?: object): string {
  return "/" + "self_service" + "/" + "adv_consents" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /self_service/adv_consents/new(.:format) */
export function newSelfServiceAdvConsentPath(options?: object): string {
  return "/" + "self_service" + "/" + "adv_consents" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /self_service/adv_consents/:id(.:format) */
export function selfServiceAdvConsentPath(id: ScalarType, options?: object): string {
  return "/" + "self_service" + "/" + "adv_consents" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /self_service/employee_count/edit(.:format) */
export function editSelfServiceEmployeeCountPath(options?: object): string {
  return "/" + "self_service" + "/" + "employee_count" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /self_service/employee_count(.:format) */
export function selfServiceEmployeeCountPath(options?: object): string {
  return "/" + "self_service" + "/" + "employee_count" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /weaver(.:format) */
export function weaverPath(options?: object): string {
  return "/" + "weaver" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /weaver/organisations/search(.:format) */
export function searchWeaverOrganisationsPath(options?: object): string {
  return "/" + "weaver" + "/" + "organisations" + "/" + "search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /weaver/organisations/:id/spider_log(.:format) */
export function spiderLogWeaverOrganisationPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "organisations" + "/" + id + "/" + "spider_log" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/organisations/:id/crawl(.:format) */
export function crawlWeaverOrganisationPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "organisations" + "/" + id + "/" + "crawl" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/organisations(.:format) */
export function weaverOrganisationsPath(options?: object): string {
  return "/" + "weaver" + "/" + "organisations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /weaver/organisations/:id/edit(.:format) */
export function editWeaverOrganisationPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "organisations" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/organisations/:id(.:format) */
export function weaverOrganisationPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "organisations" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/organisation_addresses/:id(.:format) */
export function weaverOrganisationAddressPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "organisation_addresses" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/tasks(.:format) */
export function weaverTasksPath(options?: object): string {
  return "/" + "weaver" + "/" + "tasks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /weaver/tasks/new(.:format) */
export function newWeaverTaskPath(options?: object): string {
  return "/" + "weaver" + "/" + "tasks" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /weaver/tasks/:id/edit(.:format) */
export function editWeaverTaskPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "tasks" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/tasks/:id(.:format) */
export function weaverTaskPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "tasks" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/jobs/:id(.:format) */
export function weaverJobPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "jobs" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/auto_mailings/:id/start(.:format) */
export function startWeaverAutoMailingPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "auto_mailings" + "/" + id + "/" + "start" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/auto_mailings/:id/preview_person(.:format) */
export function previewPersonWeaverAutoMailingPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "auto_mailings" + "/" + id + "/" + "preview_person" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/auto_mailings/preview(.:format) */
export function previewWeaverAutoMailingsPath(options?: object): string {
  return "/" + "weaver" + "/" + "auto_mailings" + "/" + "preview" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /weaver/auto_mailings(.:format) */
export function weaverAutoMailingsPath(options?: object): string {
  return "/" + "weaver" + "/" + "auto_mailings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /weaver/auto_mailings/new(.:format) */
export function newWeaverAutoMailingPath(options?: object): string {
  return "/" + "weaver" + "/" + "auto_mailings" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /weaver/auto_mailings/:id/edit(.:format) */
export function editWeaverAutoMailingPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "auto_mailings" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/auto_mailings/:id(.:format) */
export function weaverAutoMailingPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "auto_mailings" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/people/:id/guess_mail(.:format) */
export function guessMailWeaverPersonPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "people" + "/" + id + "/" + "guess_mail" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/people/:id/emails(.:format) */
export function emailsWeaverPersonPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "people" + "/" + id + "/" + "emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/people(.:format) */
export function weaverPeoplePath(options?: object): string {
  return "/" + "weaver" + "/" + "people" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /weaver/people/new(.:format) */
export function newWeaverPersonPath(options?: object): string {
  return "/" + "weaver" + "/" + "people" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /weaver/people/:id/edit(.:format) */
export function editWeaverPersonPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "people" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/people/:id(.:format) */
export function weaverPersonPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "people" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/tasks/:id/run(.:format) */
export function weaverRunTaskPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "tasks" + "/" + id + "/" + "run" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/results/:id/run(.:format) */
export function weaverRunResultPath(id: ScalarType, options?: object): string {
  return "/" + "weaver" + "/" + "results" + "/" + id + "/" + "run" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /weaver/organisation_assignment(.:format) */
export function weaverOrganisationAssignmentPath(options?: object): string {
  return "/" + "weaver" + "/" + "organisation_assignment" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /events/:event_id/join(.:format) */
export function joinEventPath(event_id: ScalarType, options?: object): string {
  return "/" + "events" + "/" + event_id + "/" + "join" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["event_id","format"]);
}

/** /community_workshop/segments(.:format) */
export function communityWorkshopSegmentsPath(options?: object): string {
  return "/" + "community_workshop" + "/" + "segments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /community_workshop/segments/new(.:format) */
export function newCommunityWorkshopSegmentPath(options?: object): string {
  return "/" + "community_workshop" + "/" + "segments" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /community_workshop/segments/:id/edit(.:format) */
export function editCommunityWorkshopSegmentPath(id: ScalarType, options?: object): string {
  return "/" + "community_workshop" + "/" + "segments" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /community_workshop/segments/:id(.:format) */
export function communityWorkshopSegmentPath(id: ScalarType, options?: object): string {
  return "/" + "community_workshop" + "/" + "segments" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /community_workshops/:id/invite(.:format) */
export function inviteCommunityWorkshopPath(id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + id + "/" + "invite" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /community_workshops/:id/:event_id/teilnehmerliste(.:format) */
export function teilnehmerlisteCommunityWorkshopPath(id: ScalarType, event_id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + id + "/" + event_id + "/" + "teilnehmerliste" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","event_id","format"]);
}

/** /community_workshops/:id/:event_id/name_tags(.:format) */
export function nameTagsCommunityWorkshopPath(id: ScalarType, event_id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + id + "/" + event_id + "/" + "name_tags" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","event_id","format"]);
}

/** /community_workshops/:id/unsubscribe/:token/comment(.:format) */
export function unsubscribeCommunityWorkshopPath(id: ScalarType, token: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + id + "/" + "unsubscribe" + "/" + token + "/" + "comment" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","token","format"]);
}

/** /community_workshops/:community_workshop_id/participants/:id/confirm(.:format) */
export function confirmCommunityWorkshopParticipantPath(community_workshop_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + community_workshop_id + "/" + "participants" + "/" + id + "/" + "confirm" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["community_workshop_id","id","format"]);
}

/** /community_workshops/:community_workshop_id/participants/:id/final_confirm(.:format) */
export function finalConfirmCommunityWorkshopParticipantPath(community_workshop_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + community_workshop_id + "/" + "participants" + "/" + id + "/" + "final_confirm" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["community_workshop_id","id","format"]);
}

/** /community_workshops/:community_workshop_id/participants/:id/decline(.:format) */
export function declineCommunityWorkshopParticipantPath(community_workshop_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + community_workshop_id + "/" + "participants" + "/" + id + "/" + "decline" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["community_workshop_id","id","format"]);
}

/** /community_workshops/:community_workshop_id/participants/:id/send_testmail(.:format) */
export function sendTestmailCommunityWorkshopParticipantPath(community_workshop_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + community_workshop_id + "/" + "participants" + "/" + id + "/" + "send_testmail" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["community_workshop_id","id","format"]);
}

/** /community_workshops/:community_workshop_id/participants/bounced(.:format) */
export function bouncedCommunityWorkshopParticipantsPath(community_workshop_id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + community_workshop_id + "/" + "participants" + "/" + "bounced" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["community_workshop_id","format"]);
}

/** /community_workshops/:community_workshop_id/participants(.:format) */
export function communityWorkshopParticipantsPath(community_workshop_id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + community_workshop_id + "/" + "participants" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["community_workshop_id","format"]);
}

/** /community_workshops/:community_workshop_id/participants/new(.:format) */
export function newCommunityWorkshopParticipantPath(community_workshop_id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + community_workshop_id + "/" + "participants" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["community_workshop_id","format"]);
}

/** /community_workshops/:community_workshop_id/participants/:id/edit(.:format) */
export function editCommunityWorkshopParticipantPath(community_workshop_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + community_workshop_id + "/" + "participants" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["community_workshop_id","id","format"]);
}

/** /community_workshops/:community_workshop_id/participants/:id(.:format) */
export function communityWorkshopParticipantPath(community_workshop_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + community_workshop_id + "/" + "participants" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["community_workshop_id","id","format"]);
}

/** /community_workshops(.:format) */
export function communityWorkshopsPath(options?: object): string {
  return "/" + "community_workshops" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /community_workshops/new(.:format) */
export function newCommunityWorkshopPath(options?: object): string {
  return "/" + "community_workshops" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /community_workshops/:id/edit(.:format) */
export function editCommunityWorkshopPath(id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /community_workshops/:id(.:format) */
export function communityWorkshopPath(id: ScalarType, options?: object): string {
  return "/" + "community_workshops" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mailings/:id/recipients(.:format) */
export function recipientsMailingPath(id: ScalarType, options?: object): string {
  return "/" + "mailings" + "/" + id + "/" + "recipients" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mailings/:id/csv(.:format) */
export function csvMailingPath(id: ScalarType, options?: object): string {
  return "/" + "mailings" + "/" + id + "/" + "csv" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mailings/:id/search(.:format) */
export function searchMailingPath(id: ScalarType, options?: object): string {
  return "/" + "mailings" + "/" + id + "/" + "search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mailings/:id/preview(.:format) */
export function previewMailingPath(id: ScalarType, options?: object): string {
  return "/" + "mailings" + "/" + id + "/" + "preview" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mailings/:id/templates(.:format) */
export function templatesMailingPath(id: ScalarType, options?: object): string {
  return "/" + "mailings" + "/" + id + "/" + "templates" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mailings/:id/deliver(.:format) */
export function deliverMailingPath(id: ScalarType, options?: object): string {
  return "/" + "mailings" + "/" + id + "/" + "deliver" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mailings/image_upload(.:format) */
export function imageUploadMailingsPath(options?: object): string {
  return "/" + "mailings" + "/" + "image_upload" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mailings(.:format) */
export function mailingsPath(options?: object): string {
  return "/" + "mailings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mailings/new(.:format) */
export function newMailingPath(options?: object): string {
  return "/" + "mailings" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mailings/:id/edit(.:format) */
export function editMailingPath(id: ScalarType, options?: object): string {
  return "/" + "mailings" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mailings/:id(.:format) */
export function mailingPath(id: ScalarType, options?: object): string {
  return "/" + "mailings" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mailings/recipients(.:format) */
export function mailingsRecipientsPath(options?: object): string {
  return "/" + "mailings" + "/" + "recipients" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mailings/recipients/options(.:format) */
export function mailingsRecipientsOptionsPath(options?: object): string {
  return "/" + "mailings" + "/" + "recipients" + "/" + "options" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/community_members(.:format) */
export function statisticsCommunityMembersPath(options?: object): string {
  return "/" + "statistics" + "/" + "community_members" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/umsatz(.:format) */
export function statisticsUmsatzPath(options?: object): string {
  return "/" + "statistics" + "/" + "umsatz" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/vorgaenge(.:format) */
export function statisticsVorgaengePath(options?: object): string {
  return "/" + "statistics" + "/" + "vorgaenge" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/personen(.:format) */
export function statisticsPersonenPath(options?: object): string {
  return "/" + "statistics" + "/" + "personen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/organisationen(.:format) */
export function statisticsOrganisationenPath(options?: object): string {
  return "/" + "statistics" + "/" + "organisationen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/neue_organisationen(.:format) */
export function statisticsNeueOrganisationenPath(options?: object): string {
  return "/" + "statistics" + "/" + "neue_organisationen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/community_manager(.:format) */
export function statisticsCommunityManagerPath(options?: object): string {
  return "/" + "statistics" + "/" + "community_manager" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/akquise(.:format) */
export function statisticsAkquisePath(options?: object): string {
  return "/" + "statistics" + "/" + "akquise" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/kuendigungen(.:format) */
export function statisticsKuendigungenPath(options?: object): string {
  return "/" + "statistics" + "/" + "kuendigungen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/neuabschluesse(.:format) */
export function statisticsNeuabschluessePath(options?: object): string {
  return "/" + "statistics" + "/" + "neuabschluesse" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/jourfix(.:format) */
export function statisticsJourfixPath(options?: object): string {
  return "/" + "statistics" + "/" + "jourfix" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/umsatzbilanz(.:format) */
export function statisticsUmsatzbilanzPath(options?: object): string {
  return "/" + "statistics" + "/" + "umsatzbilanz" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /statistics/cm_pm(.:format) */
export function statisticsCmPmPath(options?: object): string {
  return "/" + "statistics" + "/" + "cm_pm" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mt/:token(.:format) */
export function mailTrackingsClickPath(token: ScalarType, options?: object): string {
  return "/" + "mt" + "/" + token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["token","format"]);
}

/** /mt/gif/:token(.:format) */
export function mailTrackingsOpenPath(token: ScalarType, options?: object): string {
  return "/" + "mt" + "/" + "gif" + "/" + token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["token","format"]);
}

/** /print_month(.:format) */
export function printMonthIndexPath(options?: object): string {
  return "/" + "print_month" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /print_month/:id(.:format) */
export function printMonthPath(id: ScalarType, options?: object): string {
  return "/" + "print_month" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rechnung_export_months(.:format) */
export function rechnungExportMonthsPath(options?: object): string {
  return "/" + "rechnung_export_months" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rechnung_export_months/:id(.:format) */
export function rechnungExportMonthPath(id: ScalarType, options?: object): string {
  return "/" + "rechnung_export_months" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rechnung/debitor_update(.:format) */
export function rechnungDebitorUpdatePath(options?: object): string {
  return "/" + "rechnung" + "/" + "debitor_update" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kundenzufriedenheit/for/:token(.:format) */
export function kundenzufriedenheitCreatePath(token: ScalarType, options?: object): string {
  return "/" + "kundenzufriedenheit" + "/" + "for" + "/" + token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["token","format"]);
}

/** /kundenzufriedenheit/:token(.:format) */
export function kundenzufriedenheitPath(token: ScalarType, options?: object): string {
  return "/" + "kundenzufriedenheit" + "/" + token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["token","format"]);
}

/** /organisation_surveys/:token(.:format) */
export function organisationSurveyPath(token: ScalarType, options?: object): string {
  return "/" + "organisation_surveys" + "/" + token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["token","format"]);
}

/** /organisation/gitlab_issues(.:format) */
export function organisationGitlabIssuesPath(options?: object): string {
  return "/" + "organisation" + "/" + "gitlab_issues" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /organisation/advs/:id/recreate(.:format) */
export function recreateOrganisationAdvPath(id: ScalarType, options?: object): string {
  return "/" + "organisation" + "/" + "advs" + "/" + id + "/" + "recreate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /organisation/advs/recreate_all(.:format) */
export function recreateAllOrganisationAdvsPath(options?: object): string {
  return "/" + "organisation" + "/" + "advs" + "/" + "recreate_all" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /organisation/advs/create(.:format) */
export function createOrganisationAdvsPath(options?: object): string {
  return "/" + "organisation" + "/" + "advs" + "/" + "create" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /organisation/advs(.:format) */
export function organisationAdvsPath(options?: object): string {
  return "/" + "organisation" + "/" + "advs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /organisation/advs/:id(.:format) */
export function organisationAdvPath(id: ScalarType, options?: object): string {
  return "/" + "organisation" + "/" + "advs" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /organisation/adv_consents(.:format) */
export function organisationAdvConsentsPath(options?: object): string {
  return "/" + "organisation" + "/" + "adv_consents" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /organisation/adv_consents/new(.:format) */
export function newOrganisationAdvConsentPath(options?: object): string {
  return "/" + "organisation" + "/" + "adv_consents" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /organisation/adv_consents/:id(.:format) */
export function organisationAdvConsentPath(id: ScalarType, options?: object): string {
  return "/" + "organisation" + "/" + "adv_consents" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /organisation/patientenakten(.:format) */
export function organisationPatientenaktenPath(options?: object): string {
  return "/" + "organisation" + "/" + "patientenakten" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /organisation/patientenakten/new(.:format) */
export function newOrganisationPatientenaktePath(options?: object): string {
  return "/" + "organisation" + "/" + "patientenakten" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /organisation/patientenakten/:id/edit(.:format) */
export function editOrganisationPatientenaktePath(id: ScalarType, options?: object): string {
  return "/" + "organisation" + "/" + "patientenakten" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /organisation/patientenakten/:id(.:format) */
export function organisationPatientenaktePath(id: ScalarType, options?: object): string {
  return "/" + "organisation" + "/" + "patientenakten" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /organisationen/:id/assign(.:format) */
export function assignOrganisationPath(id: ScalarType, options?: object): string {
  return "/" + "organisationen" + "/" + id + "/" + "assign" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /organisationen/:id/assign_aquired(.:format) */
export function assignAquiredOrganisationPath(id: ScalarType, options?: object): string {
  return "/" + "organisationen" + "/" + id + "/" + "assign_aquired" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /organisationen/:id/vorgaenge(.:format) */
export function vorgaengeOrganisationPath(id: ScalarType, options?: object): string {
  return "/" + "organisationen" + "/" + id + "/" + "vorgaenge" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /organisationen/:id/rename_mail_domain(.:format) */
export function renameMailDomainOrganisationPath(id: ScalarType, options?: object): string {
  return "/" + "organisationen" + "/" + id + "/" + "rename_mail_domain" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /organisationen/:organisation_id/organisation_survey_invitation_form/new(.:format) */
export function newOrganisationOrganisationSurveyInvitationFormPath(organisation_id: ScalarType, options?: object): string {
  return "/" + "organisationen" + "/" + organisation_id + "/" + "organisation_survey_invitation_form" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["organisation_id","format"]);
}

/** /organisationen/:organisation_id/organisation_survey_invitation_form(.:format) */
export function organisationOrganisationSurveyInvitationFormPath(organisation_id: ScalarType, options?: object): string {
  return "/" + "organisationen" + "/" + organisation_id + "/" + "organisation_survey_invitation_form" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["organisation_id","format"]);
}

/** /organisationen(.:format) */
export function organisationenPath(options?: object): string {
  return "/" + "organisationen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /organisationen/new(.:format) */
export function newOrganisationPath(options?: object): string {
  return "/" + "organisationen" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /organisationen/:id/edit(.:format) */
export function editOrganisationPath(id: ScalarType, options?: object): string {
  return "/" + "organisationen" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /organisationen/:id(.:format) */
export function organisationPath(id: ScalarType, options?: object): string {
  return "/" + "organisationen" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /gdpr_consents/:id(.:format) */
export function gdprConsentPath(id: ScalarType, options?: object): string {
  return "/" + "gdpr_consents" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /vorgaenge/calculate_journey_time(.:format) */
export function calculateJourneyTimeVorgaengePath(options?: object): string {
  return "/" + "vorgaenge" + "/" + "calculate_journey_time" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /vorgaenge/select_type(.:format) */
export function selectTypeVorgaengePath(options?: object): string {
  return "/" + "vorgaenge" + "/" + "select_type" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /vorgaenge/:id/remove_reminder(.:format) */
export function removeReminderVorgangPath(id: ScalarType, options?: object): string {
  return "/" + "vorgaenge" + "/" + id + "/" + "remove_reminder" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /vorgaenge/:id/mark_as_done(.:format) */
export function markAsDoneVorgangPath(id: ScalarType, options?: object): string {
  return "/" + "vorgaenge" + "/" + id + "/" + "mark_as_done" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /vorgaenge(.:format) */
export function vorgaengePath(options?: object): string {
  return "/" + "vorgaenge" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /vorgaenge/new(.:format) */
export function newVorgangPath(options?: object): string {
  return "/" + "vorgaenge" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /vorgaenge/:id/edit(.:format) */
export function editVorgangPath(id: ScalarType, options?: object): string {
  return "/" + "vorgaenge" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /vorgaenge/:id(.:format) */
export function vorgangPath(id: ScalarType, options?: object): string {
  return "/" + "vorgaenge" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /standorte(.:format) */
export function standortePath(options?: object): string {
  return "/" + "standorte" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /standorte/new(.:format) */
export function newStandortPath(options?: object): string {
  return "/" + "standorte" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /standorte/:id/edit(.:format) */
export function editStandortPath(id: ScalarType, options?: object): string {
  return "/" + "standorte" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /standorte/:id(.:format) */
export function standortPath(id: ScalarType, options?: object): string {
  return "/" + "standorte" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /personen/:id/transfer(.:format) */
export function transferPersonPath(id: ScalarType, options?: object): string {
  return "/" + "personen" + "/" + id + "/" + "transfer" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /personen/:id/mailing_histories(.:format) */
export function mailingHistoriesPersonPath(id: ScalarType, options?: object): string {
  return "/" + "personen" + "/" + id + "/" + "mailing_histories" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /personen/:id/archive(.:format) */
export function archivePersonPath(id: ScalarType, options?: object): string {
  return "/" + "personen" + "/" + id + "/" + "archive" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /personen(.:format) */
export function personenPath(options?: object): string {
  return "/" + "personen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /personen/new(.:format) */
export function newPersonPath(options?: object): string {
  return "/" + "personen" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /personen/:id/edit(.:format) */
export function editPersonPath(id: ScalarType, options?: object): string {
  return "/" + "personen" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /personen/:id(.:format) */
export function personPath(id: ScalarType, options?: object): string {
  return "/" + "personen" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mail_tracking/mail_addresses/block(.:format) */
export function blockMailTrackingMailAddressesPath(options?: object): string {
  return "/" + "mail_tracking" + "/" + "mail_addresses" + "/" + "block" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mail_tracking/mail_addresses/:id(.:format) */
export function mailTrackingMailAddressPath(id: ScalarType, options?: object): string {
  return "/" + "mail_tracking" + "/" + "mail_addresses" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mail_tracking/mails/:id(.:format) */
export function mailTrackingMailPath(id: ScalarType, options?: object): string {
  return "/" + "mail_tracking" + "/" + "mails" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mail_tracking/campaigns(.:format) */
export function mailTrackingCampaignsPath(options?: object): string {
  return "/" + "mail_tracking" + "/" + "campaigns" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mail_tracking/campaigns/:id(.:format) */
export function mailTrackingCampaignPath(id: ScalarType, options?: object): string {
  return "/" + "mail_tracking" + "/" + "campaigns" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mailing/unsubscribe/:token(.:format) */
export function unsubscribePath(token: ScalarType, options?: object): string {
  return "/" + "mailing" + "/" + "unsubscribe" + "/" + token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["token","format"]);
}

/** /mailing/unsubscribe(.:format) */
export function unsubscribeSubmitPath(options?: object): string {
  return "/" + "mailing" + "/" + "unsubscribe" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /angebote/:id/renewal/new(.:format) */
export function newRenewalPath(id: ScalarType, options?: object): string {
  return "/" + "angebote" + "/" + id + "/" + "renewal" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /angebote/:id/renewal(.:format) */
export function renewalPath(id: ScalarType, options?: object): string {
  return "/" + "angebote" + "/" + id + "/" + "renewal" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /angebote/:id/cancellation/new(.:format) */
export function newCancellationPath(id: ScalarType, options?: object): string {
  return "/" + "angebote" + "/" + id + "/" + "cancellation" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /angebote/:id/cancellation(.:format) */
export function cancellationPath(id: ScalarType, options?: object): string {
  return "/" + "angebote" + "/" + id + "/" + "cancellation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /angebote/:id/downgrade/new(.:format) */
export function newDowngradePath(id: ScalarType, options?: object): string {
  return "/" + "angebote" + "/" + id + "/" + "downgrade" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /angebote/:id/downgrade(.:format) */
export function downgradePath(id: ScalarType, options?: object): string {
  return "/" + "angebote" + "/" + id + "/" + "downgrade" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /angebote/:id/cancel_storno(.:format) */
export function cancelStornoAngebotPath(id: ScalarType, options?: object): string {
  return "/" + "angebote" + "/" + id + "/" + "cancel_storno" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /angebote/:id/pause(.:format) */
export function pauseAngebotPath(id: ScalarType, options?: object): string {
  return "/" + "angebote" + "/" + id + "/" + "pause" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /angebote/:id/attachments(.:format) */
export function attachmentsAngebotPath(id: ScalarType, options?: object): string {
  return "/" + "angebote" + "/" + id + "/" + "attachments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /angebote(.:format) */
export function angebotePath(options?: object): string {
  return "/" + "angebote" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /angebote/new(.:format) */
export function newAngebotPath(options?: object): string {
  return "/" + "angebote" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /angebote/:id/edit(.:format) */
export function editAngebotPath(id: ScalarType, options?: object): string {
  return "/" + "angebote" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /angebote/:id(.:format) */
export function angebotPath(id: ScalarType, options?: object): string {
  return "/" + "angebote" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mitgliedsstatuses(.:format) */
export function mitgliedsstatusesPath(options?: object): string {
  return "/" + "mitgliedsstatuses" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mitgliedsstatuses/new(.:format) */
export function newMitgliedsstatusPath(options?: object): string {
  return "/" + "mitgliedsstatuses" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mitgliedsstatuses/:id/edit(.:format) */
export function editMitgliedsstatusPath(id: ScalarType, options?: object): string {
  return "/" + "mitgliedsstatuses" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mitgliedsstatuses/:id(.:format) */
export function mitgliedsstatusPath(id: ScalarType, options?: object): string {
  return "/" + "mitgliedsstatuses" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rechnungen/demand_all(.:format) */
export function demandAllRechnungenPath(options?: object): string {
  return "/" + "rechnungen" + "/" + "demand_all" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rechnungen/demand_all(.:format) */
export function rechnungenDemandAllPath(options?: object): string {
  return "/" + "rechnungen" + "/" + "demand_all" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rechnungen/:id/mark_as_paid(.:format) */
export function markAsPaidRechnungPath(id: ScalarType, options?: object): string {
  return "/" + "rechnungen" + "/" + id + "/" + "mark_as_paid" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rechnungen(.:format) */
export function rechnungenPath(options?: object): string {
  return "/" + "rechnungen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rechnungen/new(.:format) */
export function newRechnungPath(options?: object): string {
  return "/" + "rechnungen" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rechnungen/:id/edit(.:format) */
export function editRechnungPath(id: ScalarType, options?: object): string {
  return "/" + "rechnungen" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rechnungen/:id(.:format) */
export function rechnungPath(id: ScalarType, options?: object): string {
  return "/" + "rechnungen" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rechnungen/:id/demand(.:format) */
export function demandRechnungPath(id: ScalarType, options?: object): string {
  return "/" + "rechnungen" + "/" + id + "/" + "demand" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rechnungen/:id/resend(.:format) */
export function resendRechnungPath(id: ScalarType, options?: object): string {
  return "/" + "rechnungen" + "/" + id + "/" + "resend" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /versions(.:format) */
export function versionsPath(options?: object): string {
  return "/" + "versions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /versions/new(.:format) */
export function newVersionPath(options?: object): string {
  return "/" + "versions" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /versions/:id/edit(.:format) */
export function editVersionPath(id: ScalarType, options?: object): string {
  return "/" + "versions" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /versions/:id(.:format) */
export function versionPath(id: ScalarType, options?: object): string {
  return "/" + "versions" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /queued_mails/:id/send_now(.:format) */
export function sendNowQueuedMailPath(id: ScalarType, options?: object): string {
  return "/" + "queued_mails" + "/" + id + "/" + "send_now" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /queued_mails/preview(.:format) */
export function previewQueuedMailsPath(options?: object): string {
  return "/" + "queued_mails" + "/" + "preview" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /queued_mails(.:format) */
export function queuedMailsPath(options?: object): string {
  return "/" + "queued_mails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /queued_mails/new(.:format) */
export function newQueuedMailPath(options?: object): string {
  return "/" + "queued_mails" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /queued_mails/:id/edit(.:format) */
export function editQueuedMailPath(id: ScalarType, options?: object): string {
  return "/" + "queued_mails" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /queued_mails/:id(.:format) */
export function queuedMailPath(id: ScalarType, options?: object): string {
  return "/" + "queued_mails" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mail_copies/fetch(.:format) */
export function fetchMailCopiesPath(options?: object): string {
  return "/" + "mail_copies" + "/" + "fetch" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mail_copies(.:format) */
export function mailCopiesPath(options?: object): string {
  return "/" + "mail_copies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mail_copies/new(.:format) */
export function newMailCopyPath(options?: object): string {
  return "/" + "mail_copies" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mail_copies/:id/edit(.:format) */
export function editMailCopyPath(id: ScalarType, options?: object): string {
  return "/" + "mail_copies" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mail_copies/:id(.:format) */
export function mailCopyPath(id: ScalarType, options?: object): string {
  return "/" + "mail_copies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /search(.:format) */
export function searchPath(options?: object): string {
  return "/" + "search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /company/merge(.:format) */
export function companyMergePath(options?: object): string {
  return "/" + "company" + "/" + "merge" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /autocomplete/organisation(.:format) */
export function autocompleteOrganisationPath(options?: object): string {
  return "/" + "autocomplete" + "/" + "organisation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /autocomplete/organisation_locations(.:format) */
export function autocompleteOrganisationLocationsPath(options?: object): string {
  return "/" + "autocomplete" + "/" + "organisation_locations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /autocomplete/standort(.:format) */
export function autocompleteStandortPath(options?: object): string {
  return "/" + "autocomplete" + "/" + "standort" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /autocomplete/google_places(.:format) */
export function autocompleteGooglePlacesPath(options?: object): string {
  return "/" + "autocomplete" + "/" + "google_places" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /autocomplete/communities(.:format) */
export function autocompleteCommunitiesPath(options?: object): string {
  return "/" + "autocomplete" + "/" + "communities" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api(.:format) */
export function apiPath(options?: object): string {
  return "/" + "api" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api */
export function apiV1Path(options?: object): string {
  return "/" + "api" + $buildOptions(options, []);
}

/** /opus(.:format) */
export function opusPath(options?: object): string {
  return "/" + "opus" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /dashboard/ajax/reminder(.:format) */
export function dashboardAjaxReminderPath(options?: object): string {
  return "/" + "dashboard" + "/" + "ajax" + "/" + "reminder" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /dashboard/ajax/changes(.:format) */
export function dashboardAjaxChangesPath(options?: object): string {
  return "/" + "dashboard" + "/" + "ajax" + "/" + "changes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /dashboard/ajax/incidents(.:format) */
export function dashboardAjaxIncidentsPath(options?: object): string {
  return "/" + "dashboard" + "/" + "ajax" + "/" + "incidents" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /dashboard/ajax/partner(.:format) */
export function dashboardAjaxPartnerPath(options?: object): string {
  return "/" + "dashboard" + "/" + "ajax" + "/" + "partner" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /dashboard/ajax/foerderer(.:format) */
export function dashboardAjaxFoerdererPath(options?: object): string {
  return "/" + "dashboard" + "/" + "ajax" + "/" + "foerderer" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /dashboard/ajax/foerderer_unternehmen(.:format) */
export function dashboardAjaxFoerdererUnternehmenPath(options?: object): string {
  return "/" + "dashboard" + "/" + "ajax" + "/" + "foerderer_unternehmen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /dashboard/ajax/interessenten(.:format) */
export function dashboardAjaxInteressentenPath(options?: object): string {
  return "/" + "dashboard" + "/" + "ajax" + "/" + "interessenten" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /dashboard/ajax/handlungskompetenz(.:format) */
export function dashboardAjaxHandlungskompetenzPath(options?: object): string {
  return "/" + "dashboard" + "/" + "ajax" + "/" + "handlungskompetenz" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /graphql(.:format) */
export function graphqlPath(options?: object): string {
  return "/" + "graphql" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /graphiql */
export function graphiqlRailsPath(options?: object): string {
  return "/" + "graphiql" + $buildOptions(options, []);
}

/** /community_managers/sign_in(.:format) */
export function newCommunityManagerSessionPath(options?: object): string {
  return "/" + "community_managers" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /community_managers/sign_in(.:format) */
export function communityManagerSessionPath(options?: object): string {
  return "/" + "community_managers" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /community_managers/sign_out(.:format) */
export function destroyCommunityManagerSessionPath(options?: object): string {
  return "/" + "community_managers" + "/" + "sign_out" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /community_managers/password/new(.:format) */
export function newCommunityManagerPasswordPath(options?: object): string {
  return "/" + "community_managers" + "/" + "password" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /community_managers/password/edit(.:format) */
export function editCommunityManagerPasswordPath(options?: object): string {
  return "/" + "community_managers" + "/" + "password" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /community_managers/password(.:format) */
export function communityManagerPasswordPath(options?: object): string {
  return "/" + "community_managers" + "/" + "password" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** / */
export function dashboardPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /mail_check(.:format) */
export function mailCheckPath(options?: object): string {
  return "/" + "mail_check" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mail_check/block(.:format) */
export function mailCheckBlockPath(options?: object): string {
  return "/" + "mail_check" + "/" + "block" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /aenderungen(.:format) */
export function aenderungenPath(options?: object): string {
  return "/" + "aenderungen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kontingent/rahmenvertraege/:id/activate(.:format) */
export function activateKontingentRahmenvertragPath(id: ScalarType, options?: object): string {
  return "/" + "kontingent" + "/" + "rahmenvertraege" + "/" + id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /kontingent/rahmenvertraege/:id/csv(.:format) */
export function csvKontingentRahmenvertragPath(id: ScalarType, options?: object): string {
  return "/" + "kontingent" + "/" + "rahmenvertraege" + "/" + id + "/" + "csv" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /kontingent/rahmenvertraege(.:format) */
export function kontingentRahmenvertraegePath(options?: object): string {
  return "/" + "kontingent" + "/" + "rahmenvertraege" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kontingent/rahmenvertraege/new(.:format) */
export function newKontingentRahmenvertragPath(options?: object): string {
  return "/" + "kontingent" + "/" + "rahmenvertraege" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kontingent/rahmenvertraege/:id/edit(.:format) */
export function editKontingentRahmenvertragPath(id: ScalarType, options?: object): string {
  return "/" + "kontingent" + "/" + "rahmenvertraege" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /kontingent/rahmenvertraege/:id(.:format) */
export function kontingentRahmenvertragPath(id: ScalarType, options?: object): string {
  return "/" + "kontingent" + "/" + "rahmenvertraege" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /kontingent/communication_logs(.:format) */
export function kontingentCommunicationLogsPath(options?: object): string {
  return "/" + "kontingent" + "/" + "communication_logs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kontingent/communication_logs/new(.:format) */
export function newKontingentCommunicationLogPath(options?: object): string {
  return "/" + "kontingent" + "/" + "communication_logs" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kontingent/communication_logs/:id/edit(.:format) */
export function editKontingentCommunicationLogPath(id: ScalarType, options?: object): string {
  return "/" + "kontingent" + "/" + "communication_logs" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /kontingent/communication_logs/:id(.:format) */
export function kontingentCommunicationLogPath(id: ScalarType, options?: object): string {
  return "/" + "kontingent" + "/" + "communication_logs" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /kontingent/angebote/:id/supplement_usage(.:format) */
export function supplementUsageKontingentAngebotPath(id: ScalarType, options?: object): string {
  return "/" + "kontingent" + "/" + "angebote" + "/" + id + "/" + "supplement_usage" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /kontingent/angebote(.:format) */
export function kontingentAngebotePath(options?: object): string {
  return "/" + "kontingent" + "/" + "angebote" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kontingent/angebote/new(.:format) */
export function newKontingentAngebotPath(options?: object): string {
  return "/" + "kontingent" + "/" + "angebote" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kontingent/angebote/:id/edit(.:format) */
export function editKontingentAngebotPath(id: ScalarType, options?: object): string {
  return "/" + "kontingent" + "/" + "angebote" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /kontingent/angebote/:id(.:format) */
export function kontingentAngebotPath(id: ScalarType, options?: object): string {
  return "/" + "kontingent" + "/" + "angebote" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /kontingent/events(.:format) */
export function kontingentEventsPath(options?: object): string {
  return "/" + "kontingent" + "/" + "events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kontingent/events/new(.:format) */
export function newKontingentEventPath(options?: object): string {
  return "/" + "kontingent" + "/" + "events" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kontingent/events/:id/edit(.:format) */
export function editKontingentEventPath(id: ScalarType, options?: object): string {
  return "/" + "kontingent" + "/" + "events" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /kontingent/events/:id(.:format) */
export function kontingentEventPath(id: ScalarType, options?: object): string {
  return "/" + "kontingent" + "/" + "events" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /kontingent/attachments(.:format) */
export function kontingentAttachmentsPath(options?: object): string {
  return "/" + "kontingent" + "/" + "attachments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kontingent/attachments/new(.:format) */
export function newKontingentAttachmentPath(options?: object): string {
  return "/" + "kontingent" + "/" + "attachments" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /kontingent/attachments/:id/edit(.:format) */
export function editKontingentAttachmentPath(id: ScalarType, options?: object): string {
  return "/" + "kontingent" + "/" + "attachments" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /kontingent/attachments/:id(.:format) */
export function kontingentAttachmentPath(id: ScalarType, options?: object): string {
  return "/" + "kontingent" + "/" + "attachments" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /service_contracts/:id/edit(.:format) */
export function editServiceContractPath(id: ScalarType, options?: object): string {
  return "/" + "service_contracts" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /service_contracts/:id(.:format) */
export function serviceContractPath(id: ScalarType, options?: object): string {
  return "/" + "service_contracts" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /newsletter(.:format) */
export function newsletterPath(options?: object): string {
  return "/" + "newsletter" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mailchimp_import/subscribe(.:format) */
export function mailchimpImportSubscribePath(options?: object): string {
  return "/" + "mailchimp_import" + "/" + "subscribe" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /login(.:format) */
export function loginPath(options?: object): string {
  return "/" + "login" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** / */
export function rootPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /incidents/:incident_id/mutings(.:format) */
export function incidentMutingsPath(incident_id: ScalarType, options?: object): string {
  return "/" + "incidents" + "/" + incident_id + "/" + "mutings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["incident_id","format"]);
}

/** /incidents/:incident_id/mutings/new(.:format) */
export function newIncidentMutingPath(incident_id: ScalarType, options?: object): string {
  return "/" + "incidents" + "/" + incident_id + "/" + "mutings" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["incident_id","format"]);
}

/** /incidents/:id/assign(.:format) */
export function assignIncidentPath(id: ScalarType, options?: object): string {
  return "/" + "incidents" + "/" + id + "/" + "assign" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /incidents/:id/close(.:format) */
export function closeIncidentPath(id: ScalarType, options?: object): string {
  return "/" + "incidents" + "/" + id + "/" + "close" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /incidents/user_checks(.:format) */
export function userChecksIncidentsPath(options?: object): string {
  return "/" + "incidents" + "/" + "user_checks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /incidents/create_by_check(.:format) */
export function createByCheckIncidentsPath(options?: object): string {
  return "/" + "incidents" + "/" + "create_by_check" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /incidents/manager/:manager_id(.:format) */
export function managerIncidentsPath(manager_id: ScalarType, options?: object): string {
  return "/" + "incidents" + "/" + "manager" + "/" + manager_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["manager_id","format"]);
}

/** /incidents(.:format) */
export function incidentsPath(options?: object): string {
  return "/" + "incidents" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /incidents/new(.:format) */
export function newIncidentPath(options?: object): string {
  return "/" + "incidents" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /incidents/:id/edit(.:format) */
export function editIncidentPath(id: ScalarType, options?: object): string {
  return "/" + "incidents" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /incidents/:id(.:format) */
export function incidentPath(id: ScalarType, options?: object): string {
  return "/" + "incidents" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /termine/calendar/:token(.:format) */
export function termineCalendarPath(token: ScalarType, options?: object): string {
  return "/" + "termine" + "/" + "calendar" + "/" + token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["token","format"]);
}

/** /leads/:id/close(.:format) */
export function closeLeadPath(id: ScalarType, options?: object): string {
  return "/" + "leads" + "/" + id + "/" + "close" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /leads/:id/reopen(.:format) */
export function reopenLeadPath(id: ScalarType, options?: object): string {
  return "/" + "leads" + "/" + id + "/" + "reopen" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /leads/:id/create_person(.:format) */
export function createPersonLeadPath(id: ScalarType, options?: object): string {
  return "/" + "leads" + "/" + id + "/" + "create_person" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /leads/:id/quick_action/:quick_action(.:format) */
export function quickActionLeadPath(id: ScalarType, quick_action: ScalarType, options?: object): string {
  return "/" + "leads" + "/" + id + "/" + "quick_action" + "/" + quick_action + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","quick_action","format"]);
}

/** /leads/create_organisation_with_location(.:format) */
export function createOrganisationWithLocationLeadsPath(options?: object): string {
  return "/" + "leads" + "/" + "create_organisation_with_location" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /leads(.:format) */
export function leadsPath(options?: object): string {
  return "/" + "leads" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /leads/:id(.:format) */
export function leadPath(id: ScalarType, options?: object): string {
  return "/" + "leads" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /vcard_export(.:format) */
export function vcardExportPath(options?: object): string {
  return "/" + "vcard_export" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /_bsc(.:format) */
export function BscPath(options?: object): string {
  return "/" + "_bsc" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /_crm/fritzbox_calllist(.:format) */
export function CrmFritzboxCalllistPath(options?: object): string {
  return "/" + "_crm" + "/" + "fritzbox_calllist" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/jobs */
export function missionControlJobsPath(options?: object): string {
  return "/" + "rails" + "/" + "jobs" + $buildOptions(options, []);
}

/** /rails/jobs/applications/:application_id/queues/:queue_id/pause(.:format) */
export function missionControlJobsApplicationQueuePausePath(application_id: ScalarType, queue_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + "queues" + "/" + queue_id + "/" + "pause" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","queue_id","format"]);
}

/** /rails/jobs/applications/:application_id/queues(.:format) */
export function missionControlJobsApplicationQueuesPath(application_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + "queues" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","format"]);
}

/** /rails/jobs/applications/:application_id/queues/:id(.:format) */
export function missionControlJobsApplicationQueuePath(application_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + "queues" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","id","format"]);
}

/** /rails/jobs/applications/:application_id/jobs/:job_id/retry(.:format) */
export function missionControlJobsApplicationJobRetryPath(application_id: ScalarType, job_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + "jobs" + "/" + job_id + "/" + "retry" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","job_id","format"]);
}

/** /rails/jobs/applications/:application_id/jobs/:job_id/discard(.:format) */
export function missionControlJobsApplicationJobDiscardPath(application_id: ScalarType, job_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + "jobs" + "/" + job_id + "/" + "discard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","job_id","format"]);
}

/** /rails/jobs/applications/:application_id/jobs/:job_id/dispatch(.:format) */
export function missionControlJobsApplicationJobDispatchPath(application_id: ScalarType, job_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + "jobs" + "/" + job_id + "/" + "dispatch" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","job_id","format"]);
}

/** /rails/jobs/applications/:application_id/jobs/bulk_retries(.:format) */
export function missionControlJobsApplicationBulkRetriesPath(application_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + "jobs" + "/" + "bulk_retries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","format"]);
}

/** /rails/jobs/applications/:application_id/jobs/bulk_discards(.:format) */
export function missionControlJobsApplicationBulkDiscardsPath(application_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + "jobs" + "/" + "bulk_discards" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","format"]);
}

/** /rails/jobs/applications/:application_id/jobs/:id(.:format) */
export function missionControlJobsApplicationJobPath(application_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + "jobs" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","id","format"]);
}

/** /rails/jobs/applications/:application_id/:status/jobs(.:format) */
export function missionControlJobsApplicationJobsPath(application_id: ScalarType, status: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + status + "/" + "jobs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","status","format"]);
}

/** /rails/jobs/applications/:application_id/workers(.:format) */
export function missionControlJobsApplicationWorkersPath(application_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + "workers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","format"]);
}

/** /rails/jobs/applications/:application_id/workers/:id(.:format) */
export function missionControlJobsApplicationWorkerPath(application_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + "workers" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","id","format"]);
}

/** /rails/jobs/applications/:application_id/recurring_tasks(.:format) */
export function missionControlJobsApplicationRecurringTasksPath(application_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + "recurring_tasks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","format"]);
}

/** /rails/jobs/applications/:application_id/recurring_tasks/:id(.:format) */
export function missionControlJobsApplicationRecurringTaskPath(application_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "applications" + "/" + application_id + "/" + "recurring_tasks" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["application_id","id","format"]);
}

/** /rails/jobs/queues(.:format) */
export function missionControlJobsQueuesPath(options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "queues" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/jobs/queues/:id(.:format) */
export function missionControlJobsQueuePath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "queues" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/jobs/jobs/:id(.:format) */
export function missionControlJobsJobPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + "jobs" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/jobs/:status/jobs(.:format) */
export function missionControlJobsJobsPath(status: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + status + "/" + "jobs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["status","format"]);
}

/** /rails/jobs/ */
export function missionControlJobsRootPath(options?: object): string {
  return "/" + "rails" + "/" + "jobs" + "/" + $buildOptions(options, []);
}

/** / */
export function pludoniAadLoginPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** //auth/pludoni(.:format) */
export function pludoniAadLoginAuthPludoniPath(options?: object): string {
  return "/" + "/" + "auth" + "/" + "pludoni" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** //auth/pludoni/callback(.:format) */
export function pludoniAadLoginAuthPludoniCallbackPath(options?: object): string {
  return "/" + "/" + "auth" + "/" + "pludoni" + "/" + "callback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** //auth/developer/callback(.:format) */
export function pludoniAadLoginAuthDeveloperCallbackPath(options?: object): string {
  return "/" + "/" + "auth" + "/" + "developer" + "/" + "callback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /service_contract_types(.:format) */
export function serviceContractTypesPath(options?: object): string {
  return "/" + "service_contract_types" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /service_contract_types/new(.:format) */
export function newServiceContractTypePath(options?: object): string {
  return "/" + "service_contract_types" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /service_contract_types/:id/edit(.:format) */
export function editServiceContractTypePath(id: ScalarType, options?: object): string {
  return "/" + "service_contract_types" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /service_contract_types/:id(.:format) */
export function serviceContractTypePath(id: ScalarType, options?: object): string {
  return "/" + "service_contract_types" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /recede_historical_location(.:format) */
export function turboRecedeHistoricalLocationPath(options?: object): string {
  return "/" + "recede_historical_location" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /resume_historical_location(.:format) */
export function turboResumeHistoricalLocationPath(options?: object): string {
  return "/" + "resume_historical_location" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /refresh_historical_location(.:format) */
export function turboRefreshHistoricalLocationPath(options?: object): string {
  return "/" + "refresh_historical_location" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/active_storage/disk/:encoded_token(.:format) */
export function updateRailsDiskServicePath(encoded_token: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_token","format"]);
}
