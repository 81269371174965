$(document).ready(function() {
  $("#person_Presseverteiler").click(function() {
    var $this = $(this);

    if ($this.is(":checked")) {
      $("#presse").show();
    } else {
      $("#presse").hide();
    }

    return;
  });

  return;
});