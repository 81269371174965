<template lang="pug">
.panel.panel-primary
  .panel-heading
    .d-flex
      h3.panel-title.mr-4: a(@click.prevent="show = !show" href="#")
        | Verlängerbare Mitglieder

      ul.nav.nav-pills.line-height-1.flex-grow-1
        li.nav-item(:class="{ active: tab === 1 }")
          a.nav-link(@click="tab = 1")
            | Verlängerbar (1-Monat)
            |
            span.badge.badge-dark
              | {{ nowRenewable.length }}
        li.nav-item(:class="{ active: tab === 2 }")
          a.nav-link(@click="tab = 2")
            | Alle
            |

            span.badge.badge-dark
              | {{ renewable.length }}

      div
        a.btn.btn-outline-dark.btn-sm(@click="showAll = true" v-if="isAdmin && !showAll")
          | Alle CMs
  div(v-if="isLoading" style="height: 400px")
    .text-center: i.fa.fa-spinner.fa-spin.fa-3x

  b-table(:items="showItems", :fields="fields" v-if="showItems.length > 0 && show" hover striped small table-class="mb-0")
    template(v-slot:cell(organisation.name)="{ item }")
      a(:href="'/organisationen/' + item.organisation.id")
        | {{ item.organisation.name }}

    template(v-slot:cell(price.netto)="{ item }")
      | {{ item.price.nettoFormatted }}
      div: small
        | {{ item.employeeLevel ? item.employeeLevel.level : "" }}

    template(v-slot:cell(actions)="{ item }")
      .btn-group.text-right
        a.btn.btn-sm.btn-outline-dark.js-modal(:href="'/angebote/' + item.id + '/edit'" v-if="item.canEdit")
          i.fa.fa-pencil-square-o.fa-fw
        a.btn.btn-sm.btn-outline-dark.js-modal(:href="'/angebote/' + item.id + '/cancellation/new'" v-if="item.canCancel")
          i.fa.fa-ban.fa-fw
        a.btn.btn-sm.btn-outline-primary.js-modal(:href="'/angebote/' + item.id + '/renewal/new'" v-if="item.canRenew")
          i.fa.fa-refresh.fa-fw
  div(v-if='renewableAddons.length > 0')
    small
      |{{ renewableAddons.length }} Verlängerbare Addons
    b-table(:items="renewableAddons", :fields="fields" hover striped small table-class="mb-0")
      template(v-slot:cell(organisation.name)="{ item }")
        a(:href="'/organisationen/' + item.organisation.id")
          | {{ item.organisation.name }}
        br
        | {{ item.title }}
      template(v-slot:cell(price.netto)="{ item }")
        | {{ item.price.nettoFormatted }}
      template(v-slot:cell(actions)="{ item }")
        .btn-group.text-right
          a.btn.btn-sm.btn-outline-dark.js-modal(:href="'/addons/bookings/' + item.id + '/edit'")
            i.fa.fa-pencil-square-o.fa-fw
          a.btn.btn-sm.btn-outline-primary.js-modal(:href="'/addons/bookings/' + item.id + '/renewal/new'")
            i.fa.fa-refresh.fa-fw

</template>

<script lang="ts" setup>
import { ref, computed, onMounted, watch } from "vue"

import gql from "utils/graphql"
import type { RenewableContractsQuery } from "@/types/graphql/requests"
import { BTable } from "bootstrap-vue"
import type { BvTableFieldArray } from "bootstrap-vue"

type Renewable = RenewableContractsQuery["renewable"][0]
type RenewableAddon = RenewableContractsQuery["renewableAddons"][0]

const tab = ref(1)
const isLoading = ref(true)
const show = ref(true)
const showAll = ref(false)
const renewable = ref<Renewable[]>([])
const renewableAddons = ref<RenewableAddon[]>([])

import moment from "@/utils/moment"

const fields: BvTableFieldArray = [
  {
    key: "organisation.name",
    label: "Organisation",
    sortable: true,
  },
  {
    key: "organisation.communityManager.ldapLogin",
    label: "Community-Manager",
    sortable: true,
  },
  {
    key: "nextServicePeriodBeginn",
    label: "Nächster Beginn",
    sortable: true,
    formatter: (value: string) => {
      return moment(value).format("L")
    },
  },
  {
    key: "renewableSince",
    label: "Verlängerbar seit",
    sortable: true,
    formatter: (value: string) => {
      return moment(value).format("L")
    },
  },
  {
    key: "price.netto",
    label: "Preis",
    sortable: true,
  },
  {
    key: "actions",
    label: "Aktionen",
  },
]

const isAdmin = ref(window.CurrentCm.rechnungsadmin)
const nowRenewable = computed<Renewable[]>(() => {
  return renewable.value.filter(
    (renewable: Renewable) =>
      renewable.nextServicePeriodBeginn &&
      new Date(renewable.nextServicePeriodBeginn).getTime() -
      30 * 3600 * 24 * 1000 <
      new Date().getTime()
  )
})
const showItems = computed<Renewable[]>(() => {
  return tab.value === 1 ? nowRenewable.value : renewable.value
})

watch(isAdmin, fetch)
watch(showAll, fetch)

async function fetch() {
  isLoading.value = true
  renewable.value = []
  const response = await gql.RenewableContracts({
    showMy: !showAll,
  })
  renewable.value = response.renewable
  renewableAddons.value = response.renewableAddons
  isLoading.value = false
}
onMounted(() => {
  fetch()
})
</script>
<style scoped>
.nav-item {
  cursor: pointer;
}
</style>
