import Vue from "vue"

import Highcharts from "highcharts"
import VueHighcharts from "vue-highcharts"

import highchartsMore from 'highcharts/highcharts-more';
// import solidGauge from 'highcharts/modules/solid-gauge';

highchartsMore(Highcharts);
// solidGauge(Highcharts);
Vue.use(VueHighcharts, { Highcharts })

const font =
  '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

Highcharts.theme = {
  colors: [
    "#6794a7",
    "#014d64",
    "#76c0c1",
    "#01a2d9",
    "#7ad2f6",
    "#00887d",
    "#adadad",
    "#7bd3f6",
    "#7c260b",
    "#ee8f71",
    "#76c0c1",
    "#a18376",
  ],
  chart: {
    // "backgroundColor": "#d5e4eb",
    style: {
      fontFamily: font,
      color: "#3C3C3C",
    },
  },
  title: {
    align: "left",
    style: {
      fontWeight: "bold",
    },
  },
  subtitle: {
    align: "left",
  },
  yAxis: {
    gridLineColor: "#FFFFFF",
    lineColor: "#FFFFFF",
    minorGridLineColor: "#FFFFFF",
    tickColor: "#D7D7D8",
    tickWidth: 1,
    title: {
      style: {
        color: "#A0A0A3",
      },
    },
  },
  tooltip: {
    backgroundColor: "#FFFFFF",
    borderColor: "#76c0c1",
    headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
    style: {
      fontSize: "16px",
      color: "#000000",
    },
  },
  legend: {
    itemStyle: {
      color: "#3C3C3C",
    },
    itemHiddenStyle: {
      color: "#606063",
    },
  },
  credits: {
    enabled: false,
    style: {
      color: "#666",
    },
  },
  labels: {
    style: {
      color: "#D7D7D8",
    },
  },
  drilldown: {
    activeAxisLabelStyle: {
      color: "#F0F0F3",
    },
    activeDataLabelStyle: {
      color: "#F0F0F3",
    },
  },
  navigation: {
    buttonOptions: {
      symbolStroke: "#DDDDDD",
      theme: {
        fill: "#505053",
      },
    },
  },
  legendBackgroundColor: "rgba(0, 0, 0, 0.5)",
  background2: "#505053",
  dataLabelsColor: "#B0B0B3",
  textColor: "#C0C0C0",
  contrastTextColor: "#F0F0F3",
  maskColor: "rgba(255,255,255,0.3)",
}
Highcharts.setOptions(Highcharts.theme)

Highcharts.setOptions({
  labels: {
    style: {
      fontFamily: font,
    },
  },
  tooltip: {
    style: {
      fontFamily: font,
    },
  },
})

if (window.Locale == "de") {
  Highcharts.setOptions({
    lang: {
      loading: "Lade...",
      months: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
      shortMonths: [
        "Jan",
        "Feb",
        "Mär",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez",
      ],
      weekdays: [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
      ],
      decimalPoint: ",",
      numericSymbols: ["k", "M", "G", "T", "P", "E"],
      thousandsSep: ".",
      resetZoom: "Zoom zurücksetzen",
      resetZoomTitle: "Zoom zurücksetzen 1:1",
      printChart: "Drucken",
      downloadPNG: "PNG herunterladen",
      downloadJPEG: "JPEG herunterladen",
      downloadPDF: "PDF herunterladen",
      downloadSVG: "SVG herunterladen",
      contextButtonTitle: "Chart Optionen",
    },
  })
}

Highcharts.dateFormats = {
  W(timestamp) {
    const date = new Date(timestamp)
    const day = date.getUTCDay() === 0 ? 7 : date.getUTCDay()
    let dayNumber
    date.setDate(date.getUTCDate() + 4 - day)
    dayNumber = Math.floor(
      (date.getTime() - new Date(date.getUTCFullYear(), 0, 1, -6)) / 86400000
    )
    return 1 + Math.floor(dayNumber / 7)
  },
}

Highcharts.setOptions({
  lang: {
    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez",
    ],

    months: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "October",
      "November",
      "Dezember",
    ],

    weekdays: [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
    ],

    thousandsSep: ".",
    downloadJPEG: "Download JPEG",
    downloadPDF: "Download PDF",
    downloadPNG: "Download PNG",
    downloadSVG: "Download SVG ",
    loading: "wird geladen...",
    decimalPoint: ",",
  },
})

$(() => {
  $(".select-all").click(function (ev) {
    ev.preventDefault()
    $(this).closest("div").find("input[type=checkbox]").prop("checked", true)
  })

  $(".select-none").click(function (ev) {
    ev.preventDefault()
    $(this).closest("div").find("input[type=checkbox]").prop("checked", false)
  })

  $(".js-umsatz-chart").each(function () {
    const chart = $(this)

    Highcharts.chart(this, {
      title: {
        text: chart.data("title"),
      },
      series: chart.data("chart"),
      chart: {
        zoomType: "x",
        type: "spline",
      },
      legend: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false,
            lineWidth: 1,

            marker: {
              enabled: false,
            },
            shadow: false,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            threshold: null,
          },
        },
      },
      yAxis: {
        title: {
          text: "Umsatz",
        },
        labels: {
          formatter () {
            return `${Highcharts.numberFormat(this.value)  } €`
          },
        },
        min: 0,
      },
      tooltip: {
        shared: true,
        valueSuffix: " €",
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mai",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dez",
        ],
        min: 0,
        title: {
          text: "Monate",
        },
      },
    })
  })

  $(".js-time-chart").each(function () {
    const chart = $(this)

    Highcharts.chart(this, {
      title: {
        text: chart.data("title"),
      },
      series: chart.data("chart"),
      chart: {
        zoomType: "x",
        type: "spline",
      },
      legend: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false,
            lineWidth: 1,

            marker: {
              enabled: false,
            },
            shadow: false,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            threshold: null,
          },
        },
      },
      tooltip: {
        shared: true,
      },
      yAxis: {
        title: {
          text: chart.data("dimension") || "Mitglieder",
        },
      },
      xAxis: {
        type: "datetime",

        dateTimeLabelFormats: {
          month: "%b %Y",
          year: "%b",
        },
      },
    })
  })

  $(".js-bar-chart").each(function () {
    const chart = $(this)

    Highcharts.chart(this, {
      title: {
        text: chart.data("title"),
      },
      series: chart.data("chart"),
      chart: {
        type: "bar",
      },
      legend: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false,
            lineWidth: 1,

            marker: {
              enabled: false,
            },

            shadow: false,

            states: {
              hover: {
                lineWidth: 1,
              },
            },

            threshold: null,
          },
        },
      },
      yAxis: {
        title: {
          text: "Anzahl",
          min: 0,
        },
      },
    })
  })

  $(".js-pie-chart").each(function () {
    const chart = $(this)

    Highcharts.chart(this, {
      title: {
        text: chart.data("title"),
      },
      series: chart.data("chart"),
      chart: {
        type: "pie",
      },
      legend: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
    })
  })

  $(".js-custom-chart").each(function () {
    const chart = $(this)
    Highcharts.chart(this, chart.data("chart"))
  })
})

export default Highcharts
