<template lang="pug">
b-modal(v-model="proxyValue" size="lg" scrollable title="Firma anlegen" hide-footer)
  b-form(@submit="onSubmit" @reset="onReset")
    template(v-if="organisation.organisation_addresses.length > 0")
      b Welche Adressen sollen übernommen werden?
      .list-group.mt-3
        a.list-group-item.list-group-item-action.d-flex.justify-content-between(href="#" v-for="address in organisation.organisation_addresses" :key="address.id" @click='toggleAddress(address.id)' :class="{'bg-light': form.selected_addresses.includes(address.id)}")
          | {{ address.full }}
          template(v-if="form.selected_addresses.includes(address.id)")
            i.mdi.mdi-check-circle(style="font-size: 1.2rem;")
          template(v-else)
            i.mdi.mdi-checkbox-blank-circle-outline(style="font-size: 1.2rem;")
    template(v-if="organisation.people.length > 0")
      hr
      b Welche Ansprechpartner sollen übernommen werden?
      .list-group.mt-3
        a.list-group-item.list-group-item-action.d-flex.justify-content-between(href="#" v-for="person in organisation.people" :key="person.id" @click='togglePerson(person.id)' :class="{'bg-light': form.selected_people.includes(person.id)}")
          | {{ person.full_name }}
          |
          | {{ person.email }}
          |
          | {{ person.telephone }}
          template(v-if="form.selected_people.includes(person.id)")
            i.mdi.mdi-check-circle(style="font-size: 1.2rem;")
          template(v-else)
            i.mdi.mdi-checkbox-blank-circle-outline(style="font-size: 1.2rem;")
    hr
    b Allgemeine Daten
    b-form-group(label="Firmenname:")
      b-form-input(v-model="form.name" required)
    b-form-group(label="Webseite:")
      b-form-input(v-model="form.url")
    b-form-group(label='Cluster:')
      b-form-checkbox-group(v-model='form.selected_cluster' text-field="Kurzbezeichnung" value-field="ID" :options='cluster' stacked)
    b-form-group(label='Organisationstyp:')
      b-form-radio-group(v-model='form.selected_type' text-field="name" value-field="id" :options='organisationstypen' stacked)
    button.mt-3.btn.btn-primary.btn-lg.float-right(type='submit')
      i.mdi.mdi-zip-disk.mr-1
      |Anlegen

</template>

<script lang='ts'>
import {
  BModal,
  BCollapse,
  BFormGroup,
  BForm,
  BFormInput,
  BFormCheckboxGroup,
  BFormRadioGroup
} from 'bootstrap-vue'
import Vue, { PropType } from 'vue'
import { get } from './organisationstypen'

import debounce from 'lodash.debounce'

export default Vue.extend({
  components: {
    BModal,
    BCollapse,
    BFormGroup,
    BForm,
    BFormInput,
    BFormCheckboxGroup,
    BFormRadioGroup
  },
  props: {
    value: { type: Boolean, required: true },
    cluster: { type: Array, required: true },
    organisation: { type: Object as PropType<weaver.Organisation>, required: true },
  },
  data() {
    return {
      showModal: false,
      communities: [],
      organisationstyp: 1,
      organisationstypen: [] as any[],
      form: {
        weaver_organisation_id: null as number | null,
        name: null as string | null,
        url: null as string | null,
        selected_cluster: [] as number[],
        selected_type: 10,
        selected_addresses: [] as number[],
        selected_people: [] as number[]
      }
    }
  },
  computed: {
    proxyValue: {
      get(): boolean {
        return this.value
      },
      set(val: boolean) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    toggleAddress(addressId: number) {
      if (this.form.selected_addresses.includes(addressId)) {
        this.form.selected_addresses = this.form.selected_addresses.filter(address => address != addressId)
      } else {
        this.form.selected_addresses.push(addressId)
      }
    },
    togglePerson(personId: number) {
      if (this.form.selected_people.includes(personId)) {
        this.form.selected_people = this.form.selected_people.filter(person => person != personId)
      } else {
        this.form.selected_people.push(personId)
      }
    },
    onSubmit(event: Event) {
      event.preventDefault()
      this.$http.post('/weaver/organisations', { organisation: this.form }).then(r => {
        this.$emit("created")
      })
    },
    onReset() {
    },
  },
  created() {
    this.form.name = this.organisation.name
    this.form.url = this.organisation.url
    this.form.weaver_organisation_id = this.organisation.id
    if (this.organisation.cluster_ids.length > 0) {
      this.form.selected_cluster = this.organisation.cluster_ids
    }
    if (this.organisation.organisation_addresses.length > 0) {
      this.form.selected_addresses = this.organisation.organisation_addresses.map(oa => oa.id)
    }
    if (this.organisation.people.length > 0) {
      this.form.selected_people = this.organisation.people.map(oa => oa.id)
    }
    get().then(r => {
      if (r) this.organisationstypen = r
    })
  }
})
</script>

<style scoped></style>
