<template lang='pug'>
div
  activestorage-upload(@error='onUploadError' @uploaded='addUpload')

  .list-group-item(v-for='upload in uploads')
    .d-flex.justify-content-between.align-items-center
      span(v-if='upload.url')
        a(:href='upload.url' target="_blank")
          span
            | {{ upload.filename }}
      span(v-else)
        | {{ upload.filename }}
      small.text-muted
        | {{ upload.byte_size | sizeToMb }}
      button.btn.btn-xs.btn-outline-danger(type='button' @click='removeUpload(upload)' title='Löschen')
        i.fa.fa-trash

</template>

<script lang='ts'>
import ActivestorageUpload from "common/ActivestorageUpload.vue"
import Vue, { PropType } from 'vue'

export default Vue.extend({
  components: { ActivestorageUpload },
  props: {
    value: {
      type: Array as PropType<ActiveStorage.Blob[]>,
      required: true,
    }
  },
  computed: {
    uploads: {
      get(): ActiveStorage.Blob[] {
        return this.value
      },
      set(uploads: ActiveStorage.Blob[]) {
        this.$emit('input', uploads)
      }
    }
  },
  methods: {
    removeUpload(upload: ActiveStorage.Blob) {
      this.uploads = this.uploads.filter(
        (u) => u.signed_id !== upload.signed_id
      )
    },
    addUpload(payload: { file: File; blob: ActiveStorage.Blob }) {
      this.uploads = [...this.uploads, payload.blob]
    },
    onUploadError(error: string) {
      this.$bvToast.toast(error, {
        title: "Fehler beim Upload",
        variant: "danger",
        appendToast: true,
        autoHideDelay: 10000,
      })
    },
  }

})
</script>
<style scoped>
</style>
