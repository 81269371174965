<template lang='pug'>
  th
    a(@click="update()" :title="headline.title")
      i.fa.fa-fw.fa-sort-asc.text-primary(v-if="active && !isReverse")
      i.fa.fa-fw.fa-sort-desc.text-primary(v-if="active && isReverse")
      i.fa.fa-fw.fa-sort.text-muted(v-if="!active")
      slot
</template>

<script>
export default {
  props: {
    sortState: { type: Object, required: true },
    headline: { type: Object, required: true }
  },
  computed: {
    active() { return this.sortState.current === this.sortKey },
    sortKey() { return this.headline.sortKey },
    isReverse() {
      return this.sortState.reverse
    }
  },
  methods: {
    update() {
      this.$emit('update', { sortKey: this.sortKey, defaultReverse: this.headline.reverse, emptyValue: this.headline.emptyValue })
    }
  },
}
</script>

<style lang='scss' scoped>
a {
  white-space: nowrap;
  cursor: pointer;
  color: inherit;
}
</style>
