<template lang="pug">
div
  .panel.panel-primary
    .panel-heading
      h3.panel-title
        a(data-toggle="collapse" href="#leads")
          | Posteingang / Leads
      div
        ul.nav.nav-pills.line-height-1
          li.nav-item()
            a.nav-link(href="#" @click.prevent="recipientFilter = 'all'" :class="{ active: recipientFilter === 'all' }")
              | Alle Quellen
          li.nav-item(v-for="recipient in recipients")
            a.nav-link(href="#" @click.prevent="recipientFilter = recipient", :class="{ active: recipientFilter == recipient }")
              | {{ recipient.replace('xing-', '').replace(/@.*/, '') }}
              span.badge.badge-dark
                | {{ leads.filter((e) => e.recipient === recipient).length }}
        ul.nav.nav-pills.line-height-1
          li.nav-item(:class="{ active: !assigneeFilter }")
            a.nav-link(href="#" @click.prevent="assigneeFilter = null")
              | Alle CMs
          li.nav-item(v-for='item in communityManagerCounts' :class="{ active: item.communityManager && item.communityManager.id === assigneeFilter }")
            a.nav-link(href='#' @click.prevent='assigneeFilter = item.communityManager.id')
              span(v-if='item.communityManager')
                | {{ item.communityManager.ldap_login }}
              span(v-else)
                | ??
              span.badge.badge-dark
                | {{ item.count }}


    #leads.collapse.show: .list-group
      lead-row(
        v-for="message in filteredIncidents",
        :message="message",
        :key="message.id"
        @update="reload",
        :communityManagers="communityManagers"
      )
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import LeadRow from "./LeadRow.vue"

type Counts = {
  communityManager?: CommunityManager
  count: number
}

export default Vue.extend({
  components: {
    LeadRow,
  },
  props: {
    communityManagers: {
      type: Array as PropType<CommunityManager[]>,
      required: true,
    },
    communityManagerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      recipientFilter: "all",
      assigneeFilter: this.communityManagerId,
      leads: [],
    } as {
      assigneeFilter?: number,
      leads: Lead[]
      recipientFilter: string
    }
  },
  computed: {
    communityManagerCounts(): Counts[] {
      const counts: { [key: string]: number } = {}
      this.leads.forEach((l) => {
        if (l.assigned_to_id) {
          counts[l.assigned_to_id] ??= 0
          counts[l.assigned_to_id] += 1
        }
      })
      return Object.entries(counts).map(([id, count]) => {
        return {
          communityManager: this.communityManagers.find(
            (e) => e.id.toString() === id
          ),
          count,
        }
      })
    },
    recipients(): string[] {
      const all = this.leads.map((e) => e.recipient)
      const unique = new Set(all)
      return [...unique]
    },
    filteredIncidents(): Lead[] {
      let leads = this.leads
      if (this.recipientFilter !== "all") {
        leads = leads.filter((e) => e.recipient === this.recipientFilter)
      }
      if (this.assigneeFilter) {
        leads = leads.filter((e) => e.assigned_to_id === this.assigneeFilter)
      }
      return leads;
    },
  },
  mounted() {
    this.reload()
  },
  methods: {
    reload() {
      this.$http.get("/leads.json").then((r) => {
        this.leads = r.data as Lead[]
      })
    },
  },
})
</script>

<style scoped>
.nav-pills { min-height: 1.2rem }
.nav li a {
  padding: 2px 5px;
}
.panel-heading {
  display: flex;
}
.panel-title {
  margin-right: 15px;
}
.nav .nav-divider {
  width: 2px;
  height: 100%;
  margin: 0 3px;
}
</style>
