<template lang="pug">
div
  b-table(
    :fields="fields"
    striped
    hover,
    :items="value"
    responsive
    primary-key="id",
    :tbody-transition-props="{ name: 'flip-list' }"
    show-empty,
    :sortBy.sync="sortBy"
    tbody-tr-class="flip-list-item"
  )
    template(v-slot:cell(organisation.name)=" { item }")
      a(:href='"/organisationen/" + item.organisation.id')
        | {{ item.organisation.name }}

    template(v-slot:cell(sum.netto)=" { item }")
      span.text-nowrap
        | {{ item.sum.nettoFormatted }}
      br
      small.text-muted.text-nowrap
        | {{ item.sum.bruttoFormatted }}

    template(v-slot:cell(billingDate)=" { item }")
      span.text-nowrap
        | {{ item.billingDate | moment("L") }}

    template(v-slot:cell(vorgaenge)=" { item }")
      .label.label-default.d-block.text-wrap(v-for='v in item.vorgaenge')
        a(:href='v.url')
          | {{ v.type.name }}
          | ({{ v.date | moment("L") }})

    template(v-slot:cell(items[0].position)=" { item }")
      span
        | {{ item.items[0].position }}
      q(v-if='item.comment')
        | {{ item.comment }}

    template(v-slot:cell(paymentReceivedOn)=" { item }")
      span.text-nowrap(v-if='item.paymentReceivedOn')
        | {{ item.paymentReceivedOn | moment("L") }}

      span(v-else-if='item.paymentDueTo && !markedAsPaid.includes(item.id)')
        span.label.label-warning Unbezahlt
        br
        small Zahlungsziel:  {{ item.paymentDueTo | moment("L") }}

      div(v-if='item.ratenzahlung')
        span.badge.badge-success(:title='item.ratenzahlungComment' v-b-tooltip)
          |RATENZAHLUNG
        div: progress(:value='item.ratenzahlungReceived || 0' :max='item.sum.netto')

    template(v-slot:cell(actions)=" { item }")
      .btn-group
        a.btn.btn-sm.btn-outline-primary(
          v-if='rechnungsadmin && !item.paymentReceivedOn && !markedAsPaid.includes(item.id) && item.canMarkAsPaid'
          title='Als Bezahlt markieren (öffnet Modal)'
          @click.prevent='markAsPaid(item)'
          href='#'
          v-b-tooltip
        )
          i.fa.fa-check.fa-fw

        a.btn.btn-sm.btn-outline-danger.js-modal(
          v-if='item.canDemand'
          :href='"/rechnungen/" + item.id + "/demand"'
          title='Mahnen'
          v-b-tooltip
        )
          i.fa.fa-euro.fa-fw
        a.btn.btn-sm.btn-outline-dark.js-modal(
          v-if='item.canGutschrift'
          :href='item.gutschriftPath'
          title='Gutschrift'
          v-b-tooltip
        )
          i.fa.fa-fast-backward.fa-fw
      .btn-group
        pdf-viewer-with-button(:url='"/rechnungen/" + item.id + ".pdf"' class='btn-dark')
        a.btn.btn-sm.btn-outline-dark.js-modal(
          v-if='item.canEdit'
          :href='"/rechnungen/" + item.id + "/edit"'
          title='Bearbeiten'
          v-b-tooltip
        )
          i.fa.fa-pencil-square-o.fa-fw
        a.btn.btn-sm.btn-outline-danger(
          v-if='item.canDestroy'
          :href='"/rechnungen/" + item.id'
          title='Löschen'
          data-confirm='Sind Sie sicher?'
          data-method='delete'
          v-b-tooltip
        )
          i.fa.fa-trash-o.fa-fw

  b-modal(v-model='markAsPaidOpen' :hide-footer='true')
    .form-group
      label.control-label(for='paidAt')
        | Zahlungseingang
      input.form-control(type='date' v-model='paidAt' :max="new Date().toISOString().split('T')[0]" id='paidAt')

    button.btn.btn-primary(@click='markAsPaidSubmit')
      |Als Bezahlt markieren



</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import type { BvTableFieldArray, BvTableField } from "bootstrap-vue"
import { BTable, BModal } from "bootstrap-vue"
import * as Routes from "generated/routes"
import PdfViewerWithButton from "common/PDFViewerWithButton.vue"

import { UnpaidRechnungenQuery } from "types/graphql/requests"

type Rechnungen = UnpaidRechnungenQuery["rechnungen"]
type Rechnung = Rechnungen[0]

export default Vue.extend({
  components: {
    PdfViewerWithButton,
    BTable,
    BModal,
  },
  props: {
    value: { type: Array as PropType<Rechnung[]> },
    showCompany: { type: Boolean, default: true },
  },
  data() {
    return {
      currentMarkAsPaid: null as Rechnung| null,
      markAsPaidOpen: false,
      paidAt: new Date().toISOString().split("T")[0],
      markedAsPaid: [] as string[],
      sortBy: "Rechnungsdatum",
    }
  },
  methods: {
    markAsPaid(rechnung: Rechnung) {
      this.currentMarkAsPaid = rechnung
      this.markAsPaidOpen = true
    },
    markAsPaidSubmit() {
      if (!this.currentMarkAsPaid) return
      this.markAsPaidOpen = false
      const rechnung = this.currentMarkAsPaid
      this.$http.patch(Routes.markAsPaidRechnungPath(rechnung.id), { paid_at: this.paidAt }).then(() => {
        this.markedAsPaid.push(rechnung.id)
      })
    },
  },
  computed: {
    rechnungsadmin(): boolean {
      return window.CurrentCm.rechnungsadmin
    },
    fields(): BvTableFieldArray {
      const list: ((BvTableField & { key: string }) | null)[] = [
        this.showCompany
          ? {
              key: "organisation.name",
              label: "Organisation",
              sortable: true,
            }
          : null,
        {
          key: "id",
          label: "Nummer",
          sortable: true,
        },
        {
          key: "billingDate",
          label: "Datum",
          sortable: true,
        },
        {
          key: "sum.netto",
          label: "Betrag",
          sortable: true,
        },
        {
          key: "items[0].position",
          label: "Positionstext",
          sortable: false,
        },
        {
          key: "paymentReceivedOn",
          label: "Zahlungseingang",
          sortable: true,
        },
        {
          key: "vorgaenge",
          label: "Vorgänge",
          sortable: false,
        },
        {
          key: "actions",
          label: "Aktionen",
          sortable: false,
        },
      ]
      return list.filter((field): field is BvTableField & { key: string } =>
        Boolean(field)
      )
    },
  },
})
</script>

<style scoped>
* >>> .flip-list-move {
  transition: transform 0.3s ease-out;
}
* >>> .flip-list-item {
  transition: all 0.4s ease-out;
}
* >>> .flip-list-enter,
* >>> .flip-list-leave-to {
  opacity: 0;
}
* >>> .flip-list-enter {
  transform: translateX(150px);
}
* >>> .flip-list-leave-active {
  position: absolute;
}
* >>> .checkbox-group {
  display: flex;
}
* >>> .checkbox-group > .form-check {
  margin-right: 10px;
}
.themen-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.label-default {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
