<template lang="pug">
div.text-center
  .d-flex.align-items-center(style='gap: 5px;')
    span(
      v-if="relation == 'POSITIV'"
      title="Positive Kundenbeziehung"
    )
      i(class="fa fa-fw fa-thumbs-o-up")
    span(
      v-if="relation == 'NEUTRAL'"
      title="Neutrale Kundenbeziehung"
    )
      i(class="fa fa-fw fa-balance-scale")
    span(
      v-if="relation == 'NEGATIV'"
      title="Problemkunde"
    )
      i(class="fa fa-fw fa-exclamation-circle text-danger")

  table.table.table-borderless(v-if='kziGesamt.value')
    tr
      td
        |CM:
      td
        value-with-formatting(v-if='kziCm' title='Zufriedenheit mit CM laut KZI' :value='kziCm' v-b-tooltip)
    tr
      td
        |Gesamt:
      td: value-with-formatting(:value='kziGesamt' title="Gesamtzufriedenheit" v-b-tooltip)

    tr(v-if='kziReview.value')
      td
        |Review:
      td: value-with-formatting(:value='kziReview' title="Reviewzufriedenheit" v-b-tooltip)

    tr(v-if='weq.value' title="Weiterempfehlungsquote" v-b-tooltip)
      td
        |WEQ:
      td: value-with-formatting(:value='weq')

    tr(v-if='repurchint.value' title="Verlängerungsabsicht" v-b-tooltip)
      td
        |Repurchint:
      td: value-with-formatting(:value='repurchint')

    tr(v-if='kziBefragung.value')
      td(colspan='2'): value-with-formatting(:value='kziBefragung' title="Datum der Befragung" v-b-tooltip)

  MeasureChip(
    v-for="measure in measures"
    :key="measure.id"
    :measure="measure"
  )
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { PartnerTableQuery } from "types/graphql/requests"
import ValueWithFormatting from "dashboard/components/ValueWithFormatting.vue"
import MeasureChip from "dashboard/components/partner_table/MeasureChip.vue"

type PartnerTableRow = PartnerTableQuery["organisations"][0]

export default Vue.extend({
  components: {
    ValueWithFormatting,
    MeasureChip,
  },
  props: {
    item: { type: Object as PropType<PartnerTableRow>, required: true },
  },
  computed: {
    relation(): string | undefined {
      return this.item.customerRelation || undefined
    },
    measures() {
      return this.item.recentMeasures
    },
    kziCm(): any {
      return this.item.cmReview?.kziCm
    },
    kziGesamt(): any {
      return this.item.cmReview.kziGesamtzufriedenheit
    },
    kziBefragung(): any {
      return this.item.cmReview.kziBefragung
    },
    kziReview(): any {
      return this.item.cmReview.kziReview
    },
    kziReview(): any {
      return this.item.cmReview.kziReview
    },
    weq(): any {
      return this.item.cmReview.weq
    },
    repurchint() {
      return this.item.cmReview.repurchint
    },
  }
})
</script>
<style scoped>
.table td {
  padding: 0 0.1rem;
  vertical-align: baseline;
  line-height: 1.1;
}
</style>
