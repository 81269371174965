<template>
  <form>
    <div class="form-group">
      <textarea
        v-model="reason"
        class="form-control"
        required
        placeholder="Grund für Nichtbearbeitung"
      ></textarea>
    </div>
    <DatePicker
      v-model="muted_until"
      label="Incident automatisch muten bis"
      :initial-value="muted_until"
      :max="180"
      min="today"
      :only-date="true"
    />
    <button
      class="btn btn-primary"
      :disabled="!isValid"
      @click.prevent.stop="submitMuting"
    >
      Incident muten
    </button>
  </form>
</template>

<script>
import DatePicker from "dashboard/components/DatePicker.vue"
import addDays from 'utils/add_days'

export default {
  components: { DatePicker},
  data() {
    return {
      muted_until: addDays(new Date(), 14),
      reason: null,
    }
  },
  computed: {
    isValid() {
      if (this.reason === null || this.reason.trim() === "" || this.muted_until === null) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    submitMuting() {
      this.$emit('submit', { muted_until: this.muted_until, reason: this.reason })
    }
  },
}
</script>
