<template>
  <tr>
    <td>
      <a :href="'/organisationen/' + organisation.ID" target="_blank">
        {{ organisation.Bezeichnung }} <br />
        <small class="text-muted"> {{ organisation.Nutzername }} </small>
      </a>
    </td>
    <td>
      <span v-if="organisation.letztes_akquisegefuehl == 'hoch'" title="Hohe Wahrscheinlichkeit/Gute Chancen">
        <i class=" fa-thumbs-o-up fa fa-fw text-success"></i> 50%
      </span>
      <span v-if="organisation.letztes_akquisegefuehl == 'mittel'" title="Mittlere Wahrscheinlichkeit/Chancen">
        <i class="fa-fw fa fa-balance-scale" aria-hidden="true"></i> 25%
      </span>
      <span v-if="organisation.letztes_akquisegefuehl == 'niedrig'" title="Unwahrscheinlich">
        <i class="fa-fw fa fa-thumbs-o-down text-warning" aria-hidden="true"></i> 10%
      </span>
      <span v-if="organisation.letztes_akquisegefuehl == 'keine_chance'" title="Keine Chance">
        <i class="fa-fw fa fa-ban text-danger" aria-hidden="true"></i> 0%
      </span>
      <span v-if="organisation.letztes_akquisegefuehl == 'kontaktaufnahme'" title="Kontaktaufnahme">
        0%
      </span>
    </td>
    <td class="text-right">
      {{ organisation.umsatzvolumen }}
    </td>
    <td>
      <span v-if="organisation.cm">
        <img :src="organisation.cm.image" :alt="organisation.cm.ldap_login" class="cm-image img-thumbnail">
      </span>
    </td>
    <td v-for="ak in akquiseVorgaenge" :key="ak.key">
      <div v-if="organisation.vorgaenge[ak.key]" class="text-center">
        {{ organisation.vorgaenge[ak.key].date | moment("L") }}
        <span v-if="organisation.vorgaenge[ak.key].cm">
          <img :src="organisation.vorgaenge[ak.key].cm.image" :alt="organisation.vorgaenge[ak.key].cm.ldap_login" class="img-thumbnail" style="width: 30px; height: 30px">
        </span>
      </div>
    </td>
    <td>
      <div class="quick-actions">
        <a
          class="btn btn-sm btn-primary js-modal"
          title="Maßnahme/Incident anlegen"
          :href="'/incidents/new?organisation_id=' + organisation.ID"
        >
          <i class="fa fa-thumb-tack fa-fw"></i>
        </a>
        <a
          class="btn btn-sm btn-primary js-modal"
          title="Vorgang anlegen"
          :href="'/vorgaenge/new?Organisation=' + organisation.ID"
        >
          <i class="fa fa-plus fa-fw"></i>
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import ValueWithFormatting from "../ValueWithFormatting.vue"
import akquiseVorgaenge from "./akquise_vorgaenge.js"

export default {
  components: { ValueWithFormatting },
  props: { organisation: { type: Object, required: true } },
  computed: {
    akquiseVorgaenge() {
      return akquiseVorgaenge
    },
    reviewInfo() {
      return this.organisation.review_info
    },
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.quick-actions {
  opacity: 0.5;
  transition: opacity 0.2s ease-in;
  transform: scale(0.7);
  &:hover {
    opacity: 1;
  }
}
.cm-image {
  width: 30px;
  height: 30px;
  opacity: 0.8;
  float: right;
}
</style>
