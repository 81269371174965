<template lang="pug">
div
  b-form-file(
    v-show="!uploadInProgress"
    :accept="accept"
    @input="uploadFile"
    :placeholder="placeholder"
    :dropPlaceholder="dropPlaceholder"
    browse-text='Auswählen'
    :disabled="disabled"
  )
  .list-group(v-if="uploads.length > 0")
    .list-group-item(v-for="file in uploads")
      slot(name="progress" v-bind:file="file" v-bind:progress="progress")
        b-spinner(small)
        i.mdi.mdi-loading.fa-spin
        i Wird hochgeladen: {{file.file.filename }}
        br
        b-progress(:value="progress" :max="100" :animated="true" style="max-width: 200px; height: 2px")
      br
      br
</template>

<script lang="ts">
import { BFormFile, BProgress, BSpinner } from "bootstrap-vue"
import { DirectUpload } from "@rails/activestorage"
import Vue from "vue"

export default Vue.extend({
  components: {
    BFormFile,
    BProgress,
    BSpinner,
  },
  props: {
    maxSize: { type: Number, required: false, default: null },
    accept: { type: String, required: false, default: () => "application/pdf" },
    uploadUrl: {
      type: String,
      default: () => "/rails/active_storage/direct_uploads",
    },
    placeholder: { type: String, default: () => "Dokument hochladen" },
    dropPlaceholder: {
      type: String,
      default: () => "Dokument hier fallenlassen um hochzuladen",
    },
    disabled: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      progress: 0,
      uploads: [] as { file: File; upload: any }[],
    }
  },
  computed: {
    uploadInProgress(): boolean {
      return this.uploads.length > 0
    },
  },
  methods: {
    directUploadWillStoreFileWithXHR(xhr: XMLHttpRequest) {
      xhr.upload.addEventListener("progress", (event) => this.onProgress(event))
    },
    onProgress(event: ProgressEvent<XMLHttpRequestEventTarget>) {
      this.progress = (event.loaded / event.total) * 100
    },
    uploadFile(file: File) {
      if (this.maxSize && file.size > this.maxSize * 1024 * 1024) {
        this.$emit(
          "error",
          `Die Datei darf maximal ${this.maxSize} MB groß sein`
        )
        return
      }
      const upload = new DirectUpload(file, this.uploadUrl, this)
      this.uploads.push({ file, upload })
      this.progress = 0
      upload.create((error, blob) => {
        this.uploads = this.uploads.filter(
          (payload) => payload.file.name !== file.name
        )
        if (error) {
          this.$emit("error", error)
        } else {
          this.$emit("uploaded", { file, blob })
        }
      })
    },
  },
})
</script>
