<template lang="pug">
div
  reminder-panel(:vorgaenge='reminder')
  lazyload: customer-panel(:services='services' :communityManagers='communityManagers')
  lazyload: recently-imported-mails
  lazyload: leads-panel(:community-managers='communityManagers' :community-manager-id='communityManagerId')
  lazyload: renewable-panel
  lazyload: rechnungen-panel
  lazyload: incident-panel
  .row
    .col-md-6
      lazyload: umsatz-chart
    .col-md-6
      lazyload: review-chart
  .row
    .col-md-6
      lazyload: umsatzbilanz-chart
    .col-md-6
      lazyload: handlungskompetenz-panel
</template>

<script lang="ts">
import Lazyload from "dashboard/Lazyload.vue"
import IncidentPanel from "dashboard/components/IncidentPanel.vue"
import CustomerPanel from "dashboard/components/CustomerPanel.vue"
import RecentlyImportedMails from "dashboard/components/RecentlyImportedMails.vue"
import LeadsPanel from "dashboard/components/leads_table/LeadsPanel.vue"
import HandlungskompetenzPanel from "dashboard/components/HandlungskompetenzPanel.vue"
import ReminderPanel from "dashboard/components/ReminderPanel.vue"
import RechnungenPanel from "dashboard/components/RechnungenPanel.vue"
import RenewablePanel from "dashboard/components/RenewablePanel.vue"
import UmsatzChart from "dashboard/components/UmsatzChart.vue"
import ReviewChart from "dashboard/components/ReviewChart.vue"
import UmsatzbilanzChart from "dashboard/components/UmsatzbilanzChart.vue"

import Vue from "vue"

export default Vue.extend({
  components: {
    UmsatzbilanzChart,
    ReviewChart,
    CustomerPanel,
    UmsatzChart,
    IncidentPanel,
    LeadsPanel,
    HandlungskompetenzPanel,
    RecentlyImportedMails,
    Lazyload,
    ReminderPanel,
    RechnungenPanel,
    RenewablePanel,
  },
  props: {
    reminder: {
      type: Array,
      required: true,
    },
    communityManagers: {
      type: Array,
      required: true,
    },
    communityManagerTeamIds: {
      type: Array,
      required: true,
    },
    communityManagerId: {
      type: Number,
      required: true,
    },
    services: { type: Object, required: true },
  },
  provide() {
    return {
      $communityManagerId: this.communityManagerId,
      $communityManagerTeamIds: this.communityManagerTeamIds,
    }
  },
})
</script>
