<template lang='pug'>
.card.m-0.p-1
  .d-flex.justify-content-between.gap-1.align-items-start
    div
      .vorgang-title
        | {{ vorgang.organisation.name }}

    div.flex-column.d-flex.align-items-end
      small.text-muted
        | {{ date }}
  div.text-muted
    | {{ vorgang.type.name }}
    span.ml-2(v-if='vorgang.comment' v-b-tooltip='vorgang.comment')
      i.fa.fa-comment-o.fa-fw

  .d-flex.justify-content-between
    div.d-flex.align-items-center.gap-1
      .d-flex.flex-column
        akquisegefuehl(v-if='vorgang.akquisegefuehl' :akquisegefuehl='vorgang.akquisegefuehl')
        small.text-muted(v-if=' vorgang.organisation.potentiellesUmsatzvolumen ')
          | {{ vorgang.organisation.potentiellesUmsatzvolumen }}€
      span.badge.badge-dark(v-if='vorgang.reminder' title='Erinnerung gesetzt')
        i.fa-clock.fa-fw.fa
        |
        | {{ vorgang.reminder | moment("L") }}
    div.d-flex.align-items-center.gap-1
      community-manager-chip(:communityManager='communityManager' v-if='communityManager')
      .chip(v-else)
        | {{ vorgang.communityManagerName }}
      person-chip-gql(:person='vorgang.personen[0]' v-if='vorgang.personen[0]')
  .vorgang-hovering-buttons: .btn-group
    a.js-modal.btn.btn-sm.btn-primary(
      :href="vorgangUrl"
      title="Nachfolgevorgang anlegen und Erinnerung löschen, falls vorhanden"
      v-b-tooltip
    )
      i.fa.fa-plus.fa-fw

    a.btn.btn-sm.btn-dark(:href="crmUrl" title='Akte' v-b-tooltip target='_blank')
      |CRM
    a.btn.btn-sm.btn-default.js-modal(v-if="vorgang.canEdit", :href="'/vorgaenge/' + vorgang.id + '/edit'" title="Bearbeiten" v-b-tooltip)
      i.fa.fa-pencil-square-o.fa-fw

    template(v-if="vorgang.canRemoveReminder")
      a.btn.btn-sm.btn-default(@click="deleteReminder" title='Termin Erinnerung löschen' v-b-tooltip)
        i.fa.fa-calendar-times-o.fa-fw
    template(v-else)
      a.btn.btn-outline-dark(:href="vorgangUrl")
        |Neuer Vorgang

</template>

<script lang='ts'>
import Vue, { PropType } from 'vue'
import { InteressentenTableQuery } from "types/graphql/requests"
import CommunityManagerChip from "@/profile/components/CommunityManagerChip.vue"
import PersonChipGql from "@/profile/components/PersonChipGql.vue"
import Akquisegefuehl from "@/dashboard/components/Akquisegefuehl.vue"

export default Vue.extend({
  components: {
    CommunityManagerChip,
    PersonChipGql,
    Akquisegefuehl,
  },
  props: {
    vorgang: { type: Object as PropType<InteressentenTableQuery["interessenten"]["erstkontakt"][0]> }
  },
  computed: {
    crmUrl(): string {
      return `/organisationen/${this.vorgang.organisation.id}`
    },
    vorgangUrl(): string {
      return `/vorgaenge/new?back=%2F&Organisation=${this.vorgang.organisation.id}&vorgaenger=${this.vorgang.id}`
    },
    communityManager(): any {
      return this.vorgang.communityManager
    },
    date(): string {
      const d = this.vorgang.date
      if (d === null) return ""
      return Intl.DateTimeFormat("de-DE", { dateStyle: "short" }).format(new Date(d))
    }
  },
  methods: {
    deleteReminder() {
      this.$http.post(`/vorgaenge/${this.vorgang.id}/remove_reminder`).then(() => {
        this.vorgang.reminder = null
      })
    },
  }
})
</script>
<style scoped>
.vorgang-text {
  font-size: 0.8rem;
  margin-left: 1rem;
  line-height: 1.1;
  font-style: italic;
}

.vorgang-title {
  font-size: 0.9rem;
  line-height: 1.2;
  margin-bottom: 3px
}

.gap-1 {
  gap: 0.5rem;
}

.vorgang-hovering-buttons {
  display: none;
  position: absolute;
  bottom: -3rem;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  z-index: 1;
  left: 0;
  padding: 10px;
  border-radius: 0 0 5px 5px;
  border-bottom: 1px solid #bbb;
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
  margin-left: -1px;
  margin-right: -2px;
}

.card:hover .vorgang-hovering-buttons {
  display: block;
}

.card {
  font-size: 0.8rem;
  transition: all 0.1s ease-in-out;
}
.card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

</style>
