import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
// import select2 from "@/utils/select2"

export default class extends Controller {
  connect() {
    // select2
    $(this.element).select2({ width: 'element' })
  }
}
