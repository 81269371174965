<template lang="pug">
.panel.panel-primary
  .panel-heading
    .d-flex.justify-content-between(style='gap: 10px')
      h2.panel-title Themen
      ul.nav.nav-pills.line-height-1.flex-grow-1(style="gap: 2px")
        li.nav-item(:class="{ active: topicFilter == null }")
          a.nav-link(@click.prevent="topicFilter = null" href='#')
            | Alle
        li.nav-item(:class="{ active: topicFilter == thema }" v-for="(count, thema) in themaCounts")
          a.nav-link(@click.prevent="topicFilter = thema" href='#')
            | {{ thema }}
            span.badge.badge-dark.ml-1
              | {{ count }}

  b-table.table-sm(
    striped
    hover,
    :fields="fields",
    :items="paginatedItems"
    responsive
    primary-key="id",
    :tbody-transition-props="{ name: 'flip-list' }"
    show-empty,
    :sort-desc.sync="sortDesc",
    :sortBy.sync="sortBy"
    tbody-tr-class="flip-list-item"
  )
  button.btn.btn-block.btn-secondary(
    @click="showAll = true",
    v-if="!showAll && items.length > paginatedItems.length"
  )
    | Alle anzeigen ({{ items.length }})
</template>

<script lang="ts">
import Vue from "vue"
import { BTable } from "bootstrap-vue"

export default Vue.extend({
  components: {
    BTable,
  },
  props: {
    topics: { type: Array as PropType<schema.ThemaNote[]>, required: true },
  },
  data() {
    return {
      sortBy: "created_at",
      sortDesc: true,
      showAll: false,
      topicFilter: null,
    }
  },
  computed: {
    fields() {
      return [
        { key: "thema.name", label: "Thema", sortable: true },
        { key: "body", label: "Notiz", sortable: true },
        {
          key: "created_at",
          label: "Erstellt am",
          sortable: true,
          formatter: (value) => {
            return this.$moment(value).format("L")
          },
        },
        {
          key: "vorgang.typ.Bezeichnung",
          label: "Vorgang",
          sortable: true,
        },
        {
          key: "vorgang.cm_name",
          label: "CommunityManager",
          sortable: true,
        },
      ]
    },
    items() {
      const c = this.topics.filter(
        (t) => this.topicFilter == null || this.topicFilter == t.thema.name
      )
      return c
    },
    paginatedItems() {
      return this.showAll ? this.items : this.items.slice(0, 10)
    },
    themaCounts() {
      const themen = {}
      this.topics.forEach((e) => {
        themen[e.thema.name] = (themen[e.thema.name] || 0) + 1
      })
      return themen
    },
  },
})
</script>
<style scoped></style>
