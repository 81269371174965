<template lang="pug">
div
  csv-import(:mailing='mailing' @refresh='importFromCsv')

  .row(v-if='organisationTypeOptions.length > 0')
    .col-sm-4
      strong Organisation

      b-form-group(label='Organisationstypen')
        .btn-group.select-all-box
          button.btn.btn-sm(@click='selectAll("organisation_types", organisationTypeOptions)')
            i.fa.fa-check-square-o
          button.btn.btn-sm(@click='unselectAll("organisation_types")')
            i.fa.fa-square-o
        b-form-checkbox-group(v-model='filter.organisation_types', :options='organisationTypeOptions' stacked)

      b-form-group(label='Mitgliedsstatus')
        b-form-checkbox-group(v-model='filter.membership_status', :options='membershipStatusOptions')

      b-form-group(label='Cluster')
        b-form-checkbox-group(
          v-model='filter.cluster',
          :options='clusterOptions'
        )
      b-form-group(label='Anbindung')
        b-form-checkbox-group(
          v-model='filter.bms',
          :options='bmsOptions'
        )

    .col-sm-4
      strong Standorte
      b-form-group(label='Standorte' description='Kommagetrennt mehrere')
        b-form-input(type='text' v-model='filter.location')
      b-form-group(label='Radius (km)')
        b-form-input(type='number' v-model='filter.radius')
      b-form-group(label='Standorte verwenden von:')
        b-form-radio(v-model='filter.standorte_verwenden' value='person') Person muss am Standort arbeiten
        b-form-radio(v-model='filter.standorte_verwenden' value='organisation') Min. 1 Person der Firma muss am Standort arbeiten
      br
      br
      button.btn.btn-primary.btn-block(@click.prevent='refresh') Laden
    .col-sm-4
      strong Personen
      b-form-group(label='Personentypen')
        .btn-group.select-all-box
          button.btn.btn-sm(@click='selectAll("personentyp", personentypOptions)')
            i.fa.fa-check-square-o
          button.btn.btn-sm(@click='unselectAll("personentyp")')
            i.fa.fa-square-o
        b-form-checkbox-group(v-model='filter.personentyp', :options='personentypOptions')
      b-form-group(label='Kontakterlaubnis')
        b-form-checkbox-group(v-model='filter.contact_status', :options='contactStatusOptions')
      b-form-group(label='Empfehlungsbund-Kontakt')
        b-form-checkbox(v-model='filter.person_empfehlungsbund_contact')
      b-form-group(label='Vorherige Kommunikation')
        b-form-checkbox-group(v-model='filter.communication_response_status' :options='communicationResponseOptions')


  div(v-if='isLoading')
    i.fa.fa-spinner.fa-spin.fa-3x.text-center

  div(v-if='isSaving')
    i.fa.fa-spinner.fa-spin.fa-3x.text-center
    |wird gespeichert...

  recipient-table(v-model='selected' v-if='emails.length > 0' :emails='emails')

  div(v-if='isSaving')
    i.fa.fa-spinner.fa-spin.fa-3x.text-center

  div.alert.alert-info(v-else-if='selected.length > 0')
    | Anzahl ausgewählter Kontakte aus vorheriger Auswahl: {{ selected.length }}
    br
    small Zum Löschen einfach erneut Kontakte auswählen.

</template>

<script lang="ts">
import {
  BFormCheckbox,
  BFormGroup,
  BTable,
  BPagination,
  BFormCheckboxGroup,
  BFormRadio,
  BFormInput,
} from "bootstrap-vue"

import {
  computed,
  defineComponent,
  PropType,
  onMounted,
} from "vue"


import {
  organisationTypeOptions,
  clusterOptions,
  personentypOptions,
  loadOptions,
  contactStatusOptions,
  communicationResponseOptions,
  membershipStatusOptions,
  bmsOptions,
} from "./checkbox_options"

import useSelectAll from './useSelectAll'
import useMailingRecipientSave from "./recipientSave"

import RecipientTable from "./RecipientTable.vue"
import CsvImport from "./CsvImport.vue"

export default defineComponent({
  components: {
    BFormInput,
    BPagination,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    BFormGroup,
    RecipientTable,
    BTable,
    CsvImport,
  },
  props: {
    value: { type: Object as PropType<MailingForm["filter"]>, required: true },
    mailing: { type: Object as PropType<MailingForm>, required: true },
  },
  setup(props, ctx) {
    onMounted(loadOptions)

    const filter = computed({
      get(): any {
        return props.value
      },
      set(value: any) {
        ctx.emit("input", value)
      },
    })

    const { selected, isLoading, isSaving, refresh, emails, fetchFromServer } = useMailingRecipientSave(filter, props.mailing.id, ctx.emit)
    const { selectAll, unselectAll } = useSelectAll(filter)

    return {
      emails,
      filter,
      organisationTypeOptions,
      fetchFromServer,
      clusterOptions,
      isLoading,
      isSaving,
      selected,
      refresh,
      bmsOptions,
      personentypOptions,
      contactStatusOptions,
      communicationResponseOptions,
      membershipStatusOptions,
      selectAll,
      unselectAll,
      importFromCsv(recipients: schema.MailingRecipient[]) {
        fetchFromServer()
        emails.value = [...emails.value, ...recipients]
      },
    }
  },
})
</script>

<style scoped>
.select-all-box {
  float: right;
  position: relative;
  bottom: 2rem;
}
</style>
