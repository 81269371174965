<template lang="pug">
div
  .d-flex.justify-content-between
    small Vorschau:

    button.btn.btn-sm(type="button", @click="fetchPreview" title='Andere Vorschau laden')
      i.fa.fa-refresh
  table.table-sm.table
    tr
      th Betreff:
      td
        | {{ previewSubject }}
    tr
      th An:
      td(v-if='previewTo')
        | {{ previewTo.join(', ')}}
  iframe(:srcdoc='previewBody' src='/404' v-if='previewBody')

</template>

<script lang="ts">
import Vue, { PropType } from "vue"

export default Vue.extend({
  components: {},
  props: {
    value: { type: Object as PropType<MailingForm>, required: true },
  },
  data() {
    return {
      previewBody: null as String | null,
      previewSubject: null as String | null,
      previewTo: null as String | null,
    }
  },
  computed: {
    mailing: {
      get(): MailingForm {
        return this.value
      },
      set(value: MailingForm) {
        this.$emit("input", value)
      },
    },
  },
  methods: {
    async fetchPreview() {
      const response = await this.$http.get(
        `/mailings/${this.mailing.id}/preview`
      )
      this.previewBody = response.data.html
      this.previewSubject = response.data.subject
      this.previewTo = response.data.to
    },
  },
  mounted() {
    this.fetchPreview()
  },
})
</script>

<style scoped>
iframe {
  border: 0;
  width: 100%;
  height: 600px;
}
</style>
