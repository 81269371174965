<template>
  <tr>
    <td>
      <a :href="'/organisationen/' + organisation.ID" target="_blank">
        {{ organisation.Bezeichnung }} <br />
        <small class="text-muted"> {{ organisation.Nutzername }} </small>
      </a>
    </td>
    <td>
      <span v-if="organisation.kundenbeziehung == 'ernst'">
        <i class="fa fa-fw fa-black-tie"></i> Ernst
      </span>
      <span v-if="organisation.kundenbeziehung == 'locker'">
        <i class="fa fa-fw fa-handshake-o"></i> Locker
      </span>
      <span v-if="organisation.kundenbeziehung == 'problemkunde'" title="Problemkunde">
        <i class="fa fa-fw fa-exclamation-circle text-danger"></i> Probl.
      </span>
    </td>
    <td style="text-align: right; padding-right: 10px;">
      {{ reviewInfo["Kundenaufwand"].value }}
    </td>
    <td><ValueWithFormatting :value="reviewInfo['Backlink']" /></td>
    <td><ValueWithFormatting :value="reviewInfo['Mystery Applicant']" /></td>
    <td><ValueWithFormatting :value="reviewInfo['BMS']" /></td>
    <td>
      <div class="quick-actions">
        <a
          class="btn btn-sm btn-primary js-modal"
          title="Maßnahme/Incident anlegen"
          :href="'/incidents/new?organisation_id=' + organisation.ID"
        >
          <i class="fa fa-thumb-tack fa-fw"></i>
        </a>
        <a
          class="btn btn-sm btn-primary js-modal"
          title="Vorgang anlegen"
          :href="'/vorgaenge/new?Organisation=' + organisation.ID"
        >
          <i class="fa fa-plus fa-fw"></i>
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import ValueWithFormatting from "../ValueWithFormatting.vue"

export default {
  components: { ValueWithFormatting },
  props: { organisation: { type: Object, required: true } },
  computed: {
    reviewInfo() {
      return this.organisation.review_info
    },
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.quick-actions {
  opacity: 0.5;
  transition: opacity 0.2s ease-in;
  transform: scale(0.7);
  &:hover {
    opacity: 1;
  }
}
</style>


