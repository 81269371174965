<template lang="pug">
.panel.panel-primary
  .panel-heading: .d-flex
    h3.panel-title.mr-3 Umsatzbilanz
    ul.nav.nav-pills.line-height-1.flex-grow-1
      li.nav-item(:class="{active: tab == 1}")
        a.nav-link(@click='tab = 1')
          |2 Jahre
      li.nav-item(:class="{active: tab == 2}")
        a.nav-link(@click='tab = 2')
          |BSC Jahr

  highcharts(v-if='chartData' :options="chart")
  div(v-if='isLoading' style='height: 400px')
    .text-center: i.fa.fa-spinner.fa-spin.fa-3x
</template>

<script lang="ts">
import Vue from "vue"
import Highcharts from "modules/highcharts"
import type { Options } from "highcharts"

type items = {
  organisation: string
  preis: number
}

// app/services/statistics/umsatzbilanz2.rb
type MonthData = {
  cum: number
  sum: number
  downgrades: items[]
  kuendigungen: items[]
  mehrumsatz: items[]
  neuabschluesse: items[]
  upgrades: items[]
}
type ChartData = {
  [key: string]: MonthData
}

export default Vue.extend({
  data() {
    return {
      chartData: null as ChartData | null,
      isLoading: false,
      tab: 1,
    }
  },
  computed: {
    yearLines(): Highcharts.XAxisPlotLinesOptions[] {
      if (!this.chartData) return []

      const months = Object.keys(this.chartData)
      const years = months.map((e) => e.split("-")[0])
      const lines = Array.from(new Set(years)).map((y) => `${y}-01`)

      return lines.map((year) => {
        const index = months.findIndex((e) => e == year)
        return {
          color: "#ccc",
          dashStyle: "Dash",
          width: 1,
          value: index - 0.5,
        }
      })
    },
    chart(): Options | null {
      if (!this.chartData) {
        return null
      }
      const today = new Date().toISOString().split("-").slice(0, 2).join("-")

      return {
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        series: [
          {
            name: "Neuabschlüsse",
            type: "column",
            color: "#00a65a",
            yAxis: 1,
            data: Object.entries(this.chartData).map(([key, item], i) => {
              return {
                x: i,
                y: item.neuabschluesse
                  .map((e) => e.preis)
                  .reduce((agg, el) => agg + el, 0),
              }
            }),
          },
          {
            name: "Rückkehrer",
            type: "column",
            color: "#00c0ef",
            yAxis: 1,
            data: Object.entries(this.chartData).map(([key, item], i) => {
              return {
                x: i,
                y: item.upgrades
                  .map((e) => e.preis)
                  .reduce((agg, el) => agg + el, 0),
              }
            }),
          },
          {
            name: "Mehrumsatz durch höhere MS-Rechnung/Wachstum",
            type: "column",
            color: "#605ca8",
            yAxis: 1,
            data: Object.entries(this.chartData).map(([key, item], i) => {
              return {
                x: i,
                y: item.mehrumsatz
                  .map((e) => e.preis)
                  .reduce((agg, el) => agg + el, 0),
              }
            }),
          },
          {
            name: "Kündigungen",
            type: "column",
            color: "#dd4b39",
            yAxis: 1,
            data: Object.entries(this.chartData).map(([key, item], i) => {
              return {
                x: i,
                y:
                  -1 *
                  item.kuendigungen
                    .map((e) => e.preis)
                    .reduce((agg, el) => agg + el, 0),
              }
            }),
          },
          {
            name: "Förderer Downgrades",
            type: "column",
            color: "#f56954",
            yAxis: 1,
            data: Object.entries(this.chartData).map(([key, item], i) => {
              return {
                x: i,
                y:
                  -1 *
                  item.downgrades
                    .map((e) => e.preis)
                    .reduce((agg, el) => agg + el, 0),
              }
            }),
          },
          {
            name: "Kumulative Summe",
            type: "spline",
            data: Object.entries(this.chartData).map(([key, item], i) => {
              return {
                x: i,
                y: item.cum,
              }
            }),
          },
        ],
        title: {
          enabled: false,
          text: undefined,
        },
        legend: {
          enabled: true,
        },
        credits: {
          enabled: false,
        },
        yAxis: [
          {
            title: {
              text: "Umsatzdifferenz kumulativ",
            },
            labels: {
              formatter() {
                return `${Highcharts.numberFormat(+this.value, 0, ",", ".")} €`
              },
            },
          },
          {
            opposite: true,
            title: {
              text: "Umsatz δ",
            },
            labels: {
              formatter() {
                return `${Highcharts.numberFormat(+this.value, 0, ",", ".")} €`
              },
            },
          },
        ],
        tooltip: {
          shared: true,
          valueSuffix: " €",
        },
        xAxis: {
          plotLines: [
            ...this.yearLines,
            {
              color: "#FF0000",
              width: 1,
              value: Object.keys(this.chartData).findIndex((e) => e == today),
              label: {
                text: "Heute",
                style: {
                  color: "#FF0000",
                },
              },
            },
          ],
          categories: Object.keys(this.chartData),
        },
      } as Options
    },
  },
  watch: {
    tab() {
      this.fetch()
    },
  },
  methods: {
    fetch() {
      this.isLoading = true
      const year = new Date().getFullYear()
      const date = this.tab == 1 ? `${year - 2}-01-01` : `${year - 1}-12-01`
      this.$http
        .get(`/statistics/umsatzbilanz.json?date=${date}`)
        .then((response) => {
          this.isLoading = false
          this.chartData = response.data
        })
    },
  },
  mounted() {
    this.fetch()
  },
})
</script>
<style scoped></style>
