<template lang="pug">
div
  table.table.table-striped(v-if="handlungskompetenz")
    thead
      tr
        th Handlungskompetenz
        th Benchmark
        th Eigene
        th Relative
    tbody
      tr(v-for="a in attributes", :key="a.attribute")
        th
          | {{ a.label }}
        td
          | {{ a.format.format(averages[a.attribute]) }}
        td(
          :class="{ 'text-success': averages[a.attribute] <= handlungskompetenz[a.attribute] }"
        )
          | {{ a.format.format(handlungskompetenz[a.attribute]) }}
        td
          | {{ Math.round((handlungskompetenz[a.attribute] / averages[a.attribute]) * 100) / 100 }}
    tfoot
      tr
        th
          | Gesamt Handlungskompetenz
        th
          | {{ format(averages.total) }}
        th
        th
          | {{ format(handlungskompetenz.total) }}
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      averages: {},
      handlungskompetenz: null,
      attributes: [
        {
          label: "Anzahl Partner",
          attribute: "partner_count",
          format: new Intl.NumberFormat("de-DE", { maximumFractionDigits: 1 }),
        },
        {
          label: "Förderer (Firmen)",
          attribute: "foerderer_companies_count",
          format: new Intl.NumberFormat("de-DE", { maximumFractionDigits: 1 }),
        },
        {
          label: "Förderer (Andere)",
          attribute: "foerderer_other_count",
          format: new Intl.NumberFormat("de-DE", { maximumFractionDigits: 1 }),
        },
        {
          label: "Karma Durchschnitt (zahlende Partner)",
          attribute: "karma_average",
          format: new Intl.NumberFormat("de-DE", { maximumFractionDigits: 1 }),
        },
        {
          label: "Smartclick bestanden (zahlende Partner, 0.5cl/job [12m])",
          attribute: "smartclick_passed_quote",
          format: new Intl.NumberFormat("de-DE", {
            maximumFractionDigits: 1,
            style: "percent",
          }),
        },
        {
          label: "Backlink Partner Quote",
          attribute: "backlink_partner_quote",
          format: new Intl.NumberFormat("de-DE", {
            maximumFractionDigits: 1,
            style: "percent",
          }),
        },
        {
          label: "Backlink Förderer (Firmen) Quote",
          attribute: "backlink_foerderer_companies_quote",
          format: new Intl.NumberFormat("de-DE", {
            maximumFractionDigits: 1,
            style: "percent",
          }),
        },
        {
          label: "Backlink Förderer (Firmen) Andere",
          attribute: "backlink_foerderer_other_quote",
          format: new Intl.NumberFormat("de-DE", {
            maximumFractionDigits: 1,
            style: "percent",
          }),
        },
        {
          label: "Anzahl Partner mit Reviews (1j)",
          attribute: "review_count",
          format: new Intl.NumberFormat("de-DE", { maximumFractionDigits: 1 }),
        },
        {
          label: "Akquisevorgänge (Eigene, 7 Tage)",
          attribute: "akquise_count_7d",
          format: new Intl.NumberFormat("de-DE", { maximumFractionDigits: 1 }),
        },
        {
          label: "Akquisevorgänge (1j)",
          attribute: "akquise_count",
          format: new Intl.NumberFormat("de-DE", { maximumFractionDigits: 1 }),
        },
        {
          label: "Akquisevorgänge Vorort (1j)",
          attribute: "akquise_vorort_count",
          format: new Intl.NumberFormat("de-DE", { maximumFractionDigits: 1 }),
        },
        {
          label: "Akquisevorgänge Telefonat (1j)",
          attribute: "akquise_telefonat_count",
          format: new Intl.NumberFormat("de-DE", { maximumFractionDigits: 1 }),
        },
        {
          label: "Verlängerungsquote",
          attribute: "renewal_quote",
          format: new Intl.NumberFormat("de-DE", {
            maximumFractionDigits: 1,
            style: "percent",
          }),
        },
        {
          label: "KZI Teilnahme Quote (Z. Partner)",
          attribute: "kzi_quote",
          format: new Intl.NumberFormat("de-DE", {
            maximumFractionDigits: 1,
            style: "percent",
          }),
        },
        {
          label: "KZI Zufr. CM+Review (1-5)",
          attribute: "kzi_cm_average",
          format: new Intl.NumberFormat("de-DE", { maximumFractionDigits: 1 }),
        },
        {
          label: "KZI Zufr. Gesamt (1-5)",
          attribute: "kzi_cm_gesamt",
          format: new Intl.NumberFormat("de-DE", { maximumFractionDigits: 1 }),
        },
      ],
    }
  },
  computed: {},
  methods: {
    format(v) {
      return new Intl.NumberFormat("de-DE", { maximumFractionDigits: 2 }).format(v)
    }
  },
  mounted() {
    this.$http.get("/dashboard/ajax/handlungskompetenz").then(({ data }) => {
      this.handlungskompetenz = data.handlungskompetenz
      this.averages = data.averages
    })
  },
}
</script>

<style scoped>
table {
  max-width: 700px;
  border: 1px solid #555;
}
td.text-success {
  font-weight: bold;
}
tfoot,
thead {
  background: #555;
  color: white;
}
</style>
