<template lang="pug">
.panel.panel-primary
  .panel-heading: .d-flex
    h3.panel-title.mr-4: a(@click.prevent='show = !show' href='#')
      |Gitlab-Issues (EBMS)
    ul.nav.nav-pills.line-height-1.flex-grow-1
      li.nav-item(:class='{active: tab == 0}')
        a.nav-link(@click='tab = 0')
          | Alle
          |
          span.badge.badge-dark
            | {{counts.open}}
      li.nav-item(:class='{active: tab == 1}')
        a.nav-link(@click='tab = 1')
          | Kontaktanfragen
          |
          span.badge.badge-dark
            | {{counts.eb}}
      li.nav-item(:class='{active: tab == 2}')
        a.nav-link(@click='tab = 2')
          | EBMS
          |
          span.badge.badge-dark
            | {{counts.bms}}

    ul.nav.nav-pills.line-height-1.flex-grow-1
      li.nav-item(:class='{active: visibility == "open"}')
        a.nav-link(@click.prevent='visibility = "open"' href='#')
          | Offen
          span.badge.badge-dark
            | {{counts.open}}
      li.nav-item(:class='{active: visibility == "closed"}')
        a.nav-link(@click.prevent='visibility = "closed"' href='#')
          | Geschlossen
          span.badge.badge-dark
            | {{counts.closed}}


  div(v-if='isLoading')
    pulse-loader.text-center.my-5(:loading="isLoading")

  table.table.table-sm(v-if='show')
    tbody
      tr(v-for='issue in showIssues')
        td
          a(:href="issue.url" target="_blank")
            | {{issue.title}}
        td
          | {{issue.created_at | moment("L")}}
        td: .d-flex.flex-wrap(style='gap: 3px')
          span.badge.badge-dark(v-for='label in issue.labels' v-if='!label.match(/customer:|Project:/)')
            | {{label.replace(/[A-Z]-|Type:/, '')}}

</template>

<script lang="ts">
import Vue from "vue"
import PulseLoader from "vue-spinner/src/PulseLoader.vue"

type Issue = {
  id: number
  title: string
  description: string
  assignee: string | null
  url: string
  created_at: string
  labels: string[]
}

export default Vue.extend({
  components: {
    PulseLoader
  },
  props: {
    name: { type: String, required: true },
  },
  data() {
    return {
      ebIssues: [] as Issue[],
      isLoading: true,
      show: true,
      visibility: 'open',
      bmsIssues: [] as Issue[],
      tab: 0,
    }
  },
  computed: {
    counts() {
      const allIssues = [...this.ebIssues, ...this.bmsIssues]
      return {
        open: allIssues.filter(issue => !issue.isClosed).length,
        closed: allIssues.filter(issue => issue.isClosed).length,
        eb: this.ebIssues.filter(issue => !issue.isClosed).length,
        bms: this.bmsIssues.filter(issue => !issue.isClosed).length,
      }
    },
    showIssues(): Issue[] {
      let i = []
      switch(this.tab) {
        case 0:
          i = [...this.ebIssues, ...this.bmsIssues]
          break
        case 1:
          i = this.ebIssues
          break
        case 2:
          i = this.bmsIssues
          break
      }
      switch(this.visibility) {
        case 'open':
          i = i.filter(issue => !issue.isClosed)
          break
        case 'closed':
          i = i.filter(issue => issue.isClosed)
          break
      }
      return i
    },
  },
  methods: {},
  mounted() {
    this.$http
      .get("/organisation/gitlab_issues.json", {
        params: { username: this.name },
      })
      .then((d) => {
        this.isLoading = false
        this.ebIssues = d.data.issues
        this.bmsIssues = d.data.bms_issues
      })
  },
})
</script>

<style scoped>
.nav-item {
  cursor: pointer;
}
</style>
