import Vue from 'vue'
import * as Routes from "generated/routes"

type CheckboxOption = {
  text: string | null
  value: string
}

import { computed, ref } from "vue"

const organisationTypes = ref<schema.Organisationstyp[]>([])
const organisationTypeOptions = computed((): CheckboxOption[] => {
  return organisationTypes.value.map((type) => {
    return {
      text: type.Bezeichnung,
      value: type.ID.toString(),
    }
  })
})

const cluster = ref<schema.Cluster[]>([])
const clusterOptions = computed((): CheckboxOption[] => {
  return cluster.value.map((type) => {
    return {
      text: type.Kurzbezeichnung,
      value: type.ID.toString(),
    }
  })
})

const personentypen = ref<schema.Personentyp[]>([])
const personentypOptions = computed((): CheckboxOption[] => {
  return personentypen.value.map((type) => {
    return {
      text: type.Bezeichnung,
      value: type.ID.toString(),
    }
  })
})

const loadOptions = async (): Promise<void> => {
  fetch(Routes.mailingsRecipientsOptionsPath())
    .then((response) => response.json())
    .then((data) => {
      organisationTypes.value = data.organisation_types
      cluster.value = data.clusters
      personentypen.value = data.personentypen
    })
}
const contactStatusOptions = [
  { text: "KEINE Kontakterlaubnis", value: "non_contactable" },
  { text: "Wahrscheinlich Kontaktierbar", value: "maybe_contactable" },
  {
    text: "Kontaktierbar (Vertragsbeziehung/Einverständnis)",
    value: "contactable",
  },
]
const communicationResponseOptions = [
  { text: "Unbekannt", value: "unknown" },
  { text: "Grün", value: "green" },
  { text: "Gelb", value: "yellow" },
  { text: "Rot", value: "red" },
  { text: "Bounced", value: "bounced" },
]
const membershipStatusOptions = [
  { text: "Kein Aktuelles Mitglied", value: "none" },
  { text: "Partner", value: "partner" },
  { text: "Reine Förderer", value: "foerderer" },
]

const bmsOptions = [
  { text: "Nutzt EBMS (Full)", value: 'ebms' },
  { text: "Nutzt externes eigenes BMS", value: "other_bms" },
  { text: "Nutzt kein BMS/E-Mail AFAWN", value: "none_email" },
]

export {
  loadOptions,
  bmsOptions,
  organisationTypeOptions,
  clusterOptions,
  personentypOptions,
  contactStatusOptions,
  communicationResponseOptions,
  membershipStatusOptions,
}
