import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import setConditionalToggle from '@/modules/conditional-toggle'

export default class extends Controller {

  connect() {
    $('#vorgang_Standort').change(function() {
      var address = $(this).find('option').filter(':selected').text()
      $('#vorgang_dienstreise_hin_attributes_Nach').val("Kundenadresse: " +address)
      $('#vorgang_dienstreise_zurueck_attributes_Von').val("Kundenadresse: " +address)
    })
    $(() => {
      setConditionalToggle($('input[name*=anreise_erfassen]'), '1', $('.js-anreise'))
      setConditionalToggle($('input[name*=abreise_erfassen]'), '1', $('.js-abreise'))
    })
    $('#vorgang_participating_community_manager_ids').on('select2:select', function (e) {
      var cmId = e.params.data.id
      var cmName = e.params.data.text
      $('[class*=vorgang_dienstreise][class*=community_manager]').each(function() {
        var direction = this.className.match(/hin|zurueck/)[0]
        if ($(this).find('input[value=' + cmId +']').length > 0) {
          return
        }
        var radio = $("<input class='radio_buttons required' type='radio'/>").
          attr('name', "vorgang[dienstreise_" + direction + "_attributes][community_manager_id]").
          val(cmId)
        var label = $("<label/>").append(radio).append(cmName)
        var span = $("<span class='radio'></span>").append(label)
        $(this).find('.radio').parent().append(span)
      })
    });
    $('#vorgang_participating_community_manager_ids').on('select2:unselect', function (e) {
      var cmId = e.params.data.id
      $('[class*=vorgang_dienstreise][class*=community_manager]').each(function() {
        let existing = $(this).find('input[value=' + cmId +']')
        existing.closest('.radio').remove()
      })
    })
    const el = $('input[name*=ReisezeitSelbstGefahren]')
    setConditionalToggle(el, 'true', $('.js-yes'))
    setConditionalToggle(el, 'false', $('.js-no'))
  }
}
