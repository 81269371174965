import tinymce from "tinymce"

type Placeholder = {
  key: string
  description: string
}
tinymce.PluginManager.add("placeholder", function Plugin(editor) {
  // Add a button that opens a window
  const placeholderItems = editor.settings.placeholders as Placeholder[]

  editor.ui.registry.addMenuButton("placeholder", {
    text: "Placeholders",
    type: "menubutton",
    icon: "temporary-placeholder",
    fetch(callback) {
      callback(
        placeholderItems.map((placeholder) => {
          return {
            value: placeholder.key,
            text: `{{${placeholder.key}}} - ${placeholder.description}`,
            type: "menuitem",
            onAction() {
              editor.insertContent(
                `<code class="placeholder mceNonEditable" style="display: inline-block;">{{${placeholder.key}}}</code>`
              )
            },
          }
        })
      )
    },
  })

  return {
    getMetadata() {
      return {
        name: "Placeholder plugin",
        url: "http://bms.empfehlungsbund.de",
      }
    },
  }
})
