import { Ref } from "vue"

export default (
  filter: Ref
): {
  selectAll: (key: string, options: CheckboxOption[]) => void
  unselectAll: (key: string) => void
} => {
  return {
    selectAll(key: string, options: CheckboxOption[]) {
      const all = options.map((option) => option.value)
      filter.value = { ...filter.value, [key]: all }
    },
    unselectAll(key: string) {
      filter.value = { ...filter.value, [key]: [] }
    },
  }
}
