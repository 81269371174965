<template lang="pug">
div
  div(v-for="thema in themen", :key="thema.id")
    b-form-checkbox(v-model="selectedTopics", :value="thema.id")
      | {{ thema.name }}
      br
      small.text-muted
        | {{ thema.description }}
    transition(name="fade")
      div(v-if="selectedTopics.includes(thema.id)")
        textarea.form-control.mb-2(
          v-model="notes[thema.id]"
          placeholder="Notizen zu diesem Thema: Was wurde entschieden? Soll der Service eingeführt werden, falls nein, warum nicht, etc."
        )
        .d-flex.flex-wrap.mb-3(style='gap: 3px')
          button.btn.btn-sm.btn-outline-dark(v-for='suggestion in suggestions' @click.prevent='toggleSuggestion(suggestion, thema)')
            |{{ suggestion }}

  input(:name="formName", :value="hiddenValue" type="hidden")
</template>

<script lang="ts">
import Vue, { PropType } from "vue"

import { BFormCheckbox } from "bootstrap-vue"

export default Vue.extend({
  components: {
    BFormCheckbox,
  },
  props: {
    formName: { type: String, required: true },
    initialValue: {
      type: Array as PropType<schema.ThemaNote[]>,
      required: true,
    },
    themen: { type: Array as PropType<schema.Thema[]>, required: true },
  },
  data() {
    return {
      suggestions: [
        "Lehnt Nutzung ab",
        "Erstmalig Vorgestellt",
        "Pilot/Im Test",
        "nutzen es aktiv",
      ],
      selectedTopics: this.initialValue.map((e) => e.thema_id) as number[],
      notes: Object.fromEntries(
        this.initialValue.map((e) => [e.thema_id, e.body]) as [number, string][]
      ),
    }
  },
  computed: {
    hiddenValue(): string {
      const existing = this.selectedTopics.map((e) => {
        return {
          id: this.initialValue.find((i) => i.thema_id == e)?.id,
          thema_id: e,
          body: this.notes[e],
        }
      })
      const deletion = this.initialValue
        .filter((e) => !this.selectedTopics.includes(e.thema_id))
        .map((e) => ({
          id: e.id,
          _destroy: true,
        }))
      return JSON.stringify([...existing, ...deletion])
    },
  },
  methods: {
    toggleSuggestion(suggestion: string, thema: schema.Thema) {
      if (this.notes[thema.id]) {
        if (this.notes[thema.id].includes(suggestion)) {
          this.notes[thema.id] = this.notes[thema.id].replace(suggestion, "")
          this.notes[thema.id] = this.notes[thema.id].replace(", ,", ",")
          this.notes[thema.id] = this.notes[thema.id].replace(/^,/, "")
          this.notes[thema.id] = this.notes[thema.id].replace(/,$/, "")
        } else {
          this.notes[thema.id] += `, ${suggestion}`
        }
      } else {
        this.notes[thema.id] = suggestion
      }
      // trigger assign for vue2
      this.notes = { ...this.notes }
      console.log(this.notes)
    },
  },
})
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 0.2s;
}
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
