import $ from 'jquery';
import 'tablesorter'

$(function() {
  $('.tablesorter, table.sorted, .table.sortable').tablesorter({
    textExtraction(node: HTMLElement) {
      const el = $(node)
      if(el.data('value')) {
        return el.data('value')
      } else {
        return el.text()
      }
    }
  })
})
