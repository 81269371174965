<template>
  <div class="lazyload-wrapper">
    <transition name="fade">
      <slot v-if="loaded"></slot>
      <div v-else :style="{ minHeight: minHeight + 'px' }">
        noch nicht geladen...
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  props: {
    minHeight: {
      type: Number,
      default: 400,
    },
    root: {
      type: HTMLElement,
      default: () => document.querySelector("main"),
    },
  },
  data() {
    return {
      loaded: false,
    }
  },
  mounted() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            observer.disconnect()
            this.loaded = true
          }
        })
      },
      {
        rootMargin: "0px",
        threshold: 0.0,
        root: null
      }
    )
    observer.observe(this.$el)
  },
  methods: {},
})
</script>

<style scoped>
.lazyload-wrapper {
  height: auto;
  transition: height 0.5s ease-in;
}
</style>
