import axios from "utils/axios"
import { ref, watch, Ref, onMounted } from "vue"
import * as Routes from "generated/routes"
import qs from "qs"
import debounce from "lodash.debounce"

type Filter = {
  [key: string]: string | string[]
}

const useMailingRecipientSave = (
  filter: Ref<Filter>,
  mailingId: number,
  emit: (event: "count", data: number) => void
): {
  selected: Ref
  refresh: () => Promise<void>
  fetchRecipients: (filter: Filter) => Promise<schema.MailingRecipient[]>,
  fetchFromServer: () => void,
  emails: Ref
  isLoading: Ref<boolean>
  isSaving: Ref<boolean>
} => {
  const emails = ref<schema.MailingRecipient[]>([])
  const selected = ref<schema.MailingRecipient[]>([])
  const isLoading = ref(false)
  const isSaving = ref(false)
  let ignore = true

  const saveRecipients = () => {
    if (isSaving.value) return

    isSaving.value = true
    axios.patch(Routes.recipientsMailingPath(mailingId), {
      recipients: selected.value,
    }).then(() => {
      isSaving.value = false

      emit("count", selected.value.length)
    })
  }
  const debouncedSave = debounce(saveRecipients, 200, {
    leading: true,
    trailing: true,
  })
  watch(selected, () => {
    if (!ignore && !isLoading.value && !isSaving.value)
      debouncedSave()
  })
  const fetchFromServer = () => {
    ignore = true
    axios.get(Routes.recipientsMailingPath(mailingId)).then(({ data }) => {
      selected.value = data
      setTimeout(() => {
        ignore = false
      }, 500)
      emit("count", selected.value.length)
    })
  }
  onMounted(() => {
    fetchFromServer()
  })
  const fetchRecipients = async (filter: Filter): Promise<schema.MailingRecipient[]> => {
    const params = qs.stringify(
      { filter: filter },
      { arrayFormat: "brackets" }
    )
    const response = await axios.get(
      `${Routes.searchMailingPath(mailingId)}?${params}`
    )
    return response.data
  }
  return {
    emails,
    selected,
    isSaving,
    fetchRecipients,
    fetchFromServer,
    async refresh() {
      isLoading.value = true
      emails.value = await fetchRecipients(filter.value)
      isLoading.value = false
    },
    isLoading,
  }
}
export default useMailingRecipientSave
