<template lang="pug">
div(v-if='organisation')
  b-form(@submit="onSubmit" @reset="onReset")
    template(v-if="organisation.organisation_addresses.length > 0")
      b Welche Adressen sollen übernommen werden?
      .list-group.mt-3
        a.list-group-item.list-group-item-action.d-flex.justify-content-between(href="#" v-for="address in organisation.organisation_addresses" :key="address.id" @click='toggleAddress(address.id)' :class="{'bg-light': form.selected_addresses.includes(address.id)}")
          | {{ address.full }}
          template(v-if="form.selected_addresses.includes(address.id)")
            i.fa.fa-check-circle(style="font-size: 1.2rem;")
          template(v-else)
            i.fa.fa-circle-o(style="font-size: 1.2rem;")

    template(v-if="organisation.people.length > 0")
      hr
      b Welche Ansprechpartner sollen übernommen werden?
      .list-group.mt-3
        a.list-group-item.list-group-item-action.d-flex.justify-content-between(href="#" v-for="person in organisation.people" :key="person.id" @click='togglePerson(person.id)' :class="{'bg-light': form.selected_people.includes(person.id)}")
          | {{ person.full }}
          |
          | {{ person.email }}
          |
          | {{ person.telephone }}
          template(v-if="form.selected_people.includes(person.id)")
            i.fa.fa-check-circle(style="font-size: 1.2rem;")
          template(v-else)
            i.fa.fa-circle-o(style="font-size: 1.2rem;")
    hr
    b Allgemeine Daten
    b-form-group(label="Firmenname:")
      b-form-input(v-model="form.name" required)
    b-form-group(label="Webseite:")
      b-form-input(v-model="form.url")
    b-form-group(label='Cluster:')
      b-form-checkbox-group(v-model='form.selected_cluster' :options='clusterOptions' stacked)

    button.mt-3.btn.btn-primary.btn-lg.float-right(type='submit')
      i.mdi.mdi-zip-disk.mr-1
      |Anlegen

</template>

<script lang="ts">
import {
  BModal,
  BCollapse,
  BFormGroup,
  BForm,
  BFormInput,
  BFormCheckboxGroup,
  BFormRadioGroup,
} from "bootstrap-vue"
import Vue, { PropType } from "vue"

export default Vue.extend({
  components: {
    BModal,
    BCollapse,
    BFormGroup,
    BForm,
    BFormInput,
    BFormCheckboxGroup,
    BFormRadioGroup,
  },
  props: {
    organisationId: { type: Number, required: true },
  },
  data() {
    return {
      communities: [],
      organisation: null as weaver.Organisation | null,
      organisationstyp: 1,
      organisationstypen: [] as any[],
      cluster: [] as any[],
      form: {
        weaver_organisation_id: null as number | null,
        name: null as string | null,
        url: null as string | null,
        selected_cluster: [] as number[],
        selected_type: 10,
        selected_addresses: [] as number[],
        selected_people: [] as number[],
      },
    }
  },
  methods: {
    toggleAddress(addressId: number) {
      if (this.form.selected_addresses.includes(addressId)) {
        this.form.selected_addresses = this.form.selected_addresses.filter(
          (address) => address != addressId
        )
      } else {
        this.form.selected_addresses.push(addressId)
      }
    },
    togglePerson(personId: number) {
      if (this.form.selected_people.includes(personId)) {
        this.form.selected_people = this.form.selected_people.filter(
          (person) => person != personId
        )
      } else {
        this.form.selected_people.push(personId)
      }
    },
    onSubmit(event: Event) {
      event.preventDefault()
      this.$http
        .post("/weaver/organisations", { organisation: this.form })
        .then((r) => {
          this.$emit("save", r.data.crm_organisation)
        })
    },
    onReset() {},
  },
  computed: {
    clusterOptions() {
      return this.cluster.map(e => ({
        text: e.Kurzbezeichnung,
        value: e.ID,
      }))
    },
  },
  created() {
    this.$http
      .get(`/weaver/organisations/${this.organisationId}.json`)
      .then((r) => {
        this.cluster = r.data.cluster
        this.organisation = r.data.organisation
        this.form.name = r.data.organisation.name
        this.form.url = r.data.organisation.url
        this.form.weaver_organisation_id = r.data.organisation.id
        if (r.data.organisation.cluster_ids.length > 0) {
          this.form.selected_cluster = r.data.organisation.cluster_ids
        }
        if (r.data.organisation.organisation_addresses.length > 0) {
          this.form.selected_addresses =
            r.data.organisation.organisation_addresses.map((oa) => oa.id)
        }
        if (r.data.organisation.people.length > 0) {
          this.form.selected_people = r.data.organisation.people.map(
            (oa) => oa.id
          )
        }
      })
  },
})
</script>

<style scoped></style>
