<template lang="pug">
.panel.panel-primary
  .panel-heading
    h3.panel-title: a(@click.prevent='showAll = !showAll' href='#')
      span.badge.badge-dark
        | {{ vorgaenge.length }}
      |
      | Erinnerungen

  vorgaenge-table(:value="showVorgaenge" v-if='showVorgaenge.length > 0' :show-organisation='true')
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import VorgaengeTable from "profile/components/VorgaengeTable.vue"

export default Vue.extend({
  components: {
    VorgaengeTable,
  },
  props: {
    vorgaenge: {
      type: Array as PropType<schema.Vorgang[]>,
      required: true,
    },
  },
  data() {
    return {
      showAll: false,
    }
  },
  computed: {
    showVorgaenge() {
      if (this.showAll) return this.vorgaenge
      const dd = new Date(
        new Date().getTime() - 3600 * 24 * 1000 * 3
      ).toISOString()
      return this.vorgaenge.filter((v) => v.Erinnerung && v.Erinnerung > dd)
    },
  },
})
</script>
