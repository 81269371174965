
// fp_incr nicht am Date dran - Flatpickr Monkey Patch Probleme
const addDays = function(date, days) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + (typeof days === "string" ? parseInt(days, 10) : days)
  );
};
export default addDays
