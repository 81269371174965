import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import addCommunityWorkshopCallbacks from '@/modules/events'
import Vue from 'vue'

export default class extends Controller {
  connect() {
    $(document).on('click', '.js-select-all', function(e) {
      e.preventDefault();
      $('input[name*=selected_people]').each(function() { $(this).prop('checked', true) })
    })
    $(document).on('click', '.js-select-none', function(e) {
      e.preventDefault();
      $('input[name*=selected_people]').each(function() { $(this).prop('checked', false) })
    })

    $('#event-elemente').on('cocoon:after-insert', function(e, dom) { addCommunityWorkshopCallbacks(dom) })
    $('#event-elemente .nested-fields').each(function(dom) { addCommunityWorkshopCallbacks($(this)) })
  }
}
