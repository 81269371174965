<template lang="pug">
span.chip(:title='title')
  img.avatar(v-if='person.imageUrl' :src='"https://crm.pludoni.de" + person.imageUrl' alt='')
  span {{ person.name.lastName }}
</template>

<script lang='ts'>
import Vue, { PropType } from 'vue'
import { RecentlyImportedMailsQuery } from "types/graphql/requests"

type Vorgang = RecentlyImportedMailsQuery["vorgaenge"][0]

type Person = Vorgang["personen"][0]

export default Vue.extend({
  props: {
    person: { type: Object as PropType<Person>, required: true },
  },
  computed: {
    title(): string {
      return `${this.person.name.salutation} ${this.person.name.title} ${this.person.name.firstName} ${this.person.name.lastName}, ${this.person.position}`
    }
  },
})
</script>

<style scoped>
@import "./chip.scss";
</style>
