<template lang="pug">
div
  .d-flex.flex-wrap(v-if='message.parsed_data.company == message.parsed_data.new_company')
    svg(style="width:24px;height:24px" viewBox="0 0 24 24" title='Positionswechsel')
      path(fill="currentColor" :d="message.icon")
    |
    span.label.label-default
      | {{ message.parsed_data.position }}
    i.fa.fa-long-arrow-right.fa-fw.mx-1
    span.label.label-default
      | {{ message.parsed_data.new_position }}
  .d-flex.flex-wrap(v-else)
    svg(style="width:24px;height:24px" viewBox="0 0 24 24" title='Firmenwechsel')
      path(fill="currentColor" :d="message.icon")
    |
    span.label.label-default
      a.btn.btn-xs.btn-outline-light(v-if='message.organisation' :href='"/organisationen/" + message.organisation.ID')
        |CRM
      |
      | {{ message.parsed_data.company }},
      |
      | {{ message.parsed_data.position }}
    i.fa.fa-long-arrow-right.fa-fw.mx-1
    span.label.label-default
      a.btn.btn-xs.btn-outline-light(v-if='message.new_organisation' :href='"/organisationen/" + message.new_organisation.ID')
        |CRM
      |
      | {{ message.parsed_data.new_company }},
      |
      | {{ message.parsed_data.new_position }}
</template>

<script>
export default {
  components: {
  },
  props: {
    message: { type: Object, required: true },
  },
  data() {
    return {}
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style scoped>
.d-flex {
  display: flex;
  align-content: center;
  align-items: center;
}
</style>
