import consumer from "@/utils/consumer"

import { Controller } from "@hotwired/stimulus"

type ChannelData = {
  now: number,
  line: string,
  command: 'reset' | 'append' | 'finish'
}

export default class extends Controller {
  static values = { id: String, type: String, subscription: String }
  declare readonly idValue: string
  declare readonly typeValue: string
  declare readonly subscriptionValue: string
  declare subscription: any
  resetReceived: boolean = false

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "WeaverLogChannel", id: this.idValue, type: this.typeValue },
      {
        received: this.callback.bind(this)
      }
    )
  }

  callback(data: ChannelData) {
    if (data.command === "reset") {
      this.element.innerHTML = "<div class='list-group-item info'>Start</div>"
      this.resetReceived = true
      return
    }
    if (!this.resetReceived) {
      const content = `<div class='list-group-item log-error'>[...] Fehlendes Log wird erst am Ende des Crawlings nach einem Neuladen der Seite angezeigt</div>`
      this.element.innerHTML = content
      this.resetReceived = true
    }

    if (data.line) {
      const className = data.line.match(/ERROR|WARN|FATAL|INFO|DEBUG/)?.[0].toLowerCase() || "info"
      const autolinkedLine = data.line.replace(/(https?:\/\/[^\s]+)/g, "<a href='$1' target='_blank'>$1</a>")
      const content = `<div class='list-group-item log-${className}'>${autolinkedLine}</div>`
      this.element.insertAdjacentHTML("beforeend", content)
    }
    this.element.scrollTop = this.element.scrollHeight
  }

  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
