<template lang="pug">
.pdfvuer
  .pdfvuer-toolbar(v-if='false')
    .btn-group
      a.btn.btn-sm.btn-default(@click="zoomMinus")
        i.left.chevron.icon
        | Zoom -
      span.btn.btn-dark.btn.btn-sm.disabled
        | {{ Math.round(formattedZoom) }} %
      a.btn.btn-sm.btn-default(@click="zoomPlus")
        | Zoom +
        i.right.chevron.icon

  vue-pdf-embed(
    :source="url"
    ref='pdfs'
    @link-clicked="handle_pdf_link"
    :annotation="true"
  )
    template(slot="loading")
      | loading content here...
  .alert.alert-danger.m-5(v-if="error")
    | Error loading PDF:
    | {{ error.message }}
    br
    | {{ error.details }}
</template>

<script lang="ts">
import Vue from "vue"

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
// Geht nicht in Vite aktuall
//  https://github.com/arkokoley/pdfvuer/issues/106
// import pdfvuer from 'pdfvuer'
import "pdfjs-dist/build/pdf.worker.entry"

export default Vue.extend({
  components: {
    VuePdfEmbed,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      error: null,
      numPages: 0,
      errors: [],
      scale: 1,
    }
  },
  computed: {
    formattedZoom(): number {
      return +(this.scale * 100)
    },
  },
  watch: {
    page(p: number) {
      if (
        window.pageYOffset <=
          this.findPos(document.getElementById(`page-${p}`)) ||
        (document.getElementById(`page-${p + 1}`) &&
          window.pageYOffset >=
            this.findPos(document.getElementById(`page-${p + 1}`)))
      ) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(`page-${p}`)?.scrollIntoView()
      }
    },
  },
  methods: {
    zoomPlus() {
      this.scale += this.scale < 2 ? 0.2 : 0
    },
    zoomMinus() {
      this.scale -= this.scale > 0.1 ? 0.2 : 0
    },
    handle_pdf_link(params) {
      // Scroll to the appropriate place on our page - the Y component of
      // params.destArray * (div height / ???), from the bottom of the page div
      const page = document.getElementById(String(params.pageNumber))
      page?.scrollIntoView()
    },
    findPos(obj: HTMLElement | null) {
      return obj?.offsetTop || 0
    },
  },
})
</script>

<style src="pdfvuer/dist/pdfvuer.css">
</style>

<style lang="css" scoped>
#buttons {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.content {
  padding: 16px;
}
>>> .page {
  margin: auto;
}
</style>
