<template>
  <div>
    <span v-if="akquisegefuehl == 'hoch'" title="Hohe Wahrscheinlichkeit/Gute Chancen">
      <i class=" fa-thumbs-o-up fa fa-fw text-success"></i> 50%
    </span>
    <span v-if="akquisegefuehl == 'mittel'" title="Mittlere Wahrscheinlichkeit/Chancen">
      <i class="fa-fw fa fa-balance-scale" aria-hidden="true"></i> 25%
    </span>
    <span v-if="akquisegefuehl == 'niedrig'" title="Unwahrscheinlich">
      <i class="fa-fw fa fa-thumbs-o-down text-warning" aria-hidden="true"></i> 10%
    </span>
    <span v-if="akquisegefuehl == 'keine_chance'" title="Keine Chance">
      <i class="fa-fw fa fa-ban text-danger" aria-hidden="true"></i> 0%
    </span>
    <span v-if="akquisegefuehl == 'kontaktaufnahme'" title="Kontaktaufnahme">
      0%
    </span>
  </div>
</template>

<script lang='ts'>
import Vue from 'vue'

export default Vue.extend({
  props: {
    akquisegefuehl: { type: String, required: true },
  }
})
</script>
<style scoped></style>
