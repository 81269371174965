const filterVorgaenge = (vorgaenge: schema.Vorgang[], filter: string[], query = ""): schema.Vorgang[] => {
  let filtered = vorgaenge.filter((item) => {
    switch (item.typ?.key?.split("_")[0]) {
      case "invoice":
      case "cancellation":
        return filter.includes("rechnung")
      case "service":
      case "organisation":
      case "technical":
        return filter.includes("pflege")
      case "akquise":
        return filter.includes("akquise")
      default:
        return filter.includes("other")
    }
  })
  if (query && query !== "") {
    const qs = query.toLowerCase().split(/ +/)
    filtered = filtered.filter((item) => qs.every((q) => {
        let suchstring = `${item.Kommentar} ${item.cm_name} ${
          item.typ?.Bezeichnung
        } ${item.thema_notes?.map((e) => e.thema?.name)?.join(" ")}`
        if (item.person) {
          suchstring += ` ${item.person.Nachname} ${item.person.Vorname} ${item.person.Kommentar}`
        }
        if (item.community_manager) {
          suchstring += ` ${item.community_manager.ldap_login}`
        }
        return suchstring.toLowerCase().includes(q)
      }))
  }
  return filtered
}
export default filterVorgaenge
