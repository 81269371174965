<template lang="pug">
.d-flex.align-items-center
  input.form-control(@click.prevent="openModal = true" placeholder="Suche")

  b-modal(v-model="openModal", :hide-footer="true" size="xl" table-class='table-sm table-striped table-bordered')
    small.text-muted Tip: Diese Suche kann mit Shift+S aufgerufen werden, nach Eingabe eines Schlüsselwortes kann mit den Pfeiltasten + Enter eine Firma ausgewählt werden.
    .d-flex.input-group
      b-form-select(v-model="form.phone" :options="phoneOptions" style='max-width: 200px')
      input.form-control.flex-grow-1(
        autofocus
        v-model="form.q"
        placeholder="Suche"
        ref="input"
        @keydown.down="selectNext"
        @keydown.up="selectPrev"
        @keydown.enter="changeToSelected"
        @focus="focusMode = true"
        @blur="focusMode = false"
      )
      b-form-select(v-model="form.membership_type" :options="membershipOptions" style='max-width: 200px')

    small.text-muted Suchergebnisse in der Datenbank
    b-table(:items="users", :fields="fields", :sort-by.sync="sortBy", :sort-desc.sync="sortDesc" ref="table" show-empty)
      template(v-slot:empty="scope")
        span(v-if='isLoading')
          |..Lade
        span(v-else)
          |Keine CRM Suchergebnisse

      template(v-slot:cell(Bezeichnung)='data')
        span(:class='{selected: selectIndex == data.index && focusMode}')
          a(:href="'/organisationen/' + data.item.ID")
            |{{ data.value }}

    small.text-muted Suchergebnisse im Crawler/Weaver
    b-table(:items="weaverOrgs", :fields="weaverFields", :sort-by.sync="sortBy", :sort-desc.sync="sortDesc" ref="weaverTable" show-empty)
      template(v-slot:empty="scope")
        span(v-if='isLoading')
          |..Lade
        span(v-else)
          |Keine WeaverSuchergebnisse

      template(v-slot:cell(name)='data')
        a(:href="'/weaver/organisations/' + data.item.id")
          |{{ data.value }}
</template>

<script lang="ts">
import Vue from "vue"
import { BModal, BTable, BFormSelect } from "bootstrap-vue"
import debounce from "lodash.debounce"
import hotkeys from "hotkeys-js"


interface TableField<T> {
  key: keyof T
  sortable: boolean
}

export default Vue.extend({
  components: {
    BModal,
    BTable,
    BFormSelect,
  },
  data() {
    return {
      users: [] as schema.Organisation[],
      weaverOrgs: [],
      form: {
        q: "",
        phone: false,
        type: null,
        membership_type: null,
      },
      phoneOptions: [
        { text: "Keywordsuche", value: false },
        { text: "Telefonnummern", value: true },
      ],
      membershipOptions: [
        { text: "Alle", value: null },
        { text: "Mitglieder", value: "member" },
        { text: "Akquise", value: "akquise" },
      ],
      openModal: false,
      focusMode: false,
      isLoading: false,
      debouncedSearch: null as Function | null,
      sortBy: "name",
      sortDesc: true,
      selectIndex: 0,
      mostRecentRequestId: 0,
      weaverFields: [
        { key: "name", sortable: true },
      ],
      fields: [
        { key: "Bezeichnung", sortable: true },
        { key: "Nutzername", sortable: true },
      ] as TableField<schema.Organisation>[],
    }
  },
  methods: {
    changeToSelected() {
      if (!this.focusMode) return
      const user = this.$refs.table.sortedItems.map((e) => e.ID)[
        this.selectIndex
      ]
      if (user) {
        this.gotoUser(user)
      }
    },
    gotoUser(userId: number) {
      window.location.href = `/organisationen/${userId}`
    },
    search() {
      this.isLoading = true
      const form = { ...this.form } as any
      if (form.phone) {
        form.phone = form.q
        form.q = null
      }
      this.mostRecentRequestId += 1
      const requestId = this.mostRecentRequestId
      this.$http
        .get("/organisationen", { params: { search: form } })
        .catch((e) => {
            this.isLoading = false
            console.error(e)
        })
        .then((r) => {
          if (requestId < this.mostRecentRequestId) return
          this.mostRecentRequestId = requestId
          this.users = r.data.organisationen
          this.isLoading = false
        })
      this.$http
        .get("/weaver/organisations/search", { params: { q: form.q } })
        .then((r) => {
          this.weaverOrgs = r.data.organisations
          this.isLoading = false
        })
    },
    selectNext() {
      if (!this.focusMode) return
      if (this.selectIndex < this.users.length - 1) {
        this.selectIndex += 1
      }
    },
    selectPrev() {
      if (!this.focusMode) return
      if (this.selectIndex > 0) {
        this.selectIndex -= 1
      }
    },
  },
  created() {
    this.debouncedSearch = debounce(this.search, 200, {
      leading: true,
      trailing: true,
    })

    hotkeys("S,shift+s", (s) => {
      if (!s.shiftKey && s.key === "s") return
      this.openModal = true
    })
  },
  watch: {
    openModal(val) {
      if (val) {
        setTimeout(() => (this.$refs.input as HTMLElement).focus(), 300)
      }
    },
    form: {
      handler() {
        this.debouncedSearch && this.debouncedSearch()
      },
      deep: true,
    },
    users: {
      deep: true,
      handler(f) {
        if (f.length - 1 < this.selectIndex) {
          this.selectIndex = f.length - 1
        }
      },
    },
  },
})
</script>

<style scoped lang="scss">
span.selected {
  background: rgba(0, 0, 200, 0.5);
  a {
    color: white !important;
  }
  border-radius: 5px;
  display: block;
  &:after {
    content: "Enter zum Auswählen";
    display: inline-block;
    font-style: italic;
    margin-left: 5px;
    font-size: 0.8rem;
  }
}
select {
  background: #f2f2f2
}
</style>
