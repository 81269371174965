<template lang="pug">
div
  .row
    .col-4
      .btn-group.select-all-box
        button.btn.btn-sm(@click='selectAll("recipient_status", statusOptions)')
          i.fa.fa-check-square-o
        button.btn.btn-sm(@click='unselectAll("recipient_status")')
          i.fa.fa-square-o
      b-form-group(label='Status des Eingeladenen')
        b-form-checkbox-group(
          v-model='filter.recipient_status'
          :options='statusOptions'
          stacked
        )
    .col-4
      b-form-group(label='Vorherige Teilnehmer ausblenden' description="(Vorgangstyp: 'Organisation - hat am Community-Treffen teilgenommen' organisation_ct_participant)")
        b-form-checkbox(v-model='filter.hide_previous_participants')
          |Ausblenden, falls bereits in den letzten 365 Tagen an irgendeinem Event <strong>teilgenommen</strong> hat
      button.btn.btn-primary.btn-block(@click.prevent='refresh')
        |Laden

  div(v-if='isLoading')
    i.fa.fa-spinner.fa-spin.fa-3x.text-center

  div(v-if='isSaving')
    i.fa.fa-spinner.fa-spin.fa-3x.text-center
    |wird gespeichert...

  recipient-table(v-model='selected' v-if='emails.length > 0' :emails='emails')

  div.alert.alert-info(v-else-if='selected.length > 0')
    | Anzahl ausgewählter Kontakte aus vorheriger Auswahl: {{ selected.length }}
    br
    small Zum Löschen einfach erneut Kontakte auswählen.

</template>

<script lang="ts">
import {
  ref,
  computed,
  onMounted,
  defineComponent,
  PropType,
} from "vue"

import {
  BFormGroup,
  BTable,
  BFormCheckboxGroup,
  BFormCheckbox,
} from "bootstrap-vue"

import useMailingRecipientSave from "./recipientSave"
import useSelectAll from "./useSelectAll"

import RecipientTable from "./RecipientTable.vue"

export default defineComponent({
  components: {
    RecipientTable,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BTable,
  },
  props: {
    value: { type: Object as PropType<MailingForm["filter"]>, required: true },
    mailing: { type: Object as PropType<MailingForm>, required: true },
  },
  setup(props, ctx) {
    const filter = computed({
      get(): any {
        return props.value
      },
      set(value: any) {
        ctx.emit("input", value)
      },
    })
    const { selectAll, unselectAll } = useSelectAll(filter)

    const { selected, isLoading, isSaving, refresh, emails, fetchRecipients } =
      useMailingRecipientSave(filter, props.mailing.id, ctx.emit)

    const counts = ref({
      waiting_for_approval: 0,
      waiting_for_invitation: 0,
      invited: 0,
      confirmed: 0,
      final_confirmed: 0,
      not_attending: 0,
    })

    onMounted(() => {
      Object.keys(counts.value).forEach((key) => {
        fetchRecipients({ recipient_status: key }).then((response) => {
          counts.value = { ...counts.value, [key]: response.length }
        })
      })
    })
    const statusOptions = computed<CheckboxOption[]>(() => {
      return [
        {
          text: `Noch nicht freigeschaltet (${counts.value.waiting_for_approval})`,
          value: "waiting_for_approval",
        },
        {
          text: `Wurde vom System registriert (${counts.value.waiting_for_invitation})`,
          value: "waiting_for_invitation",
        },
        {
          text: `Einladungsemail versendet (${counts.value.invited})`,
          value: "invited",
        },
        {
          text: `Hat einmalig zugestimmt (${counts.value.confirmed})`,
          value: "confirmed",
        },
        {
          text: `Nimmt teil (final bestätigt) (${counts.value.final_confirmed})`,
          value: "final_confirmed",
        },
        {
          text: `Nimmt nicht Teil (${counts.value.not_attending})`,
          value: "not_attending",
        },
      ]
    })

    return {
      selectAll,
      unselectAll,
      filter,
      emails,
      selected,
      isLoading,
      isSaving,
      refresh,
      statusOptions,
      status: "Noch nicht freigeschaltet",
    }
  },
})
</script>
<style scoped></style>
