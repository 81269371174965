import { GraphQLClient } from "graphql-request"
import { getSdk } from "types/graphql/requests"

const client = new GraphQLClient("/graphql")

document.addEventListener("DOMContentLoaded", () => {
  const csrfToken = document.querySelector(
    "meta[name=csrf-token]"
  ) as HTMLMetaElement | null
  client.setHeader("X-CSRF-Token", csrfToken?.content || "")
})

export default getSdk(client)
