import $ from 'jquery'

$(document).on('click', '[data-controller=copy-location]', function(event) {
  event.preventDefault()
  const item = $(this).data('item')

  $('input[name*=Nummer]').val(item.hausnummer)
  $('input[name*=Strasse]').val(item.strasse)
  $('input[name*=PLZ]').val(item.plz)
  $('input[name*=Stadt]').val(item.ort)
  $('input[name*=Telefon]').val(item.international_phone_number)
})

