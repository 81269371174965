<template lang="pug">
div
  div(v-if='item.partnerAngebot.currentStatus?.status == "Partner" && item.partnerAngebot.nextStatus?.status == "Foerderer"')
    span.badge.badge-danger
      | Wechsel zu Förderer
    div: small Datum: {{ item.partnerAngebot.nextStatus.from | moment("L")}}
  div(v-if='gekuendigt && item.partnerAngebot')
    span.badge.badge-danger(v-if="item.partnerAngebot && item.partnerAngebot.status == 'gekuendigt'")
      | Gekündigt
    div: small Austritt: {{ item.partnerAngebot.servicePeriod.to | moment("L") }}
    .text-muted.small.kuendigungsgrund(v-if="gekuendigt && item.partnerAngebot.kuendigungsgrund" title="Kündigungsgrund (Freitext)")
      i.fa.fa-info-circle
      |
      | {{ item.partnerAngebot.kuendigungsgrund }}
    .text-muted.small.kuendigungsgrund.d-flex(v-if="gekuendigt && item.partnerAngebot.cancellationReasons.length > 0" title="Kündigungsgrund (Auswahl)" style='gap: 2px')
      i.fa.fa-info-circle
      |
      .d-flex.gap-1.flex-wrap(style='gap: 2px')
        span.badge.badge-dark(v-b-tooltip v-for='g in item.partnerAngebot.cancellationReasons.map(e => e.name)' :title="g")
          | {{ g.substring(0,32) + "..." }}

  div(v-else-if='neukunde && item.partnerAngebot')
    span.badge.badge-success
      | Neukunde
    div: small Start: {{ item.partnerAngebot.servicePeriod.from | moment("L")}}

  small.badge.badge-dark(v-if='item.billingYears && item.billingYears > 0' title='zusammenhänge Mitgliedsjahre' v-b-tooltip)
    | {{item.billingYears}} Jahre

  div(v-if='renewalDate')
    small.no-wrap
      i.fa.fa-fw.fa-refresh(title='Verlängerung' v-b-tooltip)
      |
      |{{ renewalDate }}

  template(v-if='hasUnpaidBills')
    button.btn.btn-xs.btn-warning(@click.click.prevent='openBillModal')
      template(v-if='hasDemandableBills' title='Mahnbare Rechnungen' v-b-tooltip)
        | Mahnbar!
      template(v-else title='Offene Rechnungen vorhanden' v-b-tooltip)
        | Unbezahlt

  .d-flex
    button.btn.btn-info.btn-xs(@click='erinnerungModalOpen = true' v-if='erinnerungen.length > 0')
      svg(style="width:15px;height:15px" viewBox="0 0 24 24" title='Erinnerungen' v-b-tooltip)
        path(fill="currentColor" :d="mdiAlarm")
      span.badge.badge-dark
        |{{ erinnerungen.length }}

    button.btn.btn-info.btn-xs(@click='issueModalOpen = true' title='Gitlab Kundenanfragen' v-b-tooltip v-if='issues.length > 0')
      svg(style="width:15px;height:15px" viewBox="0 0 24 24")
        path(fill="currentColor" :d="mdiGitlab")
      span.badge.badge-dark
        |{{ issues.length }}
    button.btn.btn-info.btn-xs(@click='incidentModalOpen = true' title='Incidents' v-b-tooltip v-if='incidents.length > 0')
      svg(style="width:15px;height:15px" viewBox="0 0 24 24")
        path(fill="currentColor" :d="mdiAlertCircle")
      span.badge.badge-dark
        |{{ incidents.length }}


  b-modal(v-model='issueModalOpen' :hide-footer='true' title='Kundenanfragen' size='xl')
    .card(v-for='issue in issues')
      .card-header
        a(:href='issue.url' target='_blank')
          i.fa.fa-fw.fa-external-link
          |
          |{{ issue.title }}
      .card-body(v-html='issue.description')

  b-modal(v-model='erinnerungModalOpen' :hide-footer='true' title='Erinnerungen' size='xl')
    vorgaenge-table(:value='erinnerungen')

  b-modal(v-model='incidentModalOpen' :hide-footer='true' title='Incidents' size='xl')
    table(class="table table-sm")
      IncidentRow(
        v-for="incident in incidents"
        :key="incident.id"
        :incident="incident"
        @remove="removeIncident"
      )
  b-modal(v-model='billModalOpen' :hide-footer='true' title='Rechnungen' size='xl')
    rechnungen-table(:value='unpaidBills' :showCompany='false' v-if='unpaidBills')


</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { BModal } from "bootstrap-vue"
import { mdiGitlab, mdiAlarm, mdiAlertCircle } from "@mdi/js"
import VorgaengeTable from "./VorgaengeTable.vue"
import IncidentRow from 'dashboard/components/incidents/IncidentRow.vue'
import RechnungenTable from 'profile/components/RechnungenTable.vue'
import { dashboard } from 'types/dashboard.d'

import gql from "utils/graphql"
import { UnpaidRechnungFragmentFragment } from "types/graphql/requests"

type Incident = dashboard.PartnerTableRowWithIssues["incidents"][0]

export default Vue.extend({
  components: {
    BModal,
    VorgaengeTable,
    RechnungenTable,
    IncidentRow,
  },
  props: {
    item: { type: Object as PropType<dashboard.PartnerTableRowWithIssues>, required: true },
  },
  data() {
    return {
      mdiGitlab,
      mdiAlertCircle,
      mdiAlarm,
      issueModalOpen: false,
      erinnerungModalOpen: false,
      incidentModalOpen: false,
      billModalOpen: false,
      unpaidBills: undefined as undefined | UnpaidRechnungFragmentFragment[]
    }
  },
  methods: {
    removeIncident(incident: Incident) {
      this.item.incidents = this.item.incidents.filter(i => i.id !== incident.id)
    },
    async openBillModal() {
      if (!this.item.username) return

      const { organisationByUsername } = await gql.UnpaidRechnungenOfOrganisation({ username: this.item.username })
      this.billModalOpen = true
      this.unpaidBills = organisationByUsername?.rechnungen
    }
  },
  computed: {
    hasUnpaidBills(): boolean {
      return this.item.unpaidBills.length > 0
    },
    hasDemandableBills(): boolean {
      return this.item.unpaidBills.some(b => b.paymentDemandable)
    },
    gekuendigt(): boolean {
      return this.item.partnerAngebot?.status == "gekuendigt"
    },
    neukunde(): boolean {
      return !!this.item.partnerAngebot?.neukunde
    },
    renewalDate(): string | undefined {
      const d = this.item.partnerAngebot?.renewableSince
      if (d) {
        return this.$moment(d).format("L")
      }
    },
    issues() {
      return this.item.issues
    },
    erinnerungen() {
      return this.item.erinnerungen
    },
    incidents() {
      return this.item.incidents
    },
  },
})
</script>
<style scoped>
.no-wrap {
  white-space: nowrap;
}
</style>
