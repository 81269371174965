<template lang="pug">
.panel.panel-primary
  .panel-heading
    h3.panel-title Umsatz
  highcharts(v-if='chartData' :options="chart")
  div(v-if='isLoading' style='height: 400px')
    .text-center: i.fa.fa-spinner.fa-spin.fa-3x
</template>

<script lang="ts">
import Vue from "vue"
import qs from "qs"
import Highcharts from "modules/highcharts"
import type { Options } from "highcharts"

export default Vue.extend({
  data() {
    return {
      chartData: null,
      isLoading: false,
    }
  },
  computed: {
    chart(): Options {
      return {
        series: this.chartData || undefined,
        chart: {
          zoomType: "x",
          type: "spline",
        },
        title: {
          enabled: false,
          text: undefined,
        },
        legend: {
          enabled: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
              lineWidth: 1,
              marker: {
                enabled: false,
              },
              shadow: false,
              states: {
                hover: {
                  lineWidth: 1,
                },
              },
              threshold: null,
            },
          },
        },
        yAxis: {
          title: {
            text: "Umsatz",
          },
          labels: {
            formatter() {
              return `${Highcharts.numberFormat(this.value)} €`
            },
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          valueSuffix: " €",
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mai",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dez",
          ],
          min: 0,
          title: {
            text: "Monate",
          },
        },
      } as Options
    },
  },
  mounted() {
    const params = {
      statistics_umsatz: {
        rechnungstypen: [10],
        use_beginn: 1,
        distributed: 0,
        predict: 1,
        predict_auto_renew_all: 1,
        details: 0,
        min_year: new Date().getFullYear() - 1,
        max_year: "",
      },
    }
    this.isLoading = true
    this.$http
      .get(
        `/statistics/umsatz?${qs.stringify(params, {
          arrayFormat: "brackets",
        })}`,
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((response) => {
        this.isLoading = false
        this.chartData = response.data
      })
  },
})
</script>
<style scoped></style>
