import $ from "jquery"

window.addCommunityWorkshopCallbacks = function (dom: JQuery) {
  dom
    .find("select[name*=organisation_id]")
    .select2({
      minimumInputLength: 1,
      ajax: {
        url: "/autocomplete/organisation",
        dataType: "json",
        delay: 250,

        data: function (params) {
          return {
            q: params.term,
            page: params.page,
          }
        },

        processResults: function (data, params) {
          params.page = params.page || 1

          return {
            results: data,

            pagination: {
              more: data.count === 10,
            },
          }
        },
      },
    })
    .on("select2:select", function (e) {
      var company_name = $(this)
        .select2("data")[0]
        .text.replace(/ \([^\)]*\)$/, "")
      dom.find("input[name*=event_host]").val(company_name)
      var template_node = dom
        .find(".js-organisation-locations")
        .html("Loading...")
      var org_id = $(e.target).val()

      return $.get(
        "/autocomplete/organisation_locations?id=" + org_id,
        function (data) {
          template_node.html("")

          for (var location of data) {
            var node = $("<a href='#'></a>")
            node.attr("class", "btn btn-default btn-xs")
            node.html(location.Strasse + " / " + location.Stadt)
            node.data("location", location)
            template_node.append(node)
          }

          return template_node.append("<br/><br/>")
        }
      )
    })

  dom.on("click", ".js-organisation-locations a", function (e) {
    e.preventDefault()
    var a = $(this)
    var location = a.data("location")
    var string =
      location.Strasse +
      " " +
      location.Nummer +
      ", " +
      location.PLZ +
      " " +
      location.Stadt
    return dom.find("input[name*=address]").val(string)
  })

  return dom.find("select[multiple]").select2()
}

export default window.addCommunityWorkshopCallbacks
