<template lang="pug">
div(ref='root')
  editor(
    v-model='body'
    :placeholders='placeholders'
    :templateUrl='templateUrl'
    :slim='slim'
  )
  input(type='hidden' :value='body' :name='formName')

  .card.mt-2(v-if='previewUrl'): .card-body
    .d-flex.justify-content-between
      strong Vorschau

      button.btn.btn-xs.btn-outline-dark(@click.prevent='refreshPreview')
        |refresh

    div.border
      div.d-flex.align-items-center.border.p-2(style='gap: 5px')
        small.text-muted Betreff:
        div
          | {{previewSubject }}
      iframe(:srcdoc='previewBody' src='/404' v-if='previewBody')

</template>

<script lang="ts">
import Editor from "mailing_form/editor/Editor.vue"
import { defineComponent, ref, watch } from "vue"
import debounce from 'lodash.debounce'
import axios from "utils/axios"

export default defineComponent({
  components: {
    Editor,
  },
  props: {
    formName: { type: String, required: true },
    slim: { type: Boolean, default: false },
    initial: { type: String, default: () => "" },
    placeholders: { type: Array, default: () => [] },
    templateUrl: { type: String, default: () => "/admin/mailtemplates.json", },
    previewUrl: { type: String, default: null },
  },
  setup(props) {
    const body = ref(props.initial)
    const previewBody = ref("")
    const previewSubject = ref("")
    const root = ref<HTMLElement | null>(null)

    const formData = (): FormData | undefined => {
      if (!root.value) return
      const form = root.value?.closest("form")
      if (!form) return
      const fd = new FormData(form)
      fd.append("body", body.value)
      return fd
    }
    const refreshPreview = async () => {
      if (!props.previewUrl) return

      previewBody.value = ""
      previewSubject.value = ""
      const response = await axios.post(props.previewUrl, formData())

      previewBody.value = response.data.html
      previewSubject.value = response.data.subject
    }
    const debouncedRefreshPreview = debounce(refreshPreview, 500, { leading: true, trailing: true })
    watch(body, debouncedRefreshPreview)

    return {
      root,
      refreshPreview: debouncedRefreshPreview,
      previewSubject,
      previewBody,
      body,
    }
  },
})
</script>

<style scoped>
iframe {
  border: 0;
  width: 100%;
  height: 600px;
}
</style>
