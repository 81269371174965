<template lang="pug">
div
  editor(
    v-model='proxyValue'
    :init='options'
    v-if='marketingpassus'
  )
</template>

<script lang="ts">
import type { RawEditorSettings } from "tinymce"
import Vue from "vue"

import "tinymce"

/* Default icons are required for TinyMCE 5.3 or above */
import "tinymce/icons/default"

/* A theme is also required */
import "tinymce/themes/silver"

/* Import the skin */
import "tinymce/skins/ui/oxide/skin.css"

/* Import plugins */
import "tinymce/plugins/advlist"
import "tinymce/plugins/code"
import "tinymce/plugins/emoticons"
import "tinymce/plugins/emoticons/js/emojis"
import "tinymce/plugins/link"
import "tinymce/plugins/lists"
import "tinymce/plugins/table"
import "tinymce/plugins/anchor"
import "tinymce/plugins/paste"
import "tinymce/plugins/image"
import "tinymce/plugins/imagetools"
import "tinymce/plugins/fullscreen"
import "tinymce/plugins/media"
import "tinymce/plugins/wordcount"
import "tinymce/plugins/noneditable"
import "./placeholder"
import "./quick_template"
import "./template_selection"

import text from 'styles/tinymce.css?inline'

import Editor from "@tinymce/tinymce-vue"

export default Vue.extend({
  components: {
    Editor,
  },
  props: {
    slim: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    templateUrl: {
      type: String,
      default: () => "/admin/mailtemplates.json",
    },
    placeholders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      marketingpassus: null as String | null,
      signature: null as String | null,
      fragments: [],
      templates: [] as Mailtemplate[],
    }
  },
  created() {
    this.$http.get(this.templateUrl).then(response => {
      this.templates = response.data.templates
      this.fragments = response.data.fragments || []
      this.marketingpassus = response.data.marketingpassus
      this.signature = response.data.signature
    })
  },
  computed: {
    proxyValue: {
      get(): string {
        return this.value
      },
      set(value: string) {
        this.$emit("input", value)
      },
    },
    options(): RawEditorSettings {
      return {
        min_height: 500,
        menubar: !this.slim,
        skin: false,
        placeholders: this.placeholders,
        images_upload_handler(blobInfo, success, failure) {
          const xhr = new XMLHttpRequest();
          xhr.open('POST', '/mailings/image_upload');
          const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
          if (csrfToken) {
            xhr.setRequestHeader('X-CSRF-Token', csrfToken);
          }
          xhr.onload = function() {
            if (xhr.status !== 200) {
              failure('HTTP Error: ' + xhr.status);
              return;
            }
            const json = JSON.parse(xhr.responseText);
            if (!json || typeof json.location !== 'string') {
              failure('Invalid JSON: ' + xhr.responseText);
              return;
            }
            success(json.location);
          };

          const formData = new FormData();
          formData.append('file', blobInfo.blob(), blobInfo.filename());
          xhr.send(formData);
        },
        content_css: false,
        content_style: text + "\n" + `
          body {
            font-family: Verdana,Arial,Helvetica,sans-serif;
            font-size: 14px;
            line-height: 1.3;
          }
        ` + this.slim ? `
          body {
            font-size: 13px;
            line-height: 1.2;
          }
        ` : "",
        templates: this.templates,
        fragments: [
          ...(this.fragments || []),
          {
            title: "Marketing-Passus",
            template_group: "Standard",
            body: this.marketingpassus,
          },
          {
            title: "Signatur",
            template_group: "Standard",
            body: this.signature,
          },
        ],
        plugins: this.slim ? [
          "lists link anchor noneditable code paste code",
        ] : [
          "advlist lists link image imagetools anchor quicktemplate templateselection placeholder noneditable",
          "code fullscreen",
          "media table paste code wordcount",
        ],
        branding: false,
        toolbar1:
          "lineheightselect | formatselect | bold italic strikethrough forecolor backcolor fontsizeselect h2 h3 h4 h5 | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | table removeformat code",
        toolbar2:
          "link image media | templates quicktemplate placeholder | undo redo",
      }
    },
  },
})
</script>
<style scoped>
</style>
