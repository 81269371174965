import $ from "jquery"

const pageLoad = () => {
  $("[data-quickoptions]").each(function () {
    const input = $(this)
    const selector = input.data("quickoptions")

    const options = $(`${selector} a`)

    options.click(function quickoptionClick(e) {
      e.preventDefault()
      e.stopPropagation()
      if (input.data("quickoptions-append")) {
        const input_name = input.attr("id")
        const mails = document.getElementById(input_name).value
        const mail = $(this).data("value")
        if (mails.indexOf(mail) == -1)
          if (mails.length == 0) input.val(mail).change()
          else input.val(mails + ", " + mail).change()
      } else {
        const value = $(this).data("value")
        input.val(value).change()
      }
    })
  })
}

$(pageLoad)
$(document).ajaxComplete(pageLoad)
