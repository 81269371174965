<template>
  <div>
    <ul class="nav nav-tabs">
      <li class='nav-item'>
        <a class="label-tab nav-link">{{ label }}</a>
      </li>
      <li class='nav-item' :class="{ active: filter == null }">
        <a class='nav-link' @click="setFilter(null)">
          Alle <span class="badge"> {{ incidents.length }} </span>
        </a>
      </li>
      <li class='nav-item' :class="{ active: filter == 'urgent' }">
        <a class='nav-link' @click="setFilter('urgent')">
          Dringend
          <span class="badge">
            {{ incidents.filter(s => s.urgent).length }}
          </span>
        </a>
      </li>
      <li class='nav-item' :class="{ active: filter == 'direct_message' }">
        <a class='nav-link' @click="setFilter('direct_message')">
          <i class="fa fa-fw fa-envelope-o" title="Direkt-Nachrichten"></i>
          <span class="badge">
            {{
              incidents.filter(s => s.incident_type == "direct_message").length
            }}
          </span>
        </a>
      </li>
      <li class='nav-item' :class="{ active: filter == 'measure' }">
        <a class='nav-link' @click="setFilter('measure')">
          <i class="fa fa-fw fa-thumb-tack" title="Maßnahmen"></i>
          <span class="badge">
            {{ incidents.filter(s => s.incident_type == "measure").length }}
          </span>
        </a>
      </li>
      <li class='nav-item' :class="{ active: filter == 'background_check' }">
        <a class='nav-link' @click="setFilter('background_check')">
          <i class="fa fa-fw fa-bug" title="Hintergrund Prüfungen"></i>
          <span class="badge">
            {{
              incidents.filter(s => s.incident_type == "background_check")
                .length
            }}
          </span>
        </a>
      </li>
      <li class='nav-item' :class="{ active: filter == 'communication_recommendation' }">
        <a class='nav-link' @click="setFilter('communication_recommendation')">
          <i class="fa fa-fw fa-comments" title="Review/Telefongespräch vereinbaren"></i>
          <span class="badge">
            {{
              incidents.filter(s => s.incident_type == "communication_recommendation")
                .length
            }}
          </span>
        </a>
      </li>
    </ul>
    <div class="list-group">
      <div
        v-for="group in limitedGroups"
        :key="group.organisation.ID"
        class="list-group-item py-0"
      >
        <strong>
          <a :href="'/organisationen/' + group.organisation.ID" target="_blank">
            {{ group.organisation.Bezeichnung }}
          </a>
        </strong>
        <table class="table table-sm">
          <IncidentRow
            v-for="incident in visibleItems(group.items)"
            :key="incident.id"
            :incident="incident"
            @remove="removeIncident"
          />
        </table>
      </div>
      <div
        v-if="!showAll && sortedIncidentGroups.length > limitedGroups.length"
      >
        <a @click="showAll = true" class="btn btn-link">
          Alle {{ sortedIncidentGroups.length }} Firmen mit Incidents anzeigen
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import _groupBy from "lodash/groupBy"
import _sortBy from "lodash/sortBy"

import IncidentRow from "./IncidentRow.vue"

export default {
  components: { IncidentRow },
  props: {
    incidents: { type: Array, required: true },
    label: { type: String, required: true },
  },
  data() {
    return {
      filter: null,
      finished: [],
      showAll: false,
    }
  },
  computed: {
    sortedIncidentGroups() {
      let base = this.incidents
      if (this.filter === "urgent") {
        base = base.filter(s => s.urgent)
      } else if (this.filter !== null) {
        base = base.filter(s => s.incident_type === this.filter)
      }
      base = _sortBy(
        Object.values(_groupBy(base, s => s.organisation_id)),
        group => group.map(s => s.weight).reduce((a, e) => a + e) * -1
      ).map(group => ({
        organisation: group[0].organisation,
        items: _sortBy(group, s => s.weight * -1),
      }))
      return base
    },
    limitedGroups() {
      let base = this.sortedIncidentGroups
      if (!this.showAll) {
        base = base.slice(0, 5)
      }
      return base
    },
  },
  methods: {
    setFilter(filter) {
      this.filter = filter
      this.showAll = false
    },
    removeIncident(incident) {
      this.finished = [...this.finished, incident]
    },
    visibleItems(group) {
      return group.filter(
        incident => !this.finished.some(other => other.id === incident.id)
      )
    },
  },
}
</script>

<style scoped>
.label-tab {
  height: 40px;
  color: black;
  text-transform: none;
  font-weight: bold;
  border-color: transparent;
  background: none;
}
.list-group-item:first-child {
  border-top: none;
}
.list-group-item {
  border-right: 0;
  transition: background 0.2s ease-in;
  border-left: 0;
  &:hover {
    background: #eee;
  }
}
.list-group-item a {
  color: #222;
}
</style>
