<template lang="pug">
span.chip
  img.avatar(v-if='image' :src='"https://crm.pludoni.de" + image' alt='' @error='image = null')
  span {{ name }}
</template>

<script lang="ts">
import Vue, { PropType } from "vue"

type GraphQlCm = {
  id: number,
  ldapLogin?: string,
  image?: string,
}
export default Vue.extend({
  props: {
    communityManager: {
      type: Object as PropType<schema.CommunityManager | GraphQlCm>,
      required: true,
    },
  },
  computed: {
    image(): string | null {
      return this.communityManager.manager_image?.icon?.url || this.communityManager.image || this.communityManager.manager_image?.icon
    },
    name(): string {
      return this.communityManager.ldap_login || this.communityManager.ldapLogin
    },
  }
})
</script>

<style scoped lang="scss">
@import "./chip.scss";
</style>
