import Vue from 'vue'

import { ToastPlugin } from 'bootstrap-vue'

Vue.use(ToastPlugin)

import Automount from "@pludoni/frontend-pack/dist/vue/automount"

import Dashboard from "dashboard/Dashboard.vue"
import MailingForm from "mailing_form/MailingForm.vue"
import EmailTemplateEditor from "mailing_form/EmailTemplateEditor.vue"

import GitlabIssueList from "profile/components/GitlabIssueList.vue"
import SearchBar from "dashboard/SearchBar.vue"
import VorgaengeList from 'profile/components/VorgaengeList.vue'
import CommunityManagerChip from 'profile/components/CommunityManagerChip.vue'
import ActiveStorageUploadField from "common/ActiveStorageUploadField.vue"
import PDFViewerWithButton from "common/PDFViewerWithButton.vue"
import ThemaNotesField from "profile/components/ThemaNotesField.vue"
import TopicsTable from "profile/components/TopicsTable.vue"
import CreateOrganisation from "@/weaver/components/CreateOrganisation.vue"
import AssignOrganisationButton from "@/weaver/components/AssignOrganisationButton.vue"
import AngebotAttachments from "organisation/AngebotAttachments.vue"

import sizeToMb from 'utils/sizeToMb'
Vue.filter('sizeToMb', sizeToMb)

Automount({
  "dashboard": {
    component: Dashboard,
  },
  "mailing-form": { component: MailingForm },
  "email-template-editor": { component: EmailTemplateEditor },
  "gitlab-issue-list": { component: GitlabIssueList },
  "vorgaenge-list": { component: VorgaengeList },
  "search-bar": { component: SearchBar },
  "active-storage-upload-field": { component: ActiveStorageUploadField },
  "community-manager-chip": { component: CommunityManagerChip },
  "pdf-viewer-with-button": { component: PDFViewerWithButton },
  "thema-notes-field": { component: ThemaNotesField },
  "topics-table": { component: TopicsTable },
  "weaver-assign-organisation-button": { component: AssignOrganisationButton },
  "angebot-attachments": { component: AngebotAttachments },
})
