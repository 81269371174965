<template lang="pug">
div
  .card.overflow-hidden.wizard-header: .row.bs-wizard
    .col-sm.bs-wizard-step(v-for='(page, i) in pages' :class='{complete: i < currentPage - 1, active: i == currentPage - 1 }')
      .text-center.bs-wizard-stepnum
        | {{ page.title }}
      .progress: .progress-bar
      a.bs-wizard-dot.bs-clickable(v-if='i < maxPage' @click.prevent='currentPage = i + 1' style='cursor: pointer')
      a.bs-wizard-dot(v-else)
  .bs-wizard-content: slide-x-left-transition(:duration="300" :delay="400" :group='true')
    .card(v-if='i + 1 == currentPage' :key='i' v-for='(page, i) in pages')
      .card-header.bg-dark.text-white
        h3.card-title
          |{{ page.title }}
      .card-body
        slot(:name="'page(' + (i + 1) +')'" v-bind:page="page" :index='i + 1')
      .card-footer
        slot(name='footer' v-bind:page='page' :index='i + 1' :goNext='() => goNext(i + 1)')
          button.btn.btn-link(@click.prevent='goBack(i + 1)' v-if='i > 0')
            |zurück
          template(v-if='i == pages.length - 1')
            slot(name='last')
          template(v-else)
            button.btn.btn-primary.btn-lg(@click.prevent='page.isValid && goNext(i + 1)' :disabled='!page.isValid')
              | weiter

</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { SlideXLeftTransition } from "vue2-transitions"

type Page = {
  isValid: boolean
  title: string
  onNext?: Function,
}

type foo = (this: Window, ev: PopStateEvent) => any

export default Vue.extend({
  components: { SlideXLeftTransition },
  props: {
    pages: { type: Array as PropType<Page[]>, required: true },
  },
  data() {
    return {
      currentPage: 1,
      maxPage: 1,
      callback: null as foo | null,
    }
  },
  mounted() {
    const t = this
    this.callback = (event) => {
      if (event.state && event.state.page) {
        t.currentPage = event.state.page
      }
    }
    window.addEventListener("popstate", this.callback)
    // window.history.pushState({ page: 1 }, null, '#page1')
  },
  beforeDestroy() {
    if (this.callback) {
      window.removeEventListener("popstate", this.callback)
    }
  },
  methods: {
    async goNext(i: number) {
      const curPage = this.pages[this.currentPage - 1]
      if (curPage.onNext) {
        const returnValue = await curPage.onNext(curPage, this.currentPage)
        if (returnValue === false) return
      }
      const page = i + 1
      if (window.history) {
        window.history.pushState({ page }, null, `#page${page}`)
      }
      this.currentPage = page
      if (page > this.maxPage) {
        this.maxPage = page
      }
    },
    goBack(i: number) {
      this.currentPage = i - 1
    },
  },
})
</script>

<style scoped>
.bs-clickable {
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}
.bs-clickable:hover {
  transform: scale(1.05);
  filter: brightness(1.1);
}
.wizard-header {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bs-wizard {
  margin-top: 15px;
  margin-bottom: 10px;
  background: white;
  border: none;
}
.card-header {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: 0;
}
</style>
