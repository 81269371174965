<template lang="pug">
div.text-center
  | {{ item.patientenakte.applicationsLast12Months }}

  highcharts.statistik-chart(v-if='statistics.length > 0' :options="applicationData")
  highcharts.statistik-chart(v-if='statistics.length > 0' :options="clickData")
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { PartnerTableQuery } from "types/graphql/requests"

type PartnerTableRow = PartnerTableQuery["organisations"][0]

const Sparkline = {
  title: { text: null },
  subtitle: { text: null },
  exporting: { enabled: false },
  chart: {
    //borderWidth: 0,
    spacing: [5, 0, 10, 0],
    margin: [5, 0, 10, 0],
    animation: false,
    type: "spline",
  },
  legend: { enabled: false },
  credits: { enabled: false },
  plotOptions: {
    series: {
      fillOpacity: 0.25,
      marker: { radius: 2 },
    },
  },
  tooltip: {
    enabled: true,
    outside: true,
    shared: true,
  },
}
const YAxis = {
  endOnTick: false,
  startOnTick: false,
  min: 0,
  labels: {
    enabled: false,
  },
  title: {
    text: null,
  },
  visible: true,
}

export default Vue.extend({
  components: {},
  props: {
    item: { type: Object as PropType<PartnerTableRow>, required: true },
  },
  computed: {
    statistics() {
      const pt = (this.item as PartnerTableRow).patientenakte
      return pt?.statistics || []
    },
    applicationData() {
      // make a small line chart of highcharts with axis statistics[0].recordDate and statistics[0].applications
      return {
        ...Sparkline,
        yAxis: [
          YAxis,
          YAxis,
        ],
        xAxis: {
          labels: {
            enabled: false,
          },
          title: {
            text: null,
          },
          startOnTick: false,
          endOnTick: false,
          //tickPositions: [],
          categories: this.statistics.map((s) => s.recordDate),
        },
        series: [
          {
            name: "Stellen online gesamt",
            data: this.statistics.map((s) => s.totalJobCount),
            type: "column",
            // color: light green:
            color: "#00550055",
            yAxis: 1,
          },
          {
            name: "Bewerbungen (Partnerangabe/Berechnet)",
            data: this.statistics.map((s) => s.applications),
            yAxis: 0,
          },
        ],
      }
    },
    clickData() {
      // make a small line chart of highcharts with axis statistics[0].recordDate and statistics[0].applications
      return {
        ...Sparkline,
        yAxis: [
          YAxis,
          YAxis,
        ],
        xAxis: {
          labels: {
            enabled: false,
          },
          title: {
            text: null,
          },
          startOnTick: false,
          endOnTick: false,
          //tickPositions: [],
          categories: this.statistics.map((s) => s.recordDate),
        },
        series: [
          {
            name: "Smartclicks",
            data: this.statistics.map((s) => s.smartclicks),
            yAxis: 0
          },
          {
            name: "Clicks",
            data: this.statistics.map((s) => s.clicks),
            yAxis: 1
          },
        ],
      }
    },
  },
})
</script>
<style scoped>
.statistik-chart {
  height: 40px;
  margin-top: 5px;
  width: 250px;
}
</style>
