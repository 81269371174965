$(document).on("click", ".js-calculate-reisezeit", function (event) {
  event.preventDefault()
  event.stopPropagation()
  const el = $(this)
  const parent = el.closest(".list-group-item")
  const from = parent.find("input[name*=Von]").val()
  const to = parent.find("input[name*=Nach]").val()
  const cm = $("input[name*=CommunityManager]").val()
  const url = el.attr("href") as string
  $.post(
    url,
    { community_manager: cm, from: from, to: to },
    (response) => {
      if (response) {
        parent
          .find("input[name*=Entfernung]")
          .val(response.distance)
          .prop("readonly", false)
        parent
          .find("input[name*=Reisezeit]")
          .val(response.time)
          .prop("readonly", false)
      }
    }
  )
})
