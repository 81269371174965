<template lang='pug'>
div
  .panel.panel-primary
    .panel-heading
      h3.panel-title
        a(@click.prevent='show = !show' href='#')
          | Zuletzt importierte Mails über crm@pludoni.de
      div
    .collapse(:class='{"show": show}')
      PulseLoader(:loading="loading")

      b-table.table-sm(
        v-if='vorgaenge.length > 0'
        :fields="fields"
        striped
        hover,
        :items="vorgaenge"
        responsive
        primary-key="id",
        :tbody-transition-props="{ name: 'flip-list' }"
        show-empty,
        :sortBy.sync="sortBy"
        tbody-tr-class="flip-list-item"
      )
        template(v-slot:cell(organisation.name)=" { item }")
          a(:href="'/organisationen/' + item.organisation.id")
            | {{ item.organisation.name }}

        template(v-slot:cell(type.key)="{ item }")
          | {{ item.type.name }}

          div(v-if="item.comment" style='line-height: 1.1;')
            small {{ item.comment }}

        template(v-slot:cell(personen)="{ item }")
          person-chip(v-for="person in item.personen", :person="person", :key="person.id")

        template(v-slot:cell(communityManagerName)="{ item }")
          community-manager-chip(v-if="item.communityManager", :community-manager="item.communityManager")
          span(v-else)
            | {{ item.communityManagerName }}

          div(v-if="item.gebuchteZeit && item.gebuchteZeit > 0")
            small
              i.fa.fa-fw.fa-clock-o
              | {{ item.gebuchteZeit }}m
        template(v-slot:cell(date)="{ item }")
          | {{ item.date | moment('L') }}

        template(v-slot:cell(actions)="{ item }")
          .btn-group.d-flex
            button.a.btn.disabled(v-if='done[item.id]')
              i.fa.fa-check-circle.fa-fw

            a.btn.btn-sm.btn-success(v-else-if="item.canEdit", @click='markAsDone(item)' title="E-Mail als erledigt markieren ohne Änderung" v-b-tooltip)
              i.fa.fa-check.fa-fw

            a.btn.btn-sm.btn-primary.js-modal(v-if="item.canEdit", :href="'/vorgaenge/' + item.id + '/edit?select_type=1'" title="Bearbeiten")
              i.fa.fa-pencil-square-o.fa-fw

            a.btn.btn-sm.btn-warning.js-modal(
              v-if="item.canDestroy",
              :href="'/vorgaenge/' + item.id"
              title="Löschen"
              data-method="delete"
              data-confirm="Löschen?"
            )
              i.fa.fa-trash-o.fa-fw
      .text-right: button.btn.btn-outline-danger.m-3(v-if='vorgaenge.length > 5' @click="markAllDone")
        |ALLE als erledigt markiert

</template>

<script lang='ts'>
import Vue from "vue"
import { BTable, BvTableFieldArray } from "bootstrap-vue"
import { RecentlyImportedMailsQuery } from "types/graphql/requests"
import CommunityManagerChip from "profile/components/CommunityManagerChip.vue"
import PulseLoader from "vue-spinner/src/PulseLoader.vue"
import PersonChip from "profile/components/PersonChipGql.vue"
import { markAsDoneVorgangPath } from "generated/routes"

type Vorgang = RecentlyImportedMailsQuery["vorgaenge"][0]

import gql from "utils/graphql"

export default Vue.extend({
  components: {
    BTable,
    PulseLoader,
    CommunityManagerChip,
    PersonChip
  },
  props: {},
  data() {
    return {
      vorgaenge: [] as Vorgang[],
      sortBy: "list_name",
      loading: false,
      done: {} as Record<string, boolean>,
      show: false,
    }
  },
  methods: {
    async fetchStuff() {
      this.loading = true
      const r = await gql.RecentlyImportedMails()
      this.vorgaenge = r.vorgaenge
      this.loading = false
    },
    markAsDone(item: Vorgang) {
      this.done = { ...this.done, [item.id]: true }
      this.$http.post(markAsDoneVorgangPath(item.id))
    },
    markAllDone() {
      this.vorgaenge.forEach((item) => {
        if (!this.done[item.id]) {
          this.markAsDone(item)
        }
      })
    }

  },
  computed: {
    fields(): BvTableFieldArray {
      return [
       {
          key: "organisation.name",
          label: "Organisation",
          sortable: true
        } ,
        {
          key: "personen",
          label: "Personen",
          sortable: false,
        },
        {
          key: "communityManagerName",
          label: "CommunityManager",
          sortable: true,
        },
        {
          key: "date",
          label: "Zeitraum",
          sortable: true,
        },
        {
          key: "type.key",
          label: "Typ",
          sortable: true,
        },
        {
          key: "actions",
          label: "Aktionen",
          sortable: false,
        },
      ].filter(Boolean) as BvTableFieldArray
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.fetchStuff()
      }
    }
  }
})
</script>
<style scoped>
</style>
