<template lang='pug'>
div.form-group
  label.control-label(for='formName')
    |{{label}}
  input(type='hidden' :name="formName" :value='value' ref='hiddenInput')
  .input-group
    flat-pickr.form-control(v-model='value' :config='fromConfig' ref='picker')
    span.input-group-addon
      button(class="btn btn-outline-secondary" type="button" title="Toggle" data-toggle)
        i.fa.fa-fw.fa-calendar
          span(aria-hidden="true" class="sr-only") Toggle
</template>

<script>
import { German } from 'flatpickr/dist/l10n/de';
import ConfirmDate from 'flatpickr/dist/plugins/confirmDate/confirmDate'
import FlatPickr from 'vue-flatpickr-component/src/component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';
import addDays from 'utils/add_days'

const CF = new ConfirmDate({ confirmIcon: '<i class="fa fa-fw fa-check-square-o"></i>', showAlways: true, confirmText: "OK " })
export default {
  components: { FlatPickr },
  props: ['label', 'initialValue', 'formName', 'max', 'min', 'onlyDate'],
  data() {
    return {
      value: this.initialValue,
    }
  },
  computed: {
    configMinDate() {
      if (this.min) {
        if (parseInt(this.min, 10).toString() === this.min) {
          const v = parseInt(this.min, 10)
          return addDays(new Date(), v)
        } else {
          return this.min
        }
      } else {
        return 'today'
      }
    },
    configMaxDate() {
      if (this.max) {
        if (parseInt(this.max, 10).toString() === this.max.toString()) {
          const v = parseInt(this.max, 10)
          return addDays(new Date(), v)
        } else {
          return this.max
        }
      } else {
        return addDays(new Date(), 30)
      }
    },
    fromConfig() {
      return {
        enableTime: !this.onlyDate,
        plugins: [CF],
        altInput: true,
        altFormat: this.onlyDate ? "D, d.m." : "D, d.m. H:i",
        dateFormat: "Z",
        wrap: true,
        locale: German,
        minDate: this.configMinDate,
        maxDate: this.configMaxDate,
        time_24hr: true,
        minTime: '06:00',
        maxTime: '20:00',
      }
    },
  },
  watch: {
    value() {
      this.$nextTick(() => $(this.$refs.hiddenInput).trigger('change'))
    }
  },
  mounted() {
    if (this.initialValue) {
      this.value = null
      setTimeout(() => {
        this.$refs.picker.fp.setDate(new Date(this.initialValue), true)
        this.value = this.initialValue
      }, 500)
    }
  },
}
</script>
