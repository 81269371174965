<template>
  <div
    class="panel panel-primary"
    style="height: auto; transition: height 0.3s ease-in"
  >
    <div class="panel-heading">
      <h3 class="panel-title">
        <a href="#customer-board" data-toggle="collapse" @click="loadData">
          Bestandskunden und Fördererübersicht
        </a>
      </h3>
    </div>
    <div id="customer-board" class="collapse show">
      <ul class="nav nav-tabs">
        <li class="nav-item align-self-center mx-2"><a class="label-tab">Bestandskunden</a></li>
        <li class="nav-item" :class="{ active: activeTab == 'partner' }">
          <a class="nav-link" @click="setTab('partner')">Partner</a>
        </li>
        <li class="nav-item" :class="{ active: activeTab == 'funnel' }">
          <a class="nav-link" @click="setTab('funnel')">
            Akquise-Funnel
            <span class='badge badge-pill badge-primary'>NEU</span>
          </a>
        </li>
        <li
          class="nav-item"
          :class="{ active: activeTab == 'foerderer_unternehmen' }"
        >
          <a class="nav-link" @click="setTab('foerderer_unternehmen')"
            >Förderer-Unternehmen</a
          >
        </li>
        <li class="nav-item" :class="{ active: activeTab == 'foerderer' }">
          <a class="nav-link" @click="setTab('foerderer')">Andere Förderer</a>
        </li>
        <li class="nav-item" :class="{ active: activeTab == 'interessenten' }">
          <a class="nav-link" @click="setTab('interessenten')">Interessenten</a>
        </li>
      </ul>
      <div style="min-height: 300px; transition: height 0.5s ease-in">
        <div v-if="isLoading" class="panel-body text-center">
          <PulseLoader :loading="isLoading" />
        </div>
        <PartnerTableV2
          v-if="activeTab == 'partner' && !isLoading"
          :services="services"
          :communityManagers="communityManagers"
        />
        <AkquiseFunnel
          v-if="activeTab == 'funnel'"
        />
        <FoerdererUnternehmenTable
          v-if="activeTab == 'foerderer_unternehmen' && !isLoading"
          :organisations="organisations"
          :services="services"
        />
        <FoerdererTable
          v-if="activeTab == 'foerderer' && !isLoading"
          :organisations="organisations"
        />
        <InteressentenTable
          v-if="activeTab == 'interessenten' && !isLoading"
          :organisations="organisations"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue"
import PartnerTableV2 from "./partner_table_v2/PartnerTable.vue"
import PartnerTable from "./partner_table/PartnerTable.vue"
import FoerdererTable from "./foerderer_table/FoerdererTable.vue"
import FoerdererUnternehmenTable from "./foerderer_table/FoerdererUnternehmenTable.vue"
import InteressentenTable from "./interessenten_table/InteressentenTable.vue"
import AkquiseFunnel from "./akquise_funnel/AkquiseFunnel.vue"

export default {
  components: {
    PulseLoader,
    PartnerTable,
    PartnerTableV2,
    FoerdererTable,
    FoerdererUnternehmenTable,
    InteressentenTable,
    AkquiseFunnel,
  },
  props: {
    services: { type: Object, required: true },
    communityManagers: { type: Array, required: true },
  },
  data() {
    return {
      activeTab: "partner",
      isLoading: false,
      organisations: [],
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    setTab(tab) {
      this.activeTab = tab
      this.fetch()
    },
    loadData() {
      if (this.organisations.length === 0) {
        this.fetch()
      }
    },
    async fetch() {
      this.isLoading = true
      this.organisations = []
      if (this.activeTab != 'partner' && this.activeTab != 'funnel') {
        const response = await this.$http.get(`/dashboard/ajax/${this.activeTab}`)
        this.organisations = response.data.organisations
        this.isLoading = false
      } else {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>
.label-tab {
  height: 37px;
  color: black;
  text-transform: none;
  font-weight: bold;
  border-color: transparent;
  background: none;
}
</style>
