<template lang="pug">
div
  div.list-group
    div.list-group-item(
      v-for="cm in communityManagers",
      :class="{ active: community_manager && cm.id === community_manager.id }"
    )
      div(style="width: 30px; margin-right: 10px")
        img.img-circle(:src="cm.image", v-if="cm.image")
      div(style='flex-grow: 1')
        | {{ cm.name }}
      button.btn.btn-outline-primary.btn-sm(
        @click="select(cm)",
        v-if="!community_manager || cm.id != community_manager.id"
      )
        | Zuweisen
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import * as Routes from "generated/routes"

export default Vue.extend({
  props: {
    communityManagers: {
      type: Array as PropType<CommunityManager[]>,
      required: true,
    },
    message: { type: Object as PropType<Lead>, required: true },
  },
  data() {
    return {
      community_manager: this.message.assigned_to,
    }
  },
  methods: {
    select(cm: CommunityManager) {
      this.$http
        .patch(Routes.leadPath(this.message.id), { assigned_to_id: cm.id })
        .then(() => {
          this.$emit("close")
        })
    },
  },
})
</script>
<style scoped>
  img {
    width: 30px; height: 30px;
  }
  .list-group-item {
    display: flex;
    align-items: center;
  }
</style>
