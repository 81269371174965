// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"

import CollapseCheckController from './collapse_check_controller'
import DienstreiseLegacyController from './dienstreise_legacy_controller'
import Select2Controller from './select2_controller'
import CommunityWorkshopLegacyController from './community_workshop_legacy_controller'
import BoxplotChart from './boxplot_chart_controller'
import TaskLogController from './task_log_controller'
import WeaverEmailcheckController from './weaver_emailcheck_controller'

const Stimulus = Application.start()

// Stimulus.register("participant-controller", AutohideController)
Stimulus.register("collapse-check", CollapseCheckController)
Stimulus.register("dienstreise-legacy", DienstreiseLegacyController)
Stimulus.register("select2", Select2Controller)
Stimulus.register("community-workshop-legacy", CommunityWorkshopLegacyController)
Stimulus.register("boxplot-chart", BoxplotChart)
Stimulus.register("task-log", TaskLogController)
Stimulus.register("weaver-emailcheck", WeaverEmailcheckController)
