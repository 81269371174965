window.setConditionalToggle = (radio: JQuery, toggleValue: any, toggleElement: JQuery): JQuery => {
  return radio
    .change(function () {
      let vals

      if (radio.is("select")) {
        vals = radio
          .find("option:selected")
          .map(function () {
            return $(this).val()
          })
          .toArray()
      } else {
        vals = radio
          .filter(":checked")
          .map(function () {
            return $(this).val()
          })
          .toArray()
      }

      let shouldValues = []

      if (typeof toggleValue !== "object") {
        shouldValues = [toggleValue]
      } else {
        shouldValues = toggleValue
      }

      let found = false

      for (const val of shouldValues) {
        if (vals.indexOf(val.toString()) !== -1) {
          found = true
        }
      }

      if (found) {
        toggleElement.collapse("show")
      } else {
        toggleElement.collapse("hide")
        toggleElement.data("bs.collapse").hide()
      }

      return null
    })
    .first()
    .change()
}
const fn = window.setConditionalToggle
export default fn
