<template>
  <span>
    <a
      class="btn btn-xs"
      :class="{ 'btn-success': isClosed, 'btn-default': !isClosed }"
      :title="measure.subject"
      @click.prevent="modalOpen = true"
    >
      <i v-if="isClosed" class="fa fa-check-square-o fa-fw"></i>
      <i v-else class="fa fa-square-o fa-fw"></i>
    </a>
    <BModal
      v-model="modalOpen"
      :title="measure.subject"
      size="lg"
      :hide-footer="true"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="modalOpen"
        v-html="$options.filters.tohtml(measure.text)"
      ></div>
      <template v-slot:modal-footer>
        <a
          v-if="!isClosed"
          class="btn btn-sm btn-primary"
          :href="incidentCompletUrl"
          target="_blank"
        >
          <i class="fa-play fa fa-fw"></i>Vorgang anlegen und erledigen
        </a>
      </template>
    </BModal>
  </span>
</template>

<script>
import { BModal } from "bootstrap-vue"

export default {
  components: { BModal },
  props: {
    measure: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { modalOpen: false }
  },
  computed: {
    isClosed() {
      return this.measure.closed_on !== null
    },
    incidentCompletUrl() {
      return `/incidents/${this.measure.id}`
    },
  },
  methods: {},
}
</script>

<style scoped>
span {
  margin-right: 1px;
}
</style>
