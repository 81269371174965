<template>
  <table class="table table-striped table-bordered table-sm">
    <thead>
      <template v-for="headline in headlines">
        <SortHeadline
          v-if="headline.sortKey"
          :headline="headline"
          :sort-state="sortState"
          @update="toggleSort"
        >
          {{ headline.text }}
        </SortHeadline>
        <th v-else :key="headline.text" :title="headline.title">
          {{ headline.text }}
        </th>
      </template>
    </thead>
    <tbody>
      <slot :entries="sortedEntries"> </slot>
    </tbody>
  </table>
</template>

<script>
import SortHeadline from "dashboard/components/SortHeadline.vue"
import sortBy from "lodash/sortBy"
import reverse from "lodash/reverse"
import get from "lodash/get"

export default {
  components: { SortHeadline },
  props: {
    headlines: { type: Array, required: true },
    entries: { type: Array, required: true },
  },
  data() {
    return {
      sortState: {
        current: null,
        reverse: false,
        emptyValue: null,
      },
    }
  },
  computed: {
    sortedEntries() {
      if (!this.sortState.current) {
        return this.entries
      } else {
        const collection = sortBy(this.entries, entry => {
          const value = get(entry, this.sortState.current)
          if ((!value || value === "") && this.sortState.emptyValue) {
            return this.sortState.emptyValue
          }
          if (typeof value === "string") {
            return value.toLowerCase()
          } else {
            return value
          }
        })
        if (this.sortState.reverse) {
          return reverse(collection)
        } else {
          return collection
        }
      }
    },
  },
  methods: {
    toggleSort({ sortKey, defaultReverse, emptyValue }) {
      if (sortKey === this.sortState.current) {
        this.sortState.reverse = !this.sortState.reverse
      } else {
        this.sortState.current = sortKey
        this.sortState.reverse = defaultReverse
        this.sortState.emptyValue = emptyValue
      }
    },
  },
}
</script>

<style scoped>
thead {
  position: sticky;
  top: 0;  /* 0px if you don't have a navbar, but something is required */
  background: white;
}
</style>
