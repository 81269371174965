<template lang="pug">
div
  h3 Dateianhänge für {{ angebot.label }}
  attachments-field(v-model='uploads')
  hr
  button.btn.btn-primary(@click="submit" :disabled='loading') Speichern

</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import AttachmentsField from 'mailing_form/steps/AttachmentsField.vue'

export default Vue.extend({
  components: {
    AttachmentsField,
  },
  props: {
    angebot: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      uploads: this.angebot.attachments as ActiveStorage.Blob[],
      loading: false
    }
  },
  computed: {
  },
  methods: {
    async submit() {
      this.loading = true
      try {
	await this.$http.post(`/angebote/${this.angebot.id}/attachments`, {
	  attachments: this.uploads.map((upload) => upload.signed_id)
	})
	window.location.assign(`/organisationen/${this.angebot.Organisation}`)
      } catch(err) {
	console.log(err)
	return false
      }
    }
  },
})
</script>
