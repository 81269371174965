<template lang="pug">
span.chip(:title='title')
  img.avatar(v-if='person.image.icon.url' :src='"https://crm.pludoni.de" + person.image.icon.url' alt='')
  span {{ person.Nachname }}
</template>

<script lang='ts'>
import Vue, { PropType } from 'vue'
export default Vue.extend({
  props: {
    person: { type: Object as PropType<schema.Person>, required: true },
  },
  computed: {
    title(): string {
      return `${this.person.Anrede} ${this.person.Vorname} ${this.person.Nachname}, ${this.person.Position}`
    }
  },
})
</script>

<style scoped>
@import "./chip.scss";
</style>
