import $ from 'jquery'

const activate = () => {
  $('i[title], i[data-title]').tooltip()
}
window.activateTooltips = () => {
  document.addEventListener("DOMContentLoaded", activate)
  activate()
}

