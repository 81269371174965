import { ref } from 'vue'
type Organisationstyp = {
  id: number,
  name: string,
}
const organisationstypen = ref<Organisationstyp[] | null>(null)


const get = () => {
  if (organisationstypen.value) {
    return Promise.resolve(organisationstypen.value)
  }
  return fetch("/autocomplete/communities").then(r => r.json()).then(r => {
    organisationstypen.value = r.organisationstypen
  })
}

export {
  organisationstypen,
  get,
}
