
export default [
  { key: "akquise_no_interest", title: "Kein Interesse" },
  { key: "akquise_no_jobs", title: "Keine Stellen" },
  { key: "akquise_contact", title: "E-Mail/Xing" },
  { key: "akquise_reminder", title: "Erinnerung" },
  { key: "akquise_customercontact", title: "Kundenanfrage" },
  { key: "akquise_fair", title: "Messe" },
  { key: "akquise_call", title: "Telefonat" },
  { key: "akquise_kickoff", title: "Kickoff" },
  { key: "akquise_appointment", title: "Terminvereinb." },
  { key: "akquise_meeting", title: "Treffen" },
  { key: "akquise_offer", title: "Angebot" },
]
