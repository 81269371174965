<template>
  <div class="partner-table">
    <ul class="nav nav-tabs">
      <li class='nav-item' :class="{active: filterMode == 'all'}">
        <a class='nav-link' href="#" @click.prevent="filterMode='all'">Alle Firmen</a>
      </li>
      <li class='nav-item' :class="{active: filterMode == 'own'}">
        <a class='nav-link' href="#" @click.prevent="filterMode='own'">Eigene</a>
      </li>
      <li class='nav-item' :class="{active: filterMode == 'other'}">
        <a class='nav-link' href="#" @click.prevent="filterMode='other'">Fremd-Vorgänge</a>
      </li>
    </ul>
    <SortableTable
      v-slot="slotProps"
      :headlines="headlines"
      :entries="mappedOrganisations"
    >
      <TableRow
        v-for="organisation in slotProps.entries"
        :key="organisation.ID"
        :organisation="organisation"
      />
    </SortableTable>
  </div>
</template>

<script>
import SortableTable from "dashboard/SortableTable.vue"
import TableRow from "./TableRow.vue"
import akquiseVorgaenge from "./akquise_vorgaenge.js"
/* globals CurrentCm */

export default {
  components: { TableRow, SortableTable },
  props: {
    organisations: { type: Array, required: true },
  },
  data() {
    return {
      filterMode: 'all'
    }
  },
  computed: {
    mappedOrganisations() {
      return this.organisations.filter((o) => {
        if (this.filterMode === 'all')
          return true
        if (this.filterMode === 'own')
          return o.CommunityManager === CurrentCm.id
        if (this.filterMode === 'other')
          return o.CommunityManager !== CurrentCm.id
        return false
      })
    },
    headlines() {
      return [
        {
          title: "Bezeichnung der Firma",
          text: "Name",
          sortKey: "Bezeichnung",
        },
        {
          title: "Letztes Akquisegefühl",
          text: "Akquisegefühl",
          reverse: true,
          sortKey: "letztes_akquisegefuehl_number",
        },
        {
          title: "Geschätztes Umsatzvolumen auf Basis der U-Größe und Akquisegefühl",
          text: "Gesch. Umsatzvolumen",
          reverse: true,
          sortKey: "umsatzvolumen",
        },
        {
          title: "Community-Manager der Firma",
          text: "CM",
          sortKey: "cm.ldap_login",
        },
        ...akquiseVorgaenge.map(r => ({
          title: r.title,
          text: r.title,
          reverse: true,
          sortKey: `vorgaenge.${r.key}.date`,
          emptyValue: '1970-01-01T10:00:00Z001'
        }))
      ]
    },
  },
}
</script>

<style>
.partner-table th {
  border-top: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}
.partner-table .table {
  border-top: 0;
}
</style>



