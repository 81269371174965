import Vue from 'vue'
import applyFilters from 'utils/filters'
import '@/utils/moment'
import '@/utils/select2'
import '@/utils/jquery'
import '@/utils/rails';
import "@mdi/font/scss/materialdesignicons.scss";

applyFilters(Vue)

import 'utils/axios'
import 'utils/modal.js'

import "@nathanvda/cocoon"

import { VBTooltip } from 'bootstrap-vue';
Vue.directive('b-tooltip', VBTooltip);

import "@/components"

import 'modules/conditional-toggle'
import 'modules/events'
import 'modules/highcharts'
import 'modules/incidents'
import 'modules/presseverteiler'
import 'modules/qrcode'
import 'modules/quickoptions'
import 'modules/reisezeit'
import 'modules/copy-location'
import 'modules/tablesorter'
import 'modules/auto-toc'
import 'modules/tooltip'

import 'bootstrap/js/src/tooltip'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/modal'
import 'bootstrap/js/src/dropdown'
import 'bootstrap/js/src/popover'
import 'bootstrap/js/src/tab'
import "controllers"

import 'styles/index.scss'

