export default (text: number | string): string => {
  if (!text) return ""
  const number =
    Math.round((parseInt(text.toString(), 10) / 1024 / 1024) * 10) / 10
  if (number == 0) {
    const kb = Math.round((parseInt(text.toString(), 10) / 1024) * 10) / 10
    return `${kb.toString().replace(".", ",")} KB`
  } else {
    return `${number.toString().replace(".", ",")} MB`
  }
}
