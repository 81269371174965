<template lang="pug">
a.btn.btn-default.btn-sm(:href='url' @click.prevent='showPdf = true' v-bind="$attrs" :class='btnClass' :title='title || text')
  i.fa.fa-paperclip.fa-fw
  |
  template(v-if='text')
    | {{ text }}
  b-modal.pdf-modal(v-model='showPdf', size='xl' v-if='showPdf' modal-class='pdf-modal')
    pdf-viewer(:url='url')
    template(v-slot:modal-footer='{ cancel }')
      a.btn.btn-primary.btn-sm(:href='url')
        | Download
      button.btn.btn-secondary.btn-sm(type='button' @click='showPdf = false')
        | Schließen

</template>

<script lang="ts">
import Vue from "vue"
import { BModal } from "bootstrap-vue"
import PdfViewer from "./PDFViewer.vue"

export default Vue.extend({
  components: {
    BModal,
    PdfViewer,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    btnClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showPdf: false,
    }
  },
})
</script>
<style>
.pdf-modal {
  width: 90vw;
}
.pdf-modal .pdfvuer-toolbar {
  position: sticky;
  top: 0;
  z-index: 1;
  right: 0;
}
.pdf-modal .pdfvuer {
  position: relative;
}
.pdf-modal .modal-body {
  background: #555;
  max-height: 83vh;
  overflow: auto;
}
</style>
