<template>
  <div class="partner-table">
    <SortableTable
      v-slot="slotProps"
      :headlines="headlines"
      :entries="mappedOrganisations"
    >
      <FoerdererTableRow
        v-for="organisation in slotProps.entries"
        :key="organisation.ID"
        :organisation="organisation"
      />
    </SortableTable>
  </div>
</template>

<script>
import SortableTable from "dashboard/SortableTable.vue"
import FoerdererTableRow from "./FoerdererUnternehmenRow.vue"

export default {
  components: { FoerdererTableRow, SortableTable },
  props: {
    organisations: { type: Array, required: true },
  },
  computed: {
    mappedOrganisations() {
      return this.organisations
    },
    headlines() {
      return [
        {
          title: "Bezeichnung der Firma",
          text: "Name",
          sortKey: "Bezeichnung",
        },
        {
          title: "Kundenbeziehung",
          text: "Beziehung",
          sortKey: "kundenbeziehung",
        },
        {
          title: "Anzahl Vorgänge in letzten 365 Tagen",
          text: "Aufwand",
          sortKey: "review_info.Kundenaufwand.value",
          reverse: true,
        },
        {
          title:
            "Backlink gefunden: 100% = Community, ansonsten nur zum Empfehlungsbund",
          text: "Backlink",
          sortKey: "review_info.Backlink.value",
          reverse: true,
        },
        {
          title: "Mystery bestanden ODER nutzt BMS",
          text: "Mystery",
          sortKey: "review_info.Mystery Applicant.value",
          reverse: true,
        },
        {
          title: "Nutzt BMS aktiv",
          text: "BMS",
          sortKey: "review_info.BMS.value",
          reverse: true,
        },
      ]
    },
  },
}
</script>

<style>
.partner-table th {
  border-top: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}
.partner-table .table {
  border-top: 0;
}
</style>


