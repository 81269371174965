import consumer from "@/utils/consumer"

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { id: String }

  declare readonly idValue: string
  declare subscription: any

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "WeaverEmailCheckChannel", id: this.idValue },
      {
        received: this.callback.bind(this)
      }
    )
  }
  callback(data: any) {
    //"<tr data-controller=xx> </tr>" -> take inner tr  content and replace this.element content with it
    const fragment = data.html
    if (fragment.includes("<tr")) {
      const tr = document.createElement("table")
      tr.innerHTML = fragment
      const newTr = tr.querySelector("tr")
      this.element.replaceWith(newTr)
    } else {
      this.element.innerHTML = fragment
    }
  }
  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}

