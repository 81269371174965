<template lang='pug'>
b-table.table-sm(
  :fields="fields"
  striped
  hover,
  :items="value"
  responsive
  primary-key="id",
  :tbody-transition-props="{ name: 'flip-list' }"
  show-empty,
  :sortBy.sync="sortBy"
  tbody-tr-class="flip-list-item"
)
  template(v-slot:cell(organisation.name)=" { item }")
    a(:href="'/organisationen/' + item.organisation.id")
      | {{ item.organisation.name }}
  template(v-slot:cell(type.key)="{ item }")
    | {{ item.type.name }}
    .themen-wrapper(v-if="item.themaNotes.length > 0")
      small.label.label-default.mr-1(:key="thema.id" :title='thema.body || "Thema ohne Kommentar"' v-b-tooltip v-for="thema in item.themaNotes")
        i.fa.fa-comment.mr-1(v-if="thema.body")
        | {{ thema.thema.name }}

    div(v-if="item.comment" style='line-height: 1.1;')
      small {{ item.comment }}

    div(v-if="item.termin")
      span.badge.badge-dark(:title="item.termin.location")
        i.fa-clock-o.fa.fa-fw
        | {{ item.termin.from | moment('L LT') }}
        span(v-if='item.termin.topic')
          | {{ item.termin.topic }}

    div(v-if="item.akquisegefuehl")
      small Akquisechance
      span.label.label-default {{ item.akquisegefuehl }}

    div(v-if="item.customerFirstContacted")
      span.label.label-success
        | Erstkontakt durch Kunden

  template(v-slot:cell(communityManagerName)="{ item }")
    community-manager-chip(v-if="item.communityManager", :community-manager="item.communityManager")
    span(v-else)
      | {{ item.communityManagerName }}
    //community-manager-chip(v-for="cm in item.participating_community_managers", :community-manager="cm", :key="cm.id")

    div(v-if="item.gebuchteZeit && item.gebuchteZeit > 0")
      small
        i.fa.fa-fw.fa-clock-o
        | {{ item.gebuchteZeit }}m
    //    div(v-if="item.dienstreise_hin")
    //      small(title="Anreise")
    //        i.fa.fa-car
    //        i.fa.fa-fw.fa-step-forward
    //        | {{ item.dienstreise_hin.Reisezeit }}m
    //    div(v-if="item.dienstreise_zurueck")
    //      small(title="Abreise")
    //        i.fa.fa-car
    //        i.fa.fa-fw.fa-step-backward
    //        | {{ item.dienstreise_zurueck.Reisezeit }}m

  template(v-slot:cell(date)="{ item }")
    | {{ item.date | moment('L') }}

  template(v-slot:cell(reminder)="{ item }")
    span(v-if="item.reminder" title="Erinnerung")
      i.fa-clock.fa-fw.fa
      | {{ item.reminder | moment('L') }}

    //.label.label-default(v-for="mail in item.queued_mails", :key="mail.id" style="display: block")
    //  a.js-modal(:href="'/queued_mails/' + mail.id")
    //    | Mail vom {{ mail.send_on | moment('L') }}
    //    br
    //    span.text-truncate(:title="mail.subject")
    //      | {{ mail.subject | truncate(20) }}

  template(v-slot:cell(personen)="{ item }")
    //person-chip(v-for="person in item.personen", :person="person", :key="person.id")

  template(v-slot:cell(actions)="{ item }")
    .btn-group.d-flex
      a.btn.btn-sm.btn-default.js-modal(v-if="item.canEdit", :href="'/vorgaenge/' + item.id + '/edit'" title="Bearbeiten")
        i.fa.fa-pencil-square-o.fa-fw
      a.btn.btn-sm.btn-warning.js-modal(
        v-if="item.canDestroy",
        :href="'/vorgaenge/' + item.id"
        title="Löschen"
        data-method="delete"
        data-confirm="Löschen?"
      )
        i.fa.fa-trash-o.fa-fw
      a.btn.btn-sm.btn-default(v-if="item.Mahnschreiben", :href="'/vorgaenge/' + item.id + '.pdf'" title="Mahnschreiben PDF")
        i.fa.fa-paperclip.fa-fw

      template(v-if="item.canRemoveReminder")
        a.js-modal.btn.btn-sm.btn-primary(
          :href="'/vorgaenge/new?Organisation=' + item.organisation.id + '&vorgaenger=' + item.id"
          title="Nachfolgevorgang anlegen und Erinnerung löschen"
        )
          i.fa.fa-plus.fa-fw
        a.btn.btn-sm.btn-default(@click="deleteReminder(item)" title='Termin Erinnerung löschen')
          i.fa.fa-calendar-times-o.fa-fw
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import CommunityManagerChip from "profile/components/CommunityManagerChip.vue"
import PersonChip from "profile/components/PersonChip.vue"
import { BTable, BvTableFieldArray } from "bootstrap-vue"
import { VorgaengeFieldsFragment } from "types/graphql/requests"

export default Vue.extend({
  components: {
    BTable,
    CommunityManagerChip,
    PersonChip
  },
  props: {
    showOrganisation: {
      type: Boolean,
      default: false
    },
    value: { type: Array as PropType<VorgaengeFieldsFragment[]> },
  },
  data() {
    return {
      sortBy: "list_name",
    }
  },
  computed: {
    currentCm() {
      return window.CurrentCm
    },
    fields(): BvTableFieldArray {
      return [
        this.showOrganisation ? {
          key: "organisation.name",
          label: "Organisation",
          sortable: true
        } : null,
        {
          key: "type.key",
          label: "Vorgangstyp",
          sortable: true,
        },
        {
          key: "communityManagerName",
          label: "CommunityManager",
          sortable: true,
        },
        {
          key: "date",
          label: "Zeitraum",
          sortable: true,
        },
        {
          key: "personen",
          label: "Person",
          sortable: false,
        },
        {
          key: "reminder",
          label: "Erinnerung/Termin",
          sortable: true,
        },
        {
          key: "actions",
          label: "Aktionen",
          sortable: false,
        },
      ].filter(Boolean) as BvTableFieldArray
    },
  },
  methods: {
    deleteReminder(vorgang: VorgaengeFieldsFragment) {
      vorgang.reminder = null
      this.$http.post(`/vorgaenge/${vorgang.id}/remove_reminder`).then(() => {
        const newArray = this.value.map((e) => {
          if (e.id === vorgang.id) {
            return {
              ...e,
              reminder: null,
            }
          } else {
            return e
          }
        })
        this.$emit("input", newArray)
      })
    },
  },
})
</script>

<style scoped>
* >>> .flip-list-move {
  transition: transform 0.3s ease-out;
}
* >>> .flip-list-item {
  transition: all 0.4s ease-out;
}
* >>> .flip-list-enter,
* >>> .flip-list-leave-to {
  opacity: 0;
}
* >>> .flip-list-enter {
  transform: translateX(150px);
}
* >>> .flip-list-leave-active {
  position: absolute;
}
* >>> .checkbox-group {
  display: flex;
}
* >>> .checkbox-group > .form-check {
  margin-right: 10px;
}
.themen-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}
.label-default {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
