<template lang="pug">
div.text-center
  div(v-if='reviewNotDesired')
    span.badge.badge-danger Kein Review erwünscht
  div(v-else)
    value-with-formatting(:value='reviewgespraech' v-if='reviewgespraech' style='justify-content: flex-start')

  div.text-left(v-if='appointments.length > 0')
    .card.p-1(v-for='appointment in appointments' style='width: fit-content')
      div
        span.badge.badge-dark Termin
        |
        |{{ appointment.termin.from | moment("LL") }}
      br
      |
      small(style='max-width: 150px')
        |{{ appointment.termin.topic }}

</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { PartnerTableQuery } from "types/graphql/requests"
import ValueWithFormatting from "dashboard/components/ValueWithFormatting.vue"

type PartnerTableRow = PartnerTableQuery["organisations"][0]

export default Vue.extend({
  components: {
    ValueWithFormatting,
  },
  props: {
    item: { type: Object as PropType<PartnerTableRow>, required: true },
  },
  computed: {
    reviewgespraech(): any {
      const rv = this.item.cmReview?.reviewgespraech
      if (rv && rv.value) {
        return {
          ...rv,
          value: this.$moment(rv.value).format("MMM YY"),
        }
      }
    },
    reviewNotDesired(): any {
      return this.item.patientenakte?.reviewNotDesired
    },
    appointments(): any {
      return this.item.termine
    },
  },
})
</script>
