import tinymce from "tinymce"
import _groupBy from "lodash/groupBy"

tinymce.PluginManager.add("quicktemplate", function Plugin(editor) {
  const templates = editor.settings.fragments as (Mailtemplate & { subject?: string })[]

  const templateGroups = _groupBy(templates, "template_group")

  editor.ui.registry.addMenuButton("quicktemplate", {
    text: "Fragmente",
    type: "menubutton",
    icon: "template",
    fetch(callback) {
      callback(
        Object.keys(templateGroups).map((group) => {
          return {
            text: group,
            type: "nestedmenuitem",
            getSubmenuItems() {
              return templateGroups[group].map((template) => {
                return {
                  type: "menuitem",
                  text: template.title,
                  onAction() {
                    const body = template.body
                    editor.insertContent(body)
                  },
                }
              })
            },
          }
        })
      )
    },
  })
})
