<template>
  <div class="value-with-formatting">
    <span
      class="value-dot"
      :style="{ backgroundColor: bgColor, borderColor: fgColor }"
      :title='value.title'
    ></span>
    {{ value.value }}
  </div>
</template>

<script>
export default {
  props: { value: { type: Object, required: true } },
  computed: {
    bgColor() {
      return this.value.color[0]
    },
    fgColor() {
      return this.value.color[1]
    },
  },
  methods: {},
}
</script>

<style scoped>
.value-with-formatting {
  border-radius: 3px;
  padding: 3px;
  margin-right: 1px;
  text-align: right;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.value-dot {
  float: left;
  margin-right: 2px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  height: 10px;
  width: 10px;
  display: inline-block;
}
</style>
