<template>
  <div class="partner-table">
    <SortableTable
      v-slot="slotProps"
      :headlines="headlines"
      :entries="mappedOrganisations"
    >
      <PartnerTableRow
        v-for="organisation in slotProps.entries"
        :key="organisation.ID"
        :organisation="organisation"
        :services="services"
      />
    </SortableTable>
  </div>
</template>

<script lang='ts'>
import SortableTable from "dashboard/SortableTable.vue"
import PartnerTableRow from "./PartnerRow.vue"

const statusOrder = org => {
  if (org.is_gekuendigt) {
    return [0, org.angebot.LaufzeitBis, org.Bezeichnung]
  } else if (org.is_neukunde) {
    return [1, org.angebot.LaufzeitVon, org.Bezeichnung]
  } else {
    return [3, org.angebot.NaechsteVerlaengerung, org.Bezeichnung]
  }
}

export default {
  components: { PartnerTableRow, SortableTable },
  props: {
    organisations: { type: Array, required: true },
    services: { type: Object, required: true },
  },
  data() {
    return {
      key: 1,
    }
  },
  computed: {
    mappedOrganisations() {
      return this.organisations.map(o => ({
        ...o,
        statusOrder: statusOrder(o),
      }))
    },
    headlines() {
      return [
        {
          title: "Bezeichnung der Firma",
          text: "Name",
          sortKey: "Bezeichnung",
        },
        {
          title: "Status bzw. Verlängerung",
          text: "Status",
          sortKey: "statusOrder",
        },
        {
          title: "Kundenbeziehung",
          text: "Beziehung",
          sortKey: "kundenbeziehung",
        },
        {
          title: "Letzte Kundenzufriedenheit",
          text: "KZI Ges./Alter KZI",
          sortKey: "review_info.KZI Befragung.value",
          reverse: true,
        },
        {
          title: "Zurückgeführte Bewerber in den letzten 3 Monaten",
          text: "Passus-Clicks",
          sortKey: "review_info.Smartclick Ohne Code.value",
          reverse: true,
        },
        {
          title: "Nutzt BMS aktiv",
          text: "EBBMS",
          sortKey: "review_info.BMS.value",
          reverse: true,
        },
        {
          title: "Letztes Reviewgespräch innerhalb 1 Jahres",
          text: " Reviewgespräch",
          sortKey: "review_info.Reviewgespräch.value",
          reverse: true,
        },
        //{
        //  title: "Zufriedenheit mit Communitymanager",
        //  text: "KZI CM",
        //  sortKey: "review_info.KZI CM.value",
        //  reverse: true,
        //},
        //{
        //  title: "Zufriedenheit mit Reviewgespräch",
        //  text: "KZI Review",
        //  sortKey: "review_info.KZI Review.value",
        //  reverse: true,
        //},
        //{
        //  title: "Weiterempfehlungsquote",
        //  text: "WEQ",
        //  sortKey: "review_info.WEQ.value",
        //  reverse: true,
        //},
        {
          title:
            "Services aktiviert/fehlend",
          text: "Services",
          sortKey: "services.active",
          reverse: true,
        },
        {
          title:
            "Backlink gefunden: 100% = Community, ansonsten nur zum Empfehlungsbund",
          text: "Backlink",
          sortKey: "review_info.Backlink.value",
          reverse: true,
        },
        {
          title:
            "Bewerber-Statistik ausgefüllt",
          text: "Bew.Stat.",
          sortKey: "review_info.Statistik ausgefüllt.value",
          reverse: true,
        },
        {
          title: "Anzahl Pflege-Vorgänge in letzten 365 Tagen",
          text: "Aufwand",
          sortKey: "review_info.Kundenaufwand.value",
          reverse: true,
        },
      ]
    },
  },
}
</script>

<style>
.partner-table th {
  border-top: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}
.partner-table .table {
  border-top: 0;
}
</style>
