import { Controller } from "@hotwired/stimulus"
import Highcharts from "@/modules/highcharts"

type Boxplot = {
  // {"q1"=>1, "q3"=>2, "median"=>1.0, "min"=>1, "max"=>2, "outliers"=>[3, 3, 3, 3, 3, 3, 3, 3, 4, 5]}
  q1: number
  q3: number
  median: number
  min: number
  max: number
  outliers: number[]
}

export default class extends Controller {
  static values = {
    data: Object as unknown as Boxplot,
  }
  declare readonly dataValue: Boxplot

  connect() {
    Highcharts.chart(this.element, {
      chart: {
        type: "boxplot",
        inverted: true,
        height: 50,
      },
      tooltip: {
        outside: true,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: {
        text: null,
      },
      yAxis: {
        visible: false,
        min: 0,
        max: 5,
        labels: {
          enabled: false,
        }
      },
      xAxis: {
        visible: false,

        labels: {
          enabled: false,
        }
      },
      series: [
        {
          data: [
            [
              this.dataValue.min,
              this.dataValue.q1,
              this.dataValue.median,
              this.dataValue.q3,
              this.dataValue.max,
            ],
          ],
        },
      ],
    })
  }
}
