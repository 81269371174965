<template lang="pug">
div
  div(v-if='emails.length > 0')
    .btn-group
      button.btn.btn-sm(@click='selected = emails.slice()')
        i.fa.fa-check-square-o
      button.btn.btn-sm(@click='selected = []')
        i.fa.fa-square-o

    small.text-muted
      | Anzahl: {{ emails.length }}
    b-table#recipients-table(:items='emails' :fields='fields' hover striped small table-class='mb-0' per-page='30' :currentPage='currentPage')
      template(v-slot:cell(select)='{ item }')
        b-form-checkbox(v-model='selected' :value='item')
      template(v-slot:cell(email)=" { item } ")
        .d-flex.justify-content-between
          div.flex-grow-1
            |{{ item.email }}
            br
            small.text-muted
              | {{ item.salutation }}
          a.btn.btn-xs.btn-outline-dark(v-if="item.person_id" :href="'/organisationen/' + item.crm_organisation_id + '#person_' + item.person_id")
            |crm

      template(v-slot:cell(last_history.created_at)=" { item } ")
        span.badge.badge-dark(v-if='item.last_history' :title="item.last_history.title + ' @ ' + item.last_history.created_at")
          |{{ item.last_history.title }}

    b-pagination(
      v-if='emails.length > 0'
      v-model="currentPage"
      :total-rows="emails.length"
      per-page="30"
      size='sm'
      :last-number='true'
      aria-controls="recipients-table"
    )
</template>

<script lang="ts">
import Vue, { PropType } from "vue"

import { BTable, BPagination, BFormCheckbox } from "bootstrap-vue"
import type { BvTableFieldArray } from "bootstrap-vue"

export default Vue.extend({
  components: {
    BTable,
    BPagination,
    BFormCheckbox,
  },
  props: {
    value: {
      type: Array as PropType<schema.MailingRecipient[]>,
      default: () => [],
    },
    emails: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      perPage: 50,
      currentPage: 1,
    }
  },
  computed: {
    selected: {
      get(): schema.MailingRecipient[] {
        return this.value
      },
      set(value: schema.MailingRecipient[]) {
        this.$emit("input", value)
      },
    },
    fields(): BvTableFieldArray {
      return [
        {
          key: "select",
          label: "Auswahl",
        },
        {
          key: "firma",
          label: "Firma",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "typ",
          label: "Personentyp",
          sortable: true,
        },
        {
          key: "last_history.created_at",
          sortable: true,
          label: "Letztes Mailing",
        },
      ]
    },
  },
})
</script>

<style scoped>
.badge-dark {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}
</style>
