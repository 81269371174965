<template lang="pug">
div
  .value-group(title="Smartclicks Ohne Code / Code 1 / Code 2 -- normiert zur Anzahl MA" v-b-tooltip)
    ValueWithFormatting(:value="item.cmReview.smartclickOhneCode")
    ValueWithFormatting(:value="item.cmReview.smartclickCode1" )
    ValueWithFormatting(:value="item.cmReview.smartclickCode2" )
  .text-center.d-flex(style='gap: 5px')
    span(class="label label-warning" v-if="showSmartlinkAlert" title='"Drama"-Hinweis auf Wenig Smartclicks ist aktiv' v-b-tooltip)
      i(class="fa fa-fw fa-angle-double-down")

    span(class="label label-warning" v-if="ignoreSmartlinks" title="Smartclick Warnungen + Notbremse ist für Firma vom CM ausgenommen" v-b-tooltip)
      svg(height="10" aria-hidden="true" focusable="false" class="fa" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512")
        path(fill="currentColor" d="M159.88,175.82h64v64a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16v-64h64a16,16,0,0,0,16-16v-64a16,16,0,0,0-16-16h-64v-64a16,16,0,0,0-16-16h-64a16,16,0,0,0-16,16v64h-64a16,16,0,0,0-16,16v64A16,16,0,0,0,159.88,175.82ZM568.07,336.13a39.91,39.91,0,0,0-55.93-8.47L392.47,415.84H271.86a16,16,0,0,1,0-32H350.1c16,0,30.75-10.87,33.37-26.61a32.06,32.06,0,0,0-31.62-37.38h-160a117.7,117.7,0,0,0-74.12,26.25l-46.5,37.74H15.87a16.11,16.11,0,0,0-16,16v96a16.11,16.11,0,0,0,16,16h347a104.8,104.8,0,0,0,61.7-20.27L559.6,392A40,40,0,0,0,568.07,336.13Z")

    span.label.label-danger(v-if='fallbeilActive' title='Fallbeil ist aktiv' v-b-tooltip)
      svg(style="width:10px;height:10px" viewBox="0 0 24 24")
        path(fill="currentColor" d="M12,2L22,8C22,12 20,14 16,15L13,10L9,6L12,2M4.11,19.84L2.12,18.33L9.19,9L11,10.81L4.11,19.84Z")

    span.label.label-danger(v-if='item.patientenakte.statisticUnreliable' title='Statistik ist nicht verlässlich - Smartclick-Punkte auf 0' v-b-tooltip)
      i.fa.fa-fw.fa-exclamation-triangle
      |Unzuverlässig

    span.label.label-warning(v-if='anuLimited' title='Import Limit (ANÜ)' v-b-tooltip)
      i.fa.fa-fw.fa-exclamation-triangle
      |Limit

</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import ValueWithFormatting from "dashboard/components/ValueWithFormatting.vue"
import { PartnerTableQuery } from "types/graphql/requests"
type PartnerTableRow = PartnerTableQuery["organisations"][0]

export default Vue.extend({
  components: {
    ValueWithFormatting,
  },
  props: {
    item: { type: Object as PropType<PartnerTableRow>, required: true },
  },
  computed: {
    anuLimited() {
      return this.item.patientenakte?.anuLimited
    },
    fallbeilActive() {
      return this.item.patientenakte?.fallbeilActive
    },
    showSmartlinkAlert() {
      return this.item.patientenakte?.showSmartlinkAlert
    },
    ignoreSmartlinks() {
      return this.item.patientenakte?.ignoreSmartlinks
    },
  },
})
</script>

<style scoped>
.value-group {
  display: flex;
  gap: 3px;
}
.value-group >>> .value-with-formatting {
  display: flex;
  flex-direction: column;
  width: 3ch;
}
</style>
